@charset "UTF-8";

@font-face {
    font-family: "linea-arrows-10";
    src: url("../fonts/linea-arrows-10.eot");
    src: url("../fonts/linea-arrows-10.eot?#iefix") format("embedded-opentype"), url("../fonts/linea-arrows-10.woff") format("woff"), url("../fonts/linea-arrows-10.ttf") format("truetype"), url("../fonts/linea-arrows-10.svg#linea-arrows-10") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-arrows-"]:before,
[class*=" icon-arrows-"]:before {
    font-family: "linea-arrows-10" !important;
    font-style: normal !important;
    font-weight: normal;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-arrows-anticlockwise:before {
    content: "\e000";
}

.icon-arrows-anticlockwise-dashed:before {
    content: "\e001";
}

.icon-arrows-button-down:before {
    content: "\e002";
}

.icon-arrows-button-off:before {
    content: "\e003";
}

.icon-arrows-button-on:before {
    content: "\e004";
}

.icon-arrows-button-up:before {
    content: "\e005";
}

.icon-arrows-check:before {
    content: "\e006";
}

.icon-arrows-circle-check:before {
    content: "\e007";
}

.icon-arrows-circle-down:before {
    content: "\e008";
}

.icon-arrows-circle-downleft:before {
    content: "\e009";
}

.icon-arrows-circle-downright:before {
    content: "\e00a";
}

.icon-arrows-circle-left:before {
    content: "\e00b";
}

.icon-arrows-circle-minus:before {
    content: "\e00c";
}

.icon-arrows-circle-plus:before {
    content: "\e00d";
}

.icon-arrows-circle-remove:before {
    content: "\e00e";
}

.icon-arrows-circle-right:before {
    content: "\e00f";
}

.icon-arrows-circle-up:before {
    content: "\e010";
}

.icon-arrows-circle-upleft:before {
    content: "\e011";
}

.icon-arrows-circle-upright:before {
    content: "\e012";
}

.icon-arrows-clockwise:before {
    content: "\e013";
}

.icon-arrows-clockwise-dashed:before {
    content: "\e014";
}

.icon-arrows-compress:before {
    content: "\e015";
}

.icon-arrows-deny:before {
    content: "\e016";
}

.icon-arrows-diagonal:before {
    content: "\e017";
}

.icon-arrows-diagonal2:before {
    content: "\e018";
}

.icon-arrows-down:before {
    content: "\e019";
}

.icon-arrows-down-double:before {
    content: "\e01a";
}

.icon-arrows-downleft:before {
    content: "\e01b";
}

.icon-arrows-downright:before {
    content: "\e01c";
}

.icon-arrows-drag-down:before {
    content: "\e01d";
}

.icon-arrows-drag-down-dashed:before {
    content: "\e01e";
}

.icon-arrows-drag-horiz:before {
    content: "\e01f";
}

.icon-arrows-drag-left:before {
    content: "\e020";
}

.icon-arrows-drag-left-dashed:before {
    content: "\e021";
}

.icon-arrows-drag-right:before {
    content: "\e022";
}

.icon-arrows-drag-right-dashed:before {
    content: "\e023";
}

.icon-arrows-drag-up:before {
    content: "\e024";
}

.icon-arrows-drag-up-dashed:before {
    content: "\e025";
}

.icon-arrows-drag-vert:before {
    content: "\e026";
}

.icon-arrows-exclamation:before {
    content: "\e027";
}

.icon-arrows-expand:before {
    content: "\e028";
}

.icon-arrows-expand-diagonal1:before {
    content: "\e029";
}

.icon-arrows-expand-horizontal1:before {
    content: "\e02a";
}

.icon-arrows-expand-vertical1:before {
    content: "\e02b";
}

.icon-arrows-fit-horizontal:before {
    content: "\e02c";
}

.icon-arrows-fit-vertical:before {
    content: "\e02d";
}

.icon-arrows-glide:before {
    content: "\e02e";
}

.icon-arrows-glide-horizontal:before {
    content: "\e02f";
}

.icon-arrows-glide-vertical:before {
    content: "\e030";
}

.icon-arrows-hamburger1:before {
    content: "\e031";
}

.icon-arrows-hamburger-2:before {
    content: "\e032";
}

.icon-arrows-horizontal:before {
    content: "\e033";
}

.icon-arrows-info:before {
    content: "\e034";
}

.icon-arrows-keyboard-alt:before {
    content: "\e035";
}

.icon-arrows-keyboard-cmd:before {
    content: "\e036";
}

.icon-arrows-keyboard-delete:before {
    content: "\e037";
}

.icon-arrows-keyboard-down:before {
    content: "\e038";
}

.icon-arrows-keyboard-left:before {
    content: "\e039";
}

.icon-arrows-keyboard-return:before {
    content: "\e03a";
}

.icon-arrows-keyboard-right:before {
    content: "\e03b";
}

.icon-arrows-keyboard-shift:before {
    content: "\e03c";
}

.icon-arrows-keyboard-tab:before {
    content: "\e03d";
}

.icon-arrows-keyboard-up:before {
    content: "\e03e";
}

.icon-arrows-left:before {
    content: "\e03f";
}

.icon-arrows-left-double-32:before {
    content: "\e040";
}

.icon-arrows-minus:before {
    content: "\e041";
}

.icon-arrows-move:before {
    content: "\e042";
}

.icon-arrows-move2:before {
    content: "\e043";
}

.icon-arrows-move-bottom:before {
    content: "\e044";
}

.icon-arrows-move-left:before {
    content: "\e045";
}

.icon-arrows-move-right:before {
    content: "\e046";
}

.icon-arrows-move-top:before {
    content: "\e047";
}

.icon-arrows-plus:before {
    content: "\e048";
}

.icon-arrows-question:before {
    content: "\e049";
}

.icon-arrows-remove:before {
    content: "\e04a";
}

.icon-arrows-right:before {
    content: "\e04b";
}

.icon-arrows-right-double:before {
    content: "\e04c";
}

.icon-arrows-rotate:before {
    content: "\e04d";
}

.icon-arrows-rotate-anti:before {
    content: "\e04e";
}

.icon-arrows-rotate-anti-dashed:before {
    content: "\e04f";
}

.icon-arrows-rotate-dashed:before {
    content: "\e050";
}

.icon-arrows-shrink:before {
    content: "\e051";
}

.icon-arrows-shrink-diagonal1:before {
    content: "\e052";
}

.icon-arrows-shrink-diagonal2:before {
    content: "\e053";
}

.icon-arrows-shrink-horizonal2:before {
    content: "\e054";
}

.icon-arrows-shrink-horizontal1:before {
    content: "\e055";
}

.icon-arrows-shrink-vertical1:before {
    content: "\e056";
}

.icon-arrows-shrink-vertical2:before {
    content: "\e057";
}

.icon-arrows-sign-down:before {
    content: "\e058";
}

.icon-arrows-sign-left:before {
    content: "\e059";
}

.icon-arrows-sign-right:before {
    content: "\e05a";
}

.icon-arrows-sign-up:before {
    content: "\e05b";
}

.icon-arrows-slide-down1:before {
    content: "\e05c";
}

.icon-arrows-slide-down2:before {
    content: "\e05d";
}

.icon-arrows-slide-left1:before {
    content: "\e05e";
}

.icon-arrows-slide-left2:before {
    content: "\e05f";
}

.icon-arrows-slide-right1:before {
    content: "\e060";
}

.icon-arrows-slide-right2:before {
    content: "\e061";
}

.icon-arrows-slide-up1:before {
    content: "\e062";
}

.icon-arrows-slide-up2:before {
    content: "\e063";
}

.icon-arrows-slim-down:before {
    content: "\e064";
}

.icon-arrows-slim-down-dashed:before {
    content: "\e065";
}

.icon-arrows-slim-left:before {
    content: "\e066";
}

.icon-arrows-slim-left-dashed:before {
    content: "\e067";
}

.icon-arrows-slim-right:before {
    content: "\e068";
}

.icon-arrows-slim-right-dashed:before {
    content: "\e069";
}

.icon-arrows-slim-up:before {
    content: "\e06a";
}

.icon-arrows-slim-up-dashed:before {
    content: "\e06b";
}

.icon-arrows-square-check:before {
    content: "\e06c";
}

.icon-arrows-square-down:before {
    content: "\e06d";
}

.icon-arrows-square-downleft:before {
    content: "\e06e";
}

.icon-arrows-square-downright:before {
    content: "\e06f";
}

.icon-arrows-square-left:before {
    content: "\e070";
}

.icon-arrows-square-minus:before {
    content: "\e071";
}

.icon-arrows-square-plus:before {
    content: "\e072";
}

.icon-arrows-square-remove:before {
    content: "\e073";
}

.icon-arrows-square-right:before {
    content: "\e074";
}

.icon-arrows-square-up:before {
    content: "\e075";
}

.icon-arrows-square-upleft:before {
    content: "\e076";
}

.icon-arrows-square-upright:before {
    content: "\e077";
}

.icon-arrows-squares:before {
    content: "\e078";
}

.icon-arrows-stretch-diagonal1:before {
    content: "\e079";
}

.icon-arrows-stretch-diagonal2:before {
    content: "\e07a";
}

.icon-arrows-stretch-diagonal3:before {
    content: "\e07b";
}

.icon-arrows-stretch-diagonal4:before {
    content: "\e07c";
}

.icon-arrows-stretch-horizontal1:before {
    content: "\e07d";
}

.icon-arrows-stretch-horizontal2:before {
    content: "\e07e";
}

.icon-arrows-stretch-vertical1:before {
    content: "\e07f";
}

.icon-arrows-stretch-vertical2:before {
    content: "\e080";
}

.icon-arrows-switch-horizontal:before {
    content: "\e081";
}

.icon-arrows-switch-vertical:before {
    content: "\e082";
}

.icon-arrows-up:before {
    content: "\e083";
}

.icon-arrows-up-double-33:before {
    content: "\e084";
}

.icon-arrows-upleft:before {
    content: "\e085";
}

.icon-arrows-upright:before {
    content: "\e086";
}

.icon-arrows-vertical:before {
    content: "\e087";
}

@font-face {
    font-family: "linea-basic-10";
    src: url("../fonts/linea-basic-10.eot");
    src: url("../fonts/linea-basic-10.eot?#iefix") format("embedded-opentype"), url("../fonts/linea-basic-10.woff") format("woff"), url("../fonts/linea-basic-10.ttf") format("truetype"), url("../fonts/linea-basic-10.svg#linea-basic-10") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-basic-"]:before,
[class*=" icon-basic-"]:before {
    font-family: "linea-basic-10" !important;
    font-style: normal !important;
    font-weight: normal;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-basic-accelerator:before {
    content: "a";
}

.icon-basic-alarm:before {
    content: "b";
}

.icon-basic-anchor:before {
    content: "c";
}

.icon-basic-anticlockwise:before {
    content: "d";
}

.icon-basic-archive:before {
    content: "e";
}

.icon-basic-archive-full:before {
    content: "f";
}

.icon-basic-ban:before {
    content: "g";
}

.icon-basic-battery-charge:before {
    content: "h";
}

.icon-basic-battery-empty:before {
    content: "i";
}

.icon-basic-battery-full:before {
    content: "j";
}

.icon-basic-battery-half:before {
    content: "k";
}

.icon-basic-bolt:before {
    content: "l";
}

.icon-basic-book:before {
    content: "m";
}

.icon-basic-book-pen:before {
    content: "n";
}

.icon-basic-book-pencil:before {
    content: "o";
}

.icon-basic-bookmark:before {
    content: "p";
}

.icon-basic-calculator:before {
    content: "q";
}

.icon-basic-calendar:before {
    content: "r";
}

.icon-basic-cards-diamonds:before {
    content: "s";
}

.icon-basic-cards-hearts:before {
    content: "t";
}

.icon-basic-case:before {
    content: "u";
}

.icon-basic-chronometer:before {
    content: "v";
}

.icon-basic-clessidre:before {
    content: "w";
}

.icon-basic-clock:before {
    content: "x";
}

.icon-basic-clockwise:before {
    content: "y";
}

.icon-basic-cloud:before {
    content: "z";
}

.icon-basic-clubs:before {
    content: "A";
}

.icon-basic-compass:before {
    content: "B";
}

.icon-basic-cup:before {
    content: "C";
}

.icon-basic-diamonds:before {
    content: "D";
}

.icon-basic-display:before {
    content: "E";
}

.icon-basic-download:before {
    content: "F";
}

.icon-basic-exclamation:before {
    content: "G";
}

.icon-basic-eye:before {
    content: "H";
}

.icon-basic-eye-closed:before {
    content: "I";
}

.icon-basic-female:before {
    content: "J";
}

.icon-basic-flag1:before {
    content: "K";
}

.icon-basic-flag2:before {
    content: "L";
}

.icon-basic-floppydisk:before {
    content: "M";
}

.icon-basic-folder:before {
    content: "N";
}

.icon-basic-folder-multiple:before {
    content: "O";
}

.icon-basic-gear:before {
    content: "P";
}

.icon-basic-geolocalize-01:before {
    content: "Q";
}

.icon-basic-geolocalize-05:before {
    content: "R";
}

.icon-basic-globe:before {
    content: "S";
}

.icon-basic-gunsight:before {
    content: "T";
}

.icon-basic-hammer:before {
    content: "U";
}

.icon-basic-headset:before {
    content: "V";
}

.icon-basic-heart:before {
    content: "W";
}

.icon-basic-heart-broken:before {
    content: "X";
}

.icon-basic-helm:before {
    content: "Y";
}

.icon-basic-home:before {
    content: "Z";
}

.icon-basic-info:before {
    content: "0";
}

.icon-basic-ipod:before {
    content: "1";
}

.icon-basic-joypad:before {
    content: "2";
}

.icon-basic-key:before {
    content: "3";
}

.icon-basic-keyboard:before {
    content: "4";
}

.icon-basic-laptop:before {
    content: "5";
}

.icon-basic-life-buoy:before {
    content: "6";
}

.icon-basic-lightbulb:before {
    content: "7";
}

.icon-basic-link:before {
    content: "8";
}

.icon-basic-lock:before {
    content: "9";
}

.icon-basic-lock-open:before {
    content: "!";
}

.icon-basic-magic-mouse:before {
    content: "\"";
}

.icon-basic-magnifier:before {
    content: "#";
}

.icon-basic-magnifier-minus:before {
    content: "$";
}

.icon-basic-magnifier-plus:before {
    content: "%";
}

.icon-basic-mail:before {
    content: "&";
}

.icon-basic-mail-multiple:before {
    content: "'";
}

.icon-basic-mail-open:before {
    content: "(";
}

.icon-basic-mail-open-text:before {
    content: ")";
}

.icon-basic-male:before {
    content: "*";
}

.icon-basic-map:before {
    content: "+";
}

.icon-basic-message:before {
    content: ",";
}

.icon-basic-message-multiple:before {
    content: "-";
}

.icon-basic-message-txt:before {
    content: ".";
}

.icon-basic-mixer2:before {
    content: "/";
}

.icon-basic-mouse:before {
    content: ":";
}

.icon-basic-notebook:before {
    content: ";";
}

.icon-basic-notebook-pen:before {
    content: "<";
}

.icon-basic-notebook-pencil:before {
    content: "=";
}

.icon-basic-paperplane:before {
    content: ">";
}

.icon-basic-pencil-ruler:before {
    content: "?";
}

.icon-basic-pencil-ruler-pen:before {
    content: "@";
}

.icon-basic-photo:before {
    content: "[";
}

.icon-basic-picture:before {
    content: "]";
}

.icon-basic-picture-multiple:before {
    content: "^";
}

.icon-basic-pin1:before {
    content: "_";
}

.icon-basic-pin2:before {
    content: "`";
}

.icon-basic-postcard:before {
    content: "{";
}

.icon-basic-postcard-multiple:before {
    content: "|";
}

.icon-basic-printer:before {
    content: "}";
}

.icon-basic-question:before {
    content: "~";
}

.icon-basic-rss:before {
    content: "\\";
}

.icon-basic-server:before {
    content: " \e000";
}

.icon-basic-server2:before {
    content: "\e001";
}

.icon-basic-server-cloud:before {
    content: "\e002";
}

.icon-basic-server-download:before {
    content: "\e003";
}

.icon-basic-server-upload:before {
    content: "\e004";
}

.icon-basic-settings:before {
    content: "\e005";
}

.icon-basic-share:before {
    content: "\e006";
}

.icon-basic-sheet:before {
    content: "\e007";
}

.icon-basic-sheet-multiple:before {
    content: "\e008";
}

.icon-basic-sheet-pen:before {
    content: "\e009";
}

.icon-basic-sheet-pencil:before {
    content: "\e00a";
}

.icon-basic-sheet-txt:before {
    content: "\e00b";
}

.icon-basic-signs:before {
    content: "\e00c";
}

.icon-basic-smartphone:before {
    content: "\e00d";
}

.icon-basic-spades:before {
    content: "\e00e";
}

.icon-basic-spread:before {
    content: "\e00f";
}

.icon-basic-spread-bookmark:before {
    content: "\e010";
}

.icon-basic-spread-text:before {
    content: "\e011";
}

.icon-basic-spread-text-bookmark:before {
    content: "\e012";
}

.icon-basic-star:before {
    content: "\e013";
}

.icon-basic-tablet:before {
    content: "\e014";
}

.icon-basic-target:before {
    content: "\e015";
}

.icon-basic-todo:before {
    content: "\e016";
}

.icon-basic-todo-pen:before {
    content: "\e017";
}

.icon-basic-todo-pencil:before {
    content: "\e018";
}

.icon-basic-todo-txt:before {
    content: "\e019";
}

.icon-basic-todolist-pen:before {
    content: "\e01a";
}

.icon-basic-todolist-pencil:before {
    content: "\e01b";
}

.icon-basic-trashcan:before {
    content: "\e01c";
}

.icon-basic-trashcan-full:before {
    content: "\e01d";
}

.icon-basic-trashcan-refresh:before {
    content: "\e01e";
}

.icon-basic-trashcan-remove:before {
    content: "\e01f";
}

.icon-basic-upload:before {
    content: "\e020";
}

.icon-basic-usb:before {
    content: "\e021";
}

.icon-basic-video:before {
    content: "\e022";
}

.icon-basic-watch:before {
    content: "\e023";
}

.icon-basic-webpage:before {
    content: "\e024";
}

.icon-basic-webpage-img-txt:before {
    content: "\e025";
}

.icon-basic-webpage-multiple:before {
    content: "\e026";
}

.icon-basic-webpage-txt:before {
    content: "\e027";
}

.icon-basic-world:before {
    content: "\e028";
}

@font-face {
    font-family: "linea-ecommerce-10";
    src: url("../fonts/linea-ecommerce-10.eot");
    src: url("../fonts/linea-ecommerce-10.eot?#iefix") format("embedded-opentype"), url("../fonts/linea-ecommerce-10.woff") format("woff"), url("../fonts/linea-ecommerce-10.ttf") format("truetype"), url("../fonts/linea-ecommerce-10.svg#linea-ecommerce-10") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-ecommerce-"]:before,
[class*=" icon-ecommerce-"]:before {
    font-family: "linea-ecommerce-10" !important;
    font-style: normal !important;
    font-weight: normal;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-ecommerce-bag:before {
    content: "a";
}

.icon-ecommerce-bag-check:before {
    content: "b";
}

.icon-ecommerce-bag-cloud:before {
    content: "c";
}

.icon-ecommerce-bag-download:before {
    content: "d";
}

.icon-ecommerce-bag-minus:before {
    content: "e";
}

.icon-ecommerce-bag-plus:before {
    content: "f";
}

.icon-ecommerce-bag-refresh:before {
    content: "g";
}

.icon-ecommerce-bag-remove:before {
    content: "h";
}

.icon-ecommerce-bag-search:before {
    content: "i";
}

.icon-ecommerce-bag-upload:before {
    content: "j";
}

.icon-ecommerce-banknote:before {
    content: "k";
}

.icon-ecommerce-banknotes:before {
    content: "l";
}

.icon-ecommerce-basket:before {
    content: "m";
}

.icon-ecommerce-basket-check:before {
    content: "n";
}

.icon-ecommerce-basket-cloud:before {
    content: "o";
}

.icon-ecommerce-basket-download:before {
    content: "p";
}

.icon-ecommerce-basket-minus:before {
    content: "q";
}

.icon-ecommerce-basket-plus:before {
    content: "r";
}

.icon-ecommerce-basket-refresh:before {
    content: "s";
}

.icon-ecommerce-basket-remove:before {
    content: "t";
}

.icon-ecommerce-basket-search:before {
    content: "u";
}

.icon-ecommerce-basket-upload:before {
    content: "v";
}

.icon-ecommerce-bath:before {
    content: "w";
}

.icon-ecommerce-cart:before {
    content: "x";
}

.icon-ecommerce-cart-check:before {
    content: "y";
}

.icon-ecommerce-cart-cloud:before {
    content: "z";
}

.icon-ecommerce-cart-content:before {
    content: "A";
}

.icon-ecommerce-cart-download:before {
    content: "B";
}

.icon-ecommerce-cart-minus:before {
    content: "C";
}

.icon-ecommerce-cart-plus:before {
    content: "D";
}

.icon-ecommerce-cart-refresh:before {
    content: "E";
}

.icon-ecommerce-cart-remove:before {
    content: "F";
}

.icon-ecommerce-cart-search:before {
    content: "G";
}

.icon-ecommerce-cart-upload:before {
    content: "H";
}

.icon-ecommerce-cent:before {
    content: "I";
}

.icon-ecommerce-colon:before {
    content: "J";
}

.icon-ecommerce-creditcard:before {
    content: "K";
}

.icon-ecommerce-diamond:before {
    content: "L";
}

.icon-ecommerce-dollar:before {
    content: "M";
}

.icon-ecommerce-euro:before {
    content: "N";
}

.icon-ecommerce-franc:before {
    content: "O";
}

.icon-ecommerce-gift:before {
    content: "P";
}

.icon-ecommerce-graph1:before {
    content: "Q";
}

.icon-ecommerce-graph2:before {
    content: "R";
}

.icon-ecommerce-graph3:before {
    content: "S";
}

.icon-ecommerce-graph-decrease:before {
    content: "T";
}

.icon-ecommerce-graph-increase:before {
    content: "U";
}

.icon-ecommerce-guarani:before {
    content: "V";
}

.icon-ecommerce-kips:before {
    content: "W";
}

.icon-ecommerce-lira:before {
    content: "X";
}

.icon-ecommerce-megaphone:before {
    content: "Y";
}

.icon-ecommerce-money:before {
    content: "Z";
}

.icon-ecommerce-naira:before {
    content: "0";
}

.icon-ecommerce-pesos:before {
    content: "1";
}

.icon-ecommerce-pound:before {
    content: "2";
}

.icon-ecommerce-receipt:before {
    content: "3";
}

.icon-ecommerce-receipt-bath:before {
    content: "4";
}

.icon-ecommerce-receipt-cent:before {
    content: "5";
}

.icon-ecommerce-receipt-dollar:before {
    content: "6";
}

.icon-ecommerce-receipt-euro:before {
    content: "7";
}

.icon-ecommerce-receipt-franc:before {
    content: "8";
}

.icon-ecommerce-receipt-guarani:before {
    content: "9";
}

.icon-ecommerce-receipt-kips:before {
    content: "!";
}

.icon-ecommerce-receipt-lira:before {
    content: "\"";
}

.icon-ecommerce-receipt-naira:before {
    content: "#";
}

.icon-ecommerce-receipt-pesos:before {
    content: "$";
}

.icon-ecommerce-receipt-pound:before {
    content: "%";
}

.icon-ecommerce-receipt-rublo:before {
    content: "&";
}

.icon-ecommerce-receipt-rupee:before {
    content: "'";
}

.icon-ecommerce-receipt-tugrik:before {
    content: "(";
}

.icon-ecommerce-receipt-won:before {
    content: ")";
}

.icon-ecommerce-receipt-yen:before {
    content: "*";
}

.icon-ecommerce-receipt-yen2:before {
    content: "+";
}

.icon-ecommerce-recept-colon:before {
    content: ",";
}

.icon-ecommerce-rublo:before {
    content: "-";
}

.icon-ecommerce-rupee:before {
    content: ".";
}

.icon-ecommerce-safe:before {
    content: "/";
}

.icon-ecommerce-sale:before {
    content: ":";
}

.icon-ecommerce-sales:before {
    content: ";";
}

.icon-ecommerce-ticket:before {
    content: "<";
}

.icon-ecommerce-tugriks:before {
    content: "=";
}

.icon-ecommerce-wallet:before {
    content: ">";
}

.icon-ecommerce-won:before {
    content: "?";
}

.icon-ecommerce-yen:before {
    content: "@";
}

.icon-ecommerce-yen2:before {
    content: "[";
}

@font-face {
    font-family: "linea-music-10";
    src: url("../fonts/linea-music-10.eot");
    src: url("../fonts/linea-music-10.eot?#iefix") format("embedded-opentype"), url("../fonts/linea-music-10.woff") format("woff"), url("../fonts/linea-music-10.ttf") format("truetype"), url("../fonts/linea-music-10.svg#linea-music-10") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="icon-music-"]:before,
[class*=" icon-music-"]:before {
    font-family: "linea-music-10" !important;
    font-style: normal !important;
    font-weight: normal;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-music-beginning-button:before {
    content: "a";
}

.icon-music-bell:before {
    content: "b";
}

.icon-music-cd:before {
    content: "c";
}

.icon-music-diapason:before {
    content: "d";
}

.icon-music-eject-button:before {
    content: "e";
}

.icon-music-end-button:before {
    content: "f";
}

.icon-music-fastforward-button:before {
    content: "g";
}

.icon-music-headphones:before {
    content: "h";
}

.icon-music-ipod:before {
    content: "i";
}

.icon-music-loudspeaker:before {
    content: "j";
}

.icon-music-microphone:before {
    content: "k";
}

.icon-music-microphone-old:before {
    content: "l";
}

.icon-music-mixer:before {
    content: "m";
}

.icon-music-mute:before {
    content: "n";
}

.icon-music-note-multiple:before {
    content: "o";
}

.icon-music-note-single:before {
    content: "p";
}

.icon-music-pause-button:before {
    content: "q";
}

.icon-music-play-button:before {
    content: "r";
}

.icon-music-playlist:before {
    content: "s";
}

.icon-music-radio-ghettoblaster:before {
    content: "t";
}

.icon-music-radio-portable:before {
    content: "u";
}

.icon-music-record:before {
    content: "v";
}

.icon-music-recordplayer:before {
    content: "w";
}

.icon-music-repeat-button:before {
    content: "x";
}

.icon-music-rewind-button:before {
    content: "y";
}

.icon-music-shuffle-button:before {
    content: "z";
}

.icon-music-stop-button:before {
    content: "A";
}

.icon-music-tape:before {
    content: "B";
}

.icon-music-volume-down:before {
    content: "C";
}

.icon-music-volume-up:before {
    content: "D";
}