.awssld {
  --organic-arrow-thickness: 6px !important;
  --organic-arrow-color: #fff !important;
  --loader-bar-color: rgba(32, 29, 29, 0.205) !important;
}

.special-font {
  font-size: 4rem;
  font-family: 'The Nautigal', cursive;
  /*  font-weight: 400;
   line-height: 1.6; */
}

.vertical-center {
  text-align: center;
  padding-top: 10rem;
}

.sp-bottom {
  padding-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .sp-bottom {
    padding-bottom: 90px;
  }
}

@media only screen and (max-width: 767.98px) {
  .sp-bottom {
    padding-bottom: 60px;
  }
  .vertical-center {
    padding-top: 2rem;
  }
}

.floater-right {
  position: fixed;
  right: 2vw;
  bottom: 5vh;
  z-index: 100;
}

.floater-left {
  position: fixed;
  left: 2vw;
  bottom: 5vh;
  z-index: 100;
}

#spinneroverlay {
  background: #ccc;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
  opacity: 0.4;
}

.spinner {
  margin: 0 auto;
  height: 64px;
  width: 64px;
  animation: spinn 0.8s infinite linear;
  border: 1px solid #244e50;
  border-right-color: transparent;
  border-radius: 50%;
}

@keyframes spinn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* canbedeleted */
.slider-text {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: white;
  font-size: 46px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-transform: uppercase;
}

/* canbedeleted */
.slider-container {
  display: block;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
  height: auto;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.awssld {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 90vh;
}

.awssld__wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
}

.awssld__container {
  display: block;
  width: 100%;
  height: 0;
}

.awssld__content {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.awssld__content > img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.animate-character {
  color: #fff;
  -webkit-animation: mainFadeIn 2s forwards;
  -o-animation: mainFadeIn 2s forwards;
  animation: mainFadeIn 2s forwards;
  animation-delay: 1.6s;
  opacity: 0;
  display: flex;
  align-items: baseline;
  position: relative;
  -webkit-transition: all 3000ms ease;
  transition: all 3000ms ease;
  text-transform: uppercase;
}

.box-animation {
  width: 300px;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title-animation {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}

.block-animation {
  width: 0%;
  height: inherit;
  background: #f16521;
  position: absolute;
  animation: mainBlock 2s cubic-bezier(0.74, 0.06, 0.4, 0.92) forwards;
  display: flex;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes secBlock {
  0% {
    width: 0%;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}
@keyframes mainFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes popIn {
  0% {
    width: 0px;
    height: 0px;
    background: #e9d856;
    border: 0px solid #ddd;
    opacity: 0;
  }
  50% {
    width: 10px;
    height: 10px;
    background: #e9d856;
    opacity: 1;
    bottom: 45px;
  }
  65% {
    width: 7px;
    height: 7px;
    bottom: 0px;
    width: 15px;
  }
  80% {
    width: 10px;
    height: 10px;
    bottom: 20px;
  }
  100% {
    width: 7px;
    height: 7px;
    background: #e9d856;
    border: 0px solid #222;
    bottom: 13px;
  }
}
@keyframes secFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes top {
  0% {
    opacity: 0;
    bottom: -80px;
  }
  100% {
    opacity: 1;
    bottom: 0px;
  }
}
