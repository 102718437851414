/*-----------------------------------------
[Table Of Content]

    01. Typography CSS
    02. Color CSS
    03. Common Style CSS
        3.0 - Template Spacing
        3.1 - Buttons Style
        3.2 - Background Image
        3.3 - Overflow
        3.4 - Section title Style
        3.5 - List Style
        3.6 - Site Content Wrapper
        3.7 - Scroll Top Button
        3.8 - LightSlider Customization
        3.9 - Tippy Customization
        3.10 - Magnific JS Customization
        3.11 - Blockquote Style
        3.12 - Nice Select Customization
             - Preloader Style Css
    04. Header Area Style CSS
        4.1 - Main Navigation CSS
    05. Off Canvas Style CSS
    06. Search Box Style CSS
    07. About Style CSS
    08. Portfolio Style CSS
    09. Skills Style CSS
    10. Service Style CSS
    11. Testimonial Style CSS
    12. Brand Logo Style CSS
    13. Call to Action Style CSS
    14. Blog Post Style CSS
    15. List Box Style CSS
    16. Icon Box Style CSS
    17. Full Page Home Page Style
    18. Contact Content Style
    19. Counter Style CSS
    20. Progress Bar Style CSS
    21. Pricing Table Style CSS
    22. Newsletter Style CSS
    23. Flip Box Style CSS
    24. Social Icons Style CSS
    25. Home FullScreen Carousel Style CSS
    26. Home Blog Slider Style CSS
    27. Banner Style CSS
    28. Home Portfolio FullScreen Slider CSS
    29. Home Modern Shop Style CSS
    30. Product Style CSS
    31. Home Justified Gallery Style CSS
    32. Home Portfolio Split Style CSS
    33. Home vCard Style CSS
    34. Accordion Style Css
    35. Restaurant Menu Style Css
    36. Team Member Style Css
    37. Schedules Style Css
    38. Home Wedding Style Css
    39. Revolution Slider Style Css
    40. Event Home Hero Style Css
    41. About Page Style Css
    42. Timeline Style Css
    43. Page Header Style Css
    44. 404 Page Not Found Page Style Css
    45. Maintenance Page Style Css
    46. Coming Soon Page Style Css
    47. F.A.Q Page Style Css
    48. Blog Pages Style Css
    49. Sidebar Style Css
    50. Blog Details Page Style Css
    51. Portfolio Details Page Style Css
    52. Shop Pages Style Css
    53. Single Product Pages Style Css
    54. Checkout Page Pages Style Css
    55. Cart Page Pages Style Css
    56. Freelancer Hero Style Css
    57. Home Freelancer Style Css
    58. Footer Style CSS
----------------------------------------*/
/*=====================================
   01. Typography CSS
===================================== */
body {
    color: #878c9b;
    font-size: 1.125rem;
    font-family: "Karla", sans-serif;
    font-weight: 400;
    line-height: 1.6;
    margin: 0;
}

@media only screen and (max-width: 575.98px) {
    body {
        font-size: 1rem;
    }
}

/* Remove text-shadow in selection highlight. */
::-moz-selection {
    background: #f16521;
    color: #FFFFFF;
    text-shadow: none;
}

::selection {
    background: #f16521;
    color: #FFFFFF;
    text-shadow: none;
}

/* A better looking default horizontal rule */
hr {
    border: 0;
    border-top: 1px solid #eee;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    height: 1px;
    margin: 1em 0;
    padding: 0;
    overflow: visible;
}

/* Remove the gap between audio, canvas, iframes,images, videos */
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/* Remove default fieldset styles. */
fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

/* Allow only vertical resizing of textareas. */
textarea {
    resize: vertical;
}

/* Anchor Tag Default Style */
a {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    outline: none;
    text-decoration: none;
}

a:hover,
a:active,
a:focus {
    color: inherit;
    outline: none;
    text-decoration: none;
}

textarea:focus,
textarea:active,
input:focus,
input:active {
    outline: none;
}

/* Heading Default Style */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 400;
    color: #5f362e;
    line-height: 1.2;
}

h1,
.h1 {
    font-size: 3.75rem;
    margin-bottom: 6px;
    margin-top: -15px;
}

@media (max-width: 1200px) {

    h1,
    .h1 {
        font-size: calc(1.5rem + 3vw);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {

    h1,
    .h1 {
        margin-top: -12px;
    }
}

@media only screen and (max-width: 767.98px) {

    h1,
    .h1 {
        margin-top: -8px;
    }
}

h2,
.h2 {
    font-size: 3.5rem;
    margin-bottom: 6px;
    margin-top: -14px;
}

@media (max-width: 1200px) {

    h2,
    .h2 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {

    h2,
    .h2 {
        margin-top: -10px;
    }
}

@media only screen and (max-width: 767.98px) {

    h2,
    .h2 {
        margin-top: -7px;
    }
}

h3,
.h3 {
    font-size: 3rem;
    margin-bottom: 10px;
    margin-top: -12px;
}

@media (max-width: 1200px) {

    h3,
    .h3 {
        font-size: calc(1.425rem + 2.1vw);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {

    h3,
    .h3 {
        margin-top: -9px;
    }
}

@media only screen and (max-width: 767.98px) {

    h3,
    .h3 {
        margin-top: -6px;
    }
}

h4,
.h4 {
    font-size: 2rem;
    margin-bottom: 14px;
    margin-top: -8px;
}

@media (max-width: 1200px) {

    h4,
    .h4 {
        font-size: calc(1.325rem + 0.9vw);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {

    h4,
    .h4 {
        margin-top: -6px;
    }
}

@media only screen and (max-width: 767.98px) {

    h4,
    .h4 {
        margin-top: -5px;
    }
}

h5,
.h5 {
    font-size: 1.625rem;
    margin-bottom: 15px;
}

@media (max-width: 1200px) {

    h5,
    .h5 {
        font-size: calc(1.2875rem + 0.45vw);
    }
}

h6,
.h6 {
    font-size: 1.25rem;
    margin-bottom: 12px;
}

/* Paragraph Margin */
p {
    margin-bottom: 15px;
}

p:last-child {
    margin-bottom: 0;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

strong,
b {
    font-weight: 700;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

button:active,
button:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

[data-mfp-src],
.btn-img-popup {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}

.parallax {
    background-repeat: no-repeat;
}

.form-message.alert {
    margin-top: 10px;
}

/*==========================
02. Color CSS
============================*/
.bg-white {
    background-color: #FFFFFF;
}

.bg-softWhite {
    background-color: #f8f8f8 !important;
}

.bg-softLite {
	background-color: #fbefe1 !important;
}

.bg-brand {
    background-color: #f16521;
}

.bg-secondary {
    background-color: #5f362e !important;
}

.bg-secondary-soft {
    background-color: #263466;
}

.bg-secondary-2 {
    background-color: #496189;
}

.bg-blackSoft {
    background-color: #222222;
}

.text-brand {
    color: #f16521;
}

/*============================
03. Common Style CSS
==============================*/
/*------------------
3.1 - Buttons Style
--------------------*/
.btn-group .btn {
    margin-right: 15px;
}

.btn-group .btn:last-child {
    margin-right: 0;
}

.btn {
    border-radius: 0;
    color: #5f362e;
    display: inline-block;
    padding: 0;
    font-size: 18px;
    -webkit-transition: 0.4s ease-in;
    -o-transition: 0.4s ease-in;
    transition: 0.4s ease-in;
    outline: none;
    position: relative;
		
}

.btn:focus,
.btn:active {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn-bottom:before {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    height: 1px;
    width: 100%;
}

.btn-bottom:hover:before {
    bottom: -10px;
}

.btn-bordered {
    border-radius: 50px;
    color: #5f362e;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 20px 40px;
    letter-spacing: 1.75px;
		background-color: #5f362e;
}

@media only screen and (max-width: 767.98px) {
    .btn-bordered {
        padding: 15px 25px;
    }
}

.btn-bordered:hover {
    background-color: #130701;
    color: #FFFFFF;
}

.btn-secondary {
    border: 3px solid #5f362e;
    background-color: #5f362e;
    border-radius: 50px;
    color: #f16521;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 20px 40px;
    letter-spacing: 1.75px;
}

@media only screen and (max-width: 767.98px) {
    .btn-secondary {
        padding: 15px 25px;
    }
}

.btn-secondary:hover {
    background-color: transparent;
    border-color: #f16521;
    color: #5f362e;
}

.btn-brand {
    background-color: #f16521;
    border: 3px solid #f16521;
    border-radius: 50px;
    color: #5f362e;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    padding: 20px 40px;
    letter-spacing: 1.75px;
}

@media only screen and (max-width: 767.98px) {
    .btn-brand {
        padding: 15px 25px;
    }
}

.btn-brand:hover {
    background-color: transparent;
    color: #f16521;
}

.btn-xs {
    padding: 8px 15px;
    font-size: 13px;
}

.btn-md {
    padding: 15px 25px;
}

.btn-lg {
    padding: 18px 30px;
}

/*----------------------
3.2 - Background Image
-----------------------*/
.bg-img {
    background: no-repeat center center;
    background-size: cover;
}

.bg-img-tr {
    background: no-repeat top right;
}

.bg-img-tl {
    background: no-repeat top left;
}

.bg-img-br {
    background: no-repeat bottom right;
}

.bg-img-bl {
    background: no-repeat bottom left;
}

.bg-img-nr {
    background: no-repeat center center;
    background-size: contain;
}

/*-----------------
3.3 - Overflow
-------------------*/
.fix {
    overflow: hidden;
}

.fix-x {
    overflow-x: hidden;
}

.fix-y {
    overflow-y: hidden;
}

/*-------------------------
3.4 - Section title Style
--------------------------*/
.section-title {
    margin-bottom: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .section-title {
        margin-bottom: 48px;
    }
}

@media only screen and (max-width: 767.98px) {
    .section-title {
        margin-bottom: 32px;
    }
}

.section-title h5 {
    color: #f16521;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 28px;
}

@media only screen and (max-width: 767.98px) {
    .section-title h5 {
        margin-bottom: 18px;
    }
}

.section-title h2 {
    margin-bottom: 0;
}

.section-title p {
    margin-top: 27px;
    margin-bottom: 0;
}

@media only screen and (max-width: 767.98px) {
    .section-title p {
        margin-top: 14px;
    }
}

.section-title .btn {
    margin-top: 25px;
}

@media only screen and (max-width: 767.98px) {
    .section-title .btn {
        margin-top: 20px;
    }
}

.section-title--white h2 {
    color: #FFFFFF;
}

.section-title--brand h2 {
    color: #f16521;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .section-title--wp {
        margin-bottom: 83px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .section-title--wp {
        margin-bottom: 52px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .section-title--last-btn {
        margin-bottom: 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .section-title--last-btn {
        margin-bottom: 40px;
    }
}

/*-------------------------
3.5 - List Style
--------------------------*/
.ht-list li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
}

.ht-list li:last-child {
    margin-bottom: 0;
}

.ht-list li:before {
    content: '\f00c';
    font-family: "FontAwesome";
    color: #5f362e;
    position: absolute;
    font-weight: 300;
    font-size: 14px;
    top: 2px;
    left: 0;
}

.ht-list-brand li:before {
    color: #f16521;
}

.ht-list-number {
    margin-left: 15px;
}

.ht-list-number li {
    list-style: decimal;
    padding-left: 10px;
}

.ht-list-number li:before {
    display: none;
}

/*--------------------------------
3.6 - Site Content Wrapper
----------------------------------*/
.site-wrapper {
    background-color: #FFFFFF;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

@media only screen and (min-width: 1200px) {
    .site-wrapper--left-header .container-fluid {
        padding: 0 100px;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.container-fluid {
    padding: 0 15px;
}

@media screen and (min-width: 1201px) {
    .container-fluid {
        padding: 0 100px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .container-fluid {
        max-width: 720px;
        margin: auto;
    }
}

/*--------------------------------
3.7 - Scroll Top Button
----------------------------------*/
.btn-scroll-top {
    background-color: #5f362e;
    border-radius: 50%;
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    cursor: pointer;
    font-size: 1.5625rem;
    display: block;
    text-align: center;
    line-height: 60px;
    position: fixed;
    bottom: -60px;
    right: 30px;
    height: 60px;
    width: 60px;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media (max-width: 1200px) {
    .btn-scroll-top {
        font-size: calc(1.28125rem + 0.375vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .btn-scroll-top {
        line-height: 50px;
        height: 50px;
        width: 50px;
    }
}

.btn-scroll-top:hover {
    background-color: #f16521;
}

.btn-scroll-top.show {
    bottom: 30px;
    opacity: 1;
    visibility: visible;
}

/*--------------------------------
3.8 - LightSlider Customization
----------------------------------*/
.lSSlideOuter .lSAction a {
    background-color: #FFFFFF;
    border-radius: 50%;
    border: 1px solid #EEEEEE;
    cursor: pointer;
    font-size: 1.5625rem;
    line-height: 70px;
    height: 70px;
    width: 70px;
    opacity: 1;
    position: absolute;
    left: -80px;
    top: 50%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-top: auto;
}

@media (max-width: 1200px) {
    .lSSlideOuter .lSAction a {
        font-size: calc(1.28125rem + 0.375vw);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .lSSlideOuter .lSAction a {
        line-height: 50px;
        height: 50px;
        width: 50px;
        left: -50px;
    }
}

.lSSlideOuter .lSAction a.lSNext {
    left: auto;
    right: -80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .lSSlideOuter .lSAction a.lSNext {
        right: -50px;
    }
}

.lSSlideOuter .lSAction a:hover {
    background-color: #5f362e;
    border-color: #5f362e;
    color: #FFFFFF;
}

.lSSlideOuter .lSPager.lSpg {
    margin: 47px 0 0;
    line-height: 1;
}

@media only screen and (max-width: 767.98px) {
    .lSSlideOuter .lSPager.lSpg {
        margin: 38px 0 0;
    }
}

.lSSlideOuter .lSPager.lSpg li {
    margin-right: 10px;
}

.lSSlideOuter .lSPager.lSpg li:last-child {
    margin-right: 0;
}

.lSSlideOuter .lSPager.lSpg li a {
    background-color: #5f362e;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
    outline: none;
    height: 10px;
    width: 10px;
}

.lSSlideOuter .lSPager.lSpg li.active a {
    background-color: #f16521;
}

.lSSlideOuter .lSPager-2 li a {
    background-color: transparent;
    border: 3px solid #e6e8ee;
    height: 15px;
    width: 15px;
}

.lSSlideOuter .lSPager-2 li.active a,
.lSSlideOuter .lSPager-2 li:hover a {
    background-color: transparent;
    border-color: #f16521;
}

.lSSlideOuter.lightArrows-2 .lSAction a {
    background-color: transparent;
    border: none;
    color: #222222;
    font-size: 3.4375rem;
    line-height: 1;
    height: auto;
    width: auto;
}

@media (max-width: 1200px) {
    .lSSlideOuter.lightArrows-2 .lSAction a {
        font-size: calc(1.46875rem + 2.625vw);
    }
}

.lSSlideOuter.lightArrows-2 .lSAction a:hover {
    color: #f16521;
}

/*--------------------------------
3.9 - Tippy Customization
----------------------------------*/
.tippy-popper .tippy-tooltip {
    background-color: #5f362e;
    border-radius: 3px;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    padding: 7px 13px;
}

.tippy-popper[x-placement^='top'] .tippy-arrow {
    border-top-color: #5f362e !important;
}

.tippy-popper[x-placement^='bottom'] .tippy-arrow {
    border-bottom-color: #5f362e !important;
}

.tippy-popper[x-placement^='left'] .tippy-arrow {
    border-left-color: #5f362e !important;
}

.tippy-popper[x-placement^='right'] .tippy-arrow {
    border-right-color: #5f362e !important;
}

/*--------------------------------
3.10 - Magnific JS Customization
----------------------------------*/
.ht-mfp {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.ht-mfp.mfp-bg {
    background: #5f362e;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.ht-mfp .mfp-content {
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

@media only screen and (min-width: 1200px) {
    .ht-mfp .mfp-content {
        max-width: 70%;
    }
}

.ht-mfp .mfp-content .mfp-close {
    display: none;
}

.ht-mfp .mfp-close {
    background-color: #f16521;
    color: #FFFFFF;
    padding: 0;
    text-align: center;
    opacity: 1;
    top: 50px;
    right: 50px;
    height: 50px;
    width: 50px;
    line-height: 50px;
}

@media only screen and (max-width: 767.98px) {
    .ht-mfp .mfp-close {
        top: 30px;
        right: 30px;
        height: 40px;
        width: 40px;
        line-height: 40px;
    }
}

.ht-mfp .mfp-close:hover {
    background-color: #5f362e;
}

.ht-mfp .mfp-arrow {
    background-color: #f16521;
    opacity: 1;
    margin-left: 15px;
    height: 55px;
    width: 55px;
}

.ht-mfp .mfp-arrow:before {
    display: none;
}

.ht-mfp .mfp-arrow:after {
    content: '\f104';
    color: #FFFFFF;
    font-family: "FontAwesome";
    font-size: 1.5625rem;
    line-height: 40px;
    text-align: center;
    margin: 0;
    padding: 0;
    border: 0;
    position: absolute;
    width: 100%;
    height: 100%;
}

@media (max-width: 1200px) {
    .ht-mfp .mfp-arrow:after {
        font-size: calc(1.28125rem + 0.375vw);
    }
}

.ht-mfp .mfp-arrow-right {
    margin-left: auto;
    margin-right: 15px;
}

.ht-mfp .mfp-arrow-right:after {
    content: '\f105';
}

.ht-mfp .mfp-arrow:hover {
    background-color: #0a0e1c;
}

.ht-mfp.zoom-animate.mfp-bg {
    opacity: 0;
}

.ht-mfp.zoom-animate .mfp-content {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
}

.ht-mfp.zoom-animate.mfp-ready.mfp-bg {
    opacity: 0.9;
}

.ht-mfp.zoom-animate.mfp-ready .mfp-content {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.ht-mfp.zoom-animate.mfp-removing .mfp-content {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0;
}

.ht-mfp.zoom-animate.mfp-removing.mfp-bg {
    opacity: 0;
}

.ht-mfp.zoom-animate.mfp-removing .mfp-close {
    opacity: 0;
}

/*--------------------------------
3.11 - Blockquote Style
----------------------------------*/
blockquote,
.blockquote {
    background-color: #222222;
    color: #FFFFFF;
    font-size: 1.625rem;
    line-height: 1.4;
    padding: 42px 44px 80px;
    position: relative;
}

@media (max-width: 1200px) {

    blockquote,
    .blockquote {
        font-size: calc(1.2875rem + 0.45vw);
    }
}

@media only screen and (max-width: 767.98px) {

    blockquote,
    .blockquote {
        padding: 20px 22px 60px;
    }
}

blockquote:before,
.blockquote:before {
    content: '\f10e';
    font-family: "FontAwesome";
    position: absolute;
    right: 40px;
    bottom: 40px;
}

@media only screen and (max-width: 767.98px) {

    blockquote:before,
    .blockquote:before {
        right: 30px;
        bottom: 20px;
    }
}

blockquote-title,
.blockquote-title {
    color: #f16521;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 1.5px;
    line-height: 1;
}

/*--------------------------------
3.12 - Nice Select Customization
----------------------------------*/
.nice-select {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #f16521;
    color: #5f362e;
    float: inherit;
    font-size: 1.5rem;
    padding-left: 0;
}

@media (max-width: 1200px) {
    .nice-select {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.nice-select:hover,
.nice-select:focus {
    border-bottom-color: #f16521;
}

.nice-select:after {
    border-right-color: #5f362e;
    border-bottom-color: #5f362e;
    height: 7px;
    width: 7px;
}

.nice-select .list {
    border-radius: 0;
    color: #5f362e;
    font-size: 1rem;
    width: 100%;
}

@media only screen and (max-width: 767.98px) {
    .button-item .btn-xl {
        padding: 20px 40px;
    }
}

/*===========================
4.1 - Main Navigation CSS
=============================*/
.main-menu {
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.main-menu>li {
    margin-right: 30px;
}

.main-menu>li:last-child {
    margin-right: 0;
}

.main-menu>li>a {
    color: #5f362e;
    display: block;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 1.62px;
    text-transform: uppercase;
    padding-bottom: 8px;
    position: relative;
}

.main-menu>li>a:after {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateY(10px);
    -ms-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0;
    visibility: hidden;
}

.main-menu>li:hover>a:after,
.main-menu>li.active>a:after {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
}

.has-submenu {
    padding-right: 10px;
    position: relative;
}

.has-submenu>a {
    position: relative;
}

.has-submenu>a:before {
    content: '\f107';
    color: #5f362e;
    font-size: 13px;
    line-height: 1.2;
    font-family: "FontAwesome";
    position: absolute;
    right: -12px;
    top: 0;
}

.has-submenu:hover>.submenu-nav {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
}

.has-submenu:hover .menu-content .menu-content-inner h4,
.has-submenu:hover .menu-content .menu-content-inner .btn-brand {
    -webkit-transform: none !important;
    -ms-transform: none !important;
    transform: none !important;
}

.has-submenu .submenu-nav {
    background-color: #FFFFFF;
    border: 1px solid #eee;
    border-bottom: 2px solid #f16521;
    padding: 20px 0 25px;
    position: absolute;
    left: -25px;
    top: 100%;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    min-width: 250px;
    margin-top: 54px;
    z-index: 99;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .has-submenu .submenu-nav {
        min-width: 210px;
    }
}

.has-submenu .submenu-nav:before {
    content: '';
    position: absolute;
    height: 56px;
    width: 100%;
    left: 0;
    bottom: 100%;
}

.has-submenu .submenu-nav>li {
    padding: 6px 25px;
}

.has-submenu .submenu-nav>li:first-child {
    padding-top: 0;
}

.has-submenu .submenu-nav>li:last-child {
    padding-bottom: 0;
}

.has-submenu .submenu-nav>li a {
    color: #878c9b;
    font-weight: 400;
    font-size: 15px;
    letter-spacing: inherit;
    text-transform: capitalize;
}

.has-submenu .submenu-nav>li:hover>a {
    color: #f16521;
}

.has-submenu .submenu-nav>li.has-submenu {
    position: relative;
}

.has-submenu .submenu-nav>li.has-submenu a:before {
    display: none;
}

.has-submenu .submenu-nav>li.has-submenu:hover>.submenu-nav {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
}

.has-submenu .submenu-nav>li.has-submenu:after {
    content: '\f105';
    color: #878c9b;
    font-size: 15px;
    line-height: 1.2;
    font-family: "FontAwesome";
    position: absolute;
    right: 25px;
    top: 12px;
}

.has-submenu .submenu-nav>li.has-submenu .submenu-nav {
    left: 100%;
    top: 0;
    margin-top: -15px;
}

.has-submenu .submenu-nav-mega {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .has-submenu .submenu-nav-mega {
        width: 1000px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .has-submenu .submenu-nav-mega {
        width: 830px;
    }
}

.has-submenu .submenu-nav-mega .mega-menu-item {
    border-right: 1px solid #eee;
    padding: 25px 40px !important;
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
}

.has-submenu .submenu-nav-mega .mega-menu-item:last-child {
    border-right: 0;
}

.has-submenu .submenu-nav-mega .mega-menu-item>a {
    color: #5f362e !important;
    display: block;
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li {
    position: relative;
    padding: 6px 0;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li a:hover {
    color: #f16521;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li.feature a {
    position: relative;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li.feature a:before {
    content: '\f005';
    position: absolute;
    font-family: "FontAwesome";
    color: #f16521;
    right: -20px;
    top: 1px;
    font-size: 12px;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li.new a {
    position: relative;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li.new a:after {
    background-color: #ff0000;
    content: "New";
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 600;
    border-radius: 2px;
    position: absolute;
    top: 0;
    right: -38px;
    line-height: 1;
    padding: 2px 3px;
}

.has-submenu .submenu-nav-mega .mega-menu-item ul li:hover .menu-thumb {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content {
    padding: 0 !important;
    margin: -1px;
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner {
    background-image: url("../img/extra/mega-menu-bg.jpg");
    background-size: cover;
    background-position: center center;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 15px;
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4,
.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner .btn-brand {
    color: #FFFFFF;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4 {
    -webkit-transform: translateY(-80%);
    -ms-transform: translateY(-80%);
    transform: translateY(-80%);
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner h4 span {
    font-family: "Playfair Display", serif;
    color: #f16521;
    font-style: italic;
}

.has-submenu .submenu-nav-mega .mega-menu-item.menu-content .menu-content-inner .btn-brand {
    margin-top: 30px;
    font-weight: 700;
    -webkit-transform: translateY(80%);
    -ms-transform: translateY(80%);
    transform: translateY(80%);
}

.has-submenu.full-width {
    position: static;
}

.menu-thumb {
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    position: absolute;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    top: -10px;
    right: -80px;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .menu-thumb {
        right: 0;
        top: auto;
        bottom: 100%;
    }
}

/* Responsive Mobile Menu */
.res-mobile-menu {
    margin: 0 -10px;
}

.res-mobile-menu .slicknav_btn {
    display: none;
}

.res-mobile-menu .slicknav_menu {
    padding: 0;
}

.res-mobile-menu .slicknav_nav {
    background-color: #5f362e;
    display: block !important;
    padding: 20px 30px;
}

.res-mobile-menu .slicknav_nav li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;
}

.res-mobile-menu .slicknav_nav li:last-child {
    border-bottom: 0;
}

.res-mobile-menu .slicknav_nav li a {
    color: #FFFFFF;
    font-size: 18px;
    padding: 12px 0;
    margin: 0;
    text-transform: capitalize;
    position: relative;
}

.res-mobile-menu .slicknav_nav li a .slicknav_arrow {
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFFFFF;
    font-size: 14px;
    display: block;
    text-align: center;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 35px;
    line-height: 35px;
    width: 35px;
}

.res-mobile-menu .slicknav_nav li a a {
    padding: 0;
}

.res-mobile-menu .slicknav_nav li a:hover {
    color: #FFFFFF;
    background-color: transparent;
}

.res-mobile-menu .slicknav_nav li img {
    display: none;
}

.res-mobile-menu .slicknav_nav li div {
    display: none;
}

.res-mobile-menu .slicknav_nav li ul {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0;
    padding-left: 10px;
}

.res-mobile-menu .slicknav_nav li ul li a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.7);
}

.res-mobile-menu .slicknav_nav .slicknav_open>.slicknav_item {
    position: relative;
}

.res-mobile-menu .slicknav_nav .slicknav_open>.slicknav_item .slicknav_arrow {
    background-color: #f16521;
}

/*==========================
3.0 - Template Spacing
============================*/
.sm-top {
    margin-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-top {
        margin-top: 90px;
    }
}

@media only screen and (max-width: 767.98px) {
    .sm-top {
        margin-top: 60px;
    }
}

.sm-bottom {
    margin-bottom: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-bottom {
        margin-bottom: 90px;
    }
}

@media only screen and (max-width: 767.98px) {
    .sm-bottom {
        margin-bottom: 60px;
    }
}

.sm-top-wp,
.coming-soon-body .newsletter-form-wrap .form-input-item {
    margin-top: 115px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {

    .sm-top-wp,
    .coming-soon-body .newsletter-form-wrap .form-input-item {
        margin-top: 85px;
    }
}

@media only screen and (max-width: 767.98px) {

    .sm-top-wp,
    .coming-soon-body .newsletter-form-wrap .form-input-item {
        margin-top: 55px;
    }
}

.sm-y-wp {
    margin: 115px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sm-y-wp {
        margin: 85px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .sm-y-wp {
        margin: 60px 0;
    }
}

.sp-top {
    padding-top: 120px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-top {
        padding-top: 90px;
    }
}

@media only screen and (max-width: 767.98px) {
    .sp-top {
        padding-top: 60px;
    }
}

.sp-y {
    padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-y {
        padding: 90px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .sp-y {
        padding: 60px 0;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sp-y-md {
        padding: 90px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .sp-y-sm {
        padding: 60px 0;
    }
}

/*==========================
04. Header Area Style CSS
===========================*/
.header-area {
    padding: 50px 0;
}

@media only screen and (max-width: 767.98px) {
    .header-area {
        padding: 25px 0;
    }
}

.header-area.sticky-header {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.header-area.sticky-header.sticky {
    background-color: #FFFFFF;
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
    border: 0 !important;
    position: fixed;
    left: 0;
    top: 0;
    padding: 20px 0;
    width: 100%;
    z-index: 99;
}

.header-area.sticky-header.sticky .main-menu>li a:before {
    color: #5f362e;
}

.header-area.sticky-header.sticky .main-menu>li>.submenu-nav {
    margin-top: 24px;
}

.header-area.sticky-header.sticky .main-menu>li>.submenu-nav:before {
    height: 24px;
}

.header-area.sticky-header.sticky .header-action-area button,
.header-area.sticky-header.sticky .header-action-area a {
    color: #5f362e;
}

.header-area.sticky-header.sticky .header-action-area button.btn-menu span,
.header-area.sticky-header.sticky .header-action-area a.btn-menu span {
    background-color: #5f362e;
}

.header-area.transparent:not(.sticky) {
    color: #FFFFFF;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9;
}

.header-area.transparent:not(.sticky) .header-logo-area .logo-main {
    display: none;
}

.header-area.transparent:not(.sticky) .header-logo-area .logo-light {
    display: block;
}

.header-area.transparent:not(.sticky) .main-menu>li>a {
    color: #FFFFFF;
}

.header-area.transparent:not(.sticky) .main-menu>li>a:before {
    color: #FFFFFF;
}

.header-area.transparent:not(.sticky) .header-action-area button,
.header-area.transparent:not(.sticky) .header-action-area a {
    color: #FFFFFF;
}

.header-area.transparent:not(.sticky) .header-action-area .btn-menu span {
    background-color: #FFFFFF;
}

.header-area.christmas:not(.sticky) {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 30px 0;
}

.header-area.christmas:not(.sticky) .header-logo-area .logo-main {
    display: block;
}

.header-area.christmas:not(.sticky) .header-logo-area .logo-light {
    display: none;
}

.header-area.christmas:not(.sticky) .main-menu>li>a {
    color: #000000;
}

.header-area.christmas:not(.sticky) .main-menu>li>a:before {
    color: #000000;
}

.header-area.christmas:not(.sticky) .main-menu .has-submenu .submenu-nav {
    margin-top: 34px;
}

.header-area.christmas:not(.sticky) .main-menu .has-submenu .submenu-nav:before {
    height: 34px;
}

.header-area.christmas:not(.sticky) .header-action-area button,
.header-area.christmas:not(.sticky) .header-action-area a {
    color: #000000;
}

.header-area.christmas:not(.sticky) .header-action-area .btn-menu span {
    background-color: #000000;
}

.header-area.fixed-left {
    background-color: #FFFFFF;
    -webkit-box-shadow: 3px 1px 29px rgba(0, 0, 0, 0.12);
    box-shadow: 3px 1px 29px rgba(0, 0, 0, 0.12);
    padding: 100px 60px;
    position: fixed;
    height: 100vh;
    max-width: 300px;
    width: 100%;
    z-index: 99999;
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .header-area.fixed-left {
        padding: 60px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .header-area.fixed-left {
        position: static;
        height: auto;
        margin: auto;
        padding: 25px 15px;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .header-area.fixed-left {
        max-width: 960px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .header-area.fixed-left {
        max-width: 720px;
    }
}

@media only screen and (max-width: 767.98px) {
    .header-area.fixed-left {
        max-width: 540px;
    }
}

.header-area.fixed-left .header-left-fix-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .header-area.fixed-left .header-left-fix-inner {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
    }
}

.header-area.fixed-left .header-logo-area {
    max-width: 100%;
}

.header-area.fixed-left .header-logo-area a {
    max-width: 120px;
    display: block;
}

.header-area.fixed-left .header-navigation-area {
    margin: 0 -60px;
}

.header-area.fixed-left .header-navigation-area .nav {
    display: block;
}

.header-area.fixed-left .header-navigation-area .nav>li {
    margin-right: 0;
    margin-bottom: 20px;
    padding-right: 60px;
    padding-left: 60px;
    cursor: pointer;
    position: relative;
}

.header-area.fixed-left .header-navigation-area .nav>li:last-child {
    margin-bottom: 0;
}

.header-area.fixed-left .header-navigation-area .nav>li a {
    display: inline-block;
}

.header-area.fixed-left .header-navigation-area .nav>li .submenu-nav {
    left: 100%;
    margin-top: -20px;
    top: 0;
}

.header-area.fixed-left .header-navigation-area .nav>li .submenu-nav:before {
    display: none;
}

.header-area.fixed-left .header-navigation-area .nav>li .submenu-nav-mega {
    margin-left: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
    .header-area.fixed-left .header-navigation-area .nav>li .submenu-nav-mega {
        width: 860px;
    }
}

@media screen and (min-width: 1400px) {
    .header-area.fixed-left .header-navigation-area .nav>li .submenu-nav-mega {
        width: 1050px;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .header-area.fixed-left .header-navigation-area .nav>li .submenu-nav-mega .mega-menu-item {
        padding: 25px !important;
    }
}

.header-area.fixed-left .header-widget-area {
    font-size: 0.875rem;
}

.header-area.fixed-left .header-widget-area .copyright-content {
    margin-top: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .header-area-construction {
        padding: 40px 0;
    }
}

.header-area-construction .main-menu .submenu-nav {
    margin-top: 44px;
}

.header-area.header-right-align .header-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media only screen and (min-width: 1200px) and (max-width: 1240px) {
    .header-area.header-right-align .header-right {
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

.header-area.header-right-align .header-right .header-action-area {
    margin-left: 80px;
}

.header-area.header-right-align .header-right .header-navigation-area .has-submenu .submenu-nav {
    min-width: 220px;
    left: -35px;
}

.header-area.header-right-align .header-right .header-navigation-area .has-submenu .submenu-nav .submenu-nav {
    left: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .header-area.header-right-align .header-right .header-navigation-area .has-submenu .submenu-nav .submenu-nav {
        left: auto;
        right: 100%;
    }
}

.header-area.header-right-align.header-area.sticky-header.sticky .main-menu>li>.submenu-nav {
    margin-top: 21px;
}

.header-logo-area {
    max-width: 120px;
}

.header-logo-area .logo-light {
    display: none;
}

.header-action-area button,
.header-action-area a {
    color: #5f362e;
    font-size: 20px;
    line-height: 1;
    margin-left: 15px;
    vertical-align: middle;
}

.header-action-area button:first-child,
.header-action-area a:first-child {
    margin-left: 0;
}

.header-action-area button.btn-menu,
.header-action-area a.btn-menu {
    margin-top: -3px;
    position: relative;
    height: 18px;
    width: 22px;
}

.header-action-area button.btn-menu span,
.header-action-area a.btn-menu span {
    background-color: #5f362e;
    border-radius: 2px;
    left: 0;
    display: block;
    position: absolute;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    height: 2px;
    width: 100%;
}

.header-action-area button.btn-menu span:first-child,
.header-action-area a.btn-menu span:first-child {
    top: 0;
}

.header-action-area button.btn-menu span:nth-child(2),
.header-action-area a.btn-menu span:nth-child(2) {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-action-area button.btn-menu span:last-child,
.header-action-area a.btn-menu span:last-child {
    bottom: 0;
}

.header-action-area button.btn-menu:hover span:first-child,
.header-action-area button.btn-menu:hover span:last-child,
.header-action-area a.btn-menu:hover span:first-child,
.header-action-area a.btn-menu:hover span:last-child {
    width: 80%;
}

.header-top {
    padding: 15px 0;
}

.header-top a {
    color: #878c9b;
    font-size: 1rem;
    line-height: 1;
    margin-right: 20px;
}

@media only screen and (max-width: 479.98px) {
    .header-top a {
        margin-right: 10px;
    }
}

.header-top a:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 479.98px) {
    .header-top a.hide-a {
        display: none;
    }
}

.header-top .header-icons a:hover {
    color: #f16521;
}

.header-area.fixed-top.sticky-header {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 50px 0;
}

/*===========================
56. Footer Style CSS
===========================*/
.footer-area {
    background-color: #38322C;
    font-size: 1rem;
}

.footer-area a {
    color: #fff;
}

.footer-area--layout-2 {
    background-color: #FFFFFF;
    padding: 50px 0;
    margin: 0;
}

@media only screen and (max-width: 767.98px) {
    .footer-area--layout-2 {
        padding: 30px 0;
    }
}

.footer-area--light {
    background-color: transparent;
}

.footer-area.christmas {
    background-color: #011e0b;
}

.footer-area.christmas .widget-title {
    color: #FFFFFF;
    opacity: 0.9;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .reveal-footer {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
    }
}

.widget-item {
    margin-top: 38px;
}

.widget-item .widget-title {
    color: #f16521;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    margin-top: -1px;
    margin-bottom: 22px;
}

@media only screen and (max-width: 767.98px) {
    .widget-item .widget-title {
        margin-bottom: 15px;
    }
}

.widget-item address {
    line-height: 2;
    margin-bottom: 0;
}

.widget-list li {
    line-height: 2;
}

.widget-list li a:hover {
    color: #f16521;
}

.about-widget img {
    max-width: 120px;
    margin-bottom: 20px;
}

.footer-menu li {
    margin-right: 20px;
}

.footer-menu li:last-child {
    margin-right: 0;
}

.footer-menu li a {
    color: #878c9b;
    font-size: 14px;
    line-height: 1;
}

.footer-menu li a:hover {
    color: #f16521;
}

.footer-copyright-area {
    padding-bottom: 55px;
    margin-top: -15px;
}

.footer-copyright-area .copyright-txt {
    font-size: 14px;
}

.footer-onepage {
    padding: 95px 0;
}

@media only screen and (max-width: 767.98px) {
    .footer-onepage {
        padding: 40px 0;
    }
}

/*==========================
05. Off Canvas Style CSS
============================*/
.off-canvas-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.off-canvas-wrapper.active {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
}

.off-canvas-wrapper.active .off-canvas-inner {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.off-canvas-wrapper.active .btn-close {
    display: block;
}

.off-canvas-wrapper.active .off-canvas-overlay {
    opacity: 1;
    visibility: visible;
}

.off-canvas-wrapper .off-canvas-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
}

.off-canvas-wrapper .off-canvas-header .logo-area {
    max-width: 100px;
}

.off-canvas-wrapper .off-canvas-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    cursor: url("../img/icons/cancel-white.png"), auto;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    height: 100%;
    width: 100%;
    z-index: 2;
}

.off-canvas-wrapper .btn-close {
    color: #5f362e;
    font-size: 50px;
    line-height: 1;
}

.off-canvas-wrapper .btn-close i {
    line-height: 1;
}

.off-canvas-wrapper .btn-close:hover {
    color: #f16521;
}

.off-canvas-wrapper .off-canvas-inner {
    background-color: #FFFFFF;
    position: relative;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    height: 100vh;
    width: 100%;
    z-index: 3;
}

.off-canvas-wrapper .off-canvas-inner .off-canvas-content {
    padding: 0 10px;
    height: 100%;
    overflow-y: auto;
}

.off-canvas-wrapper .off-canvas-inner .off-canvas-content .off-canvas-item {
    margin-bottom: 30px;
}

.off-canvas-wrapper .off-canvas-inner .off-canvas-content .off-canvas-item:last-child {
    margin-bottom: 0;
}

.off-canvas-wrapper.aside-menu .off-canvas-inner {
    background-color: transparent;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.off-canvas-wrapper.aside-menu .off-canvas-inner .off-canvas-content {
    background-color: #5f362e;
    position: relative;
    width: 350px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
    z-index: 3;
}

@media only screen and (max-width: 479.98px) {
    .off-canvas-wrapper.aside-menu .off-canvas-inner .off-canvas-content {
        width: 320px;
    }
}

.off-canvas-wrapper.aside-menu .off-canvas-inner .off-canvas-content .close-action .btn-close {
    color: #FFFFFF;
}

.off-canvas-wrapper.aside-menu .off-canvas-inner .off-canvas-content .close-action .btn-close:hover {
    color: #f16521;
}

.off-canvas-wrapper.aside-menu.active .off-canvas-inner .off-canvas-content {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
}

/*==========================
06. Search Box Style CSS
===========================*/
.search-box-wrapper {
    background-color: rgba(24, 33, 65, 0.9);
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
}

.search-box-wrapper .search-box-content-inner {
    width: 70%;
    height: 100%;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    -o-transition: transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    opacity: 0;
    visibility: hidden;
}

@media only screen and (max-width: 575.98px) {
    .search-box-wrapper .search-box-content-inner {
        width: 100%;
        padding: 0 20px;
    }
}

.search-box-wrapper.show .search-box-content-inner {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}

.search-box-wrapper .search-close {
    color: #FFFFFF;
    font-size: 70px;
    line-height: 1;
    position: absolute;
    top: 50px;
    right: 100px;
}

@media only screen and (max-width: 767.98px) {
    .search-box-wrapper .search-close {
        top: 20px;
        right: 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .search-box-wrapper .search-close {
        top: 30px;
        right: 30px;
    }
}

.search-box-wrapper .search-close:hover {
    color: #f16521;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.search-box-form-wrap {
    width: 100%;
}

.search-box-form-wrap input[type="search"] {
    background-color: transparent;
    border: none;
    border-bottom: 5px solid #f16521;
    color: #f16521;
    display: block;
    font-size: 120px;
    line-height: 1;
    padding: 20px 0;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .search-box-form-wrap input[type="search"] {
        font-size: 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .search-box-form-wrap input[type="search"] {
        font-size: 40px;
    }
}

.search-box-form-wrap .search-note {
    color: #f16521;
    font-weight: 700;
    text-align: right;
    margin-top: 20px;
}

/*=================================
39. Revolution Slider Style Css
===================================*/
.rev_slider_wrapper .uranus.tparrows {
    background: rgba(255, 255, 255, 0);
    width: 50px;
    height: 50px;
}

.rev_slider_wrapper .uranus.tparrows:before {
    font-size: 2.5rem;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    line-height: 50px;
    height: 50px;
    width: 50px;
}

@media (max-width: 1200px) {
    .rev_slider_wrapper .uranus.tparrows:before {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.rev_slider_wrapper .uranus.tparrows:hover:before {
    opacity: 0.75;
}

.rev_slider_wrapper .rev-btn {
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.rev_slider_wrapper .rev-btn.btn-brand,
.rev_slider_wrapper .rev-btn.btn-bordered {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

@media only screen and (max-width: 767.98px) {
    .rev_slider_wrapper .btn img {
        width: 80px !important;
        height: 80px !important;
    }
}

.tp-caption.Button-Outline-Secondary,
.Button-Outline-Secondary {
    color: white;
    font-size: 14px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

.tp-caption.Button-Outline-Secondary:hover,
.Button-Outline-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

.tp-bannertimer.tp-bottom {
    visibility: hidden !important;
}

.rev_slider_wrapper .uranus .tp-bullet {
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0);
    -webkit-transition: box-shadow 0.3s ease;
    -webkit-transition: -webkit-box-shadow 0.3s ease;
    transition: -webkit-box-shadow 0.3s ease;
    -o-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease, -webkit-box-shadow 0.3s ease;
    background: transparent;
    width: 15px;
    height: 15px;
}

.rev_slider_wrapper .uranus .tp-bullet.selected,
.rev_slider_wrapper .uranus .tp-bullet:hover {
    -webkit-box-shadow: 0 0 0 2px white;
    box-shadow: 0 0 0 2px white;
    border: none;
    border-radius: 50%;
    background: transparent;
}

.rev_slider_wrapper .uranus .tp-bullet-inner {
    -webkit-transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
    transition: background-color 0.3s ease, -webkit-transform 0.3s ease;
    -o-transition: background-color 0.3s ease, transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease;
    transition: background-color 0.3s ease, transform 0.3s ease, -webkit-transform 0.3s ease;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 50%;
    background-color: white;
    background-color: rgba(255, 255, 255, 0.3);
    text-indent: -999em;
    cursor: pointer;
    position: absolute;
}

.rev_slider_wrapper .uranus .tp-bullet.selected .tp-bullet-inner,
.rev_slider_wrapper .uranus .tp-bullet:hover .tp-bullet-inner {
    -ms-transform: scale(0.4);
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    background-color: white;
}

.rev_slider_10_1_wrapper {
    display: none;
}

#rev_slider_10_1_wrapper .tp-loader.spinner4 {
    background-color: #FFFFFF !important;
}

#rev_slider_10_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#slide-24-layer-7 {
    z-index: 5;
    min-width: 567px;
    max-width: 162px;
    white-space: normal;
    font-size: 80px;
    line-height: 73px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.57px;
}

#slide-24-layer-9 {
    z-index: 6;
    min-width: 351px;
    max-width: 351px;
    white-space: normal;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-24-layer-11 {
    z-index: 7;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-25-layer-7 {
    z-index: 5;
    min-width: 567px;
    max-width: 162px;
    white-space: normal;
    font-size: 80px;
    line-height: 73px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.57px;
}

#slide-25-layer-9 {
    z-index: 6;
    min-width: 351px;
    max-width: 351px;
    white-space: normal;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-25-layer-11 {
    z-index: 7;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-26-layer-7 {
    z-index: 5;
    min-width: 567px;
    white-space: normal;
    font-size: 80px;
    line-height: 73px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.57px;
}

#slide-26-layer-9 {
    z-index: 6;
    min-width: 351px;
    max-width: 351px;
    white-space: normal;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-26-layer-11 {
    z-index: 7;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_11_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_11_1 {
    display: none;
}

#slide-27-layer-7 {
    z-index: 5;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 120px;
    line-height: 110px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-27-layer-9 {
    z-index: 6;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-27-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-28-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 100px;
    line-height: 140px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: -3px;
}

#slide-28-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 100px;
    line-height: 140px;
    font-weight: 400;
    color: #f16521;
    letter-spacing: -3px;
}

#slide-28-layer-3 {
    z-index: 7;
    white-space: nowrap;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 15px;
}

#slide-29-layer-7 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 110px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-29-layer-9 {
    z-index: 6;
    min-width: 358px;
    max-width: 358px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-29-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_12_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_12_1 {
    display: none;
}

#slide-30-layer-9 {
    z-index: 5;
    white-space: nowrap;
    font-size: 140px;
    line-height: 120px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-30-layer-15 {
    z-index: 6;
    white-space: nowrap;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-30-layer-17 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-31-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 120px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: -1px;
}

#slide-31-layer-3 {
    z-index: 6;
    white-space: nowrap;
    letter-spacing: 1.8px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-32-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 120px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: -1px;
}

#slide-32-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 22px;
    line-height: 22px;
    font-weight: 900;
    color: #f16521;
    letter-spacing: 10px;
    font-family: "Playfair Display", serif;
}

#rev_slider_13_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_13_1 {
    display: none;
}

#slide-33-layer-5 {
    z-index: 5;
    min-width: 117px;
    max-width: 117px;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-33-layer-7 {
    z-index: 6;
    min-width: 840px;
    max-width: 193px;
    white-space: normal;
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.64px;
}

#slide-33-layer-9 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    text-transform: uppercase;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-33-layer-11 {
    z-index: 6;
}

#slide-34-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.86px;
}

#slide-34-layer-5 {
    z-index: 6;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 1.8px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

#slide-34-layer-7 {
    z-index: 7;
    min-width: 831px;
    max-width: 831px;
    white-space: normal;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-34-layer-8 {
    z-index: 8;
    white-space: nowrap;
    letter-spacing: 1.8px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

#slide-35-layer-9 {
    z-index: 5;
    white-space: nowrap;
    font-size: 140px;
    line-height: 130px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: 0;
}

#slide-35-layer-15 {
    z-index: 6;
    white-space: nowrap;
    font-size: 60px;
    line-height: 60px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: 0;
}

#rev_slider_13_1_wrapper .rev-slidebg {
    background: #5f362e;
}

#rev_slider_14_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_14_1 {
    display: none;
}

#slide-36-layer-9 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 130px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: 0;
}

#slide-36-layer-17 {
    z-index: 7;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 10px;
}

#slide-37-layer-9 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 80px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-37-layer-17 {
    z-index: 6;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 10px;
}

#slide-37-layer-19 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-38-layer-9 {
    z-index: 5;
    white-space: nowrap;
    font-size: 100px;
    line-height: 90px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: -2px;
}

#slide-38-layer-17 {
    z-index: 6;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 10px;
}

#slide-38-layer-19 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_15_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_15_1 {
    display: none;
}

#slide-39-layer-15 {
    z-index: 5;
}

#slide-39-layer-7 {
    z-index: 6;
    min-width: 947px;
    max-width: 947px;
    white-space: normal;
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -3px;
}

#slide-39-layer-11 {
    background-color: transparent;
    color: #FFFFFF;
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: white;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

#slide-39-layer-13 {
    z-index: 8;
    min-width: 495px;
    max-width: 24px;
    white-space: normal;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 6px;
    text-transform: uppercase;
}

#slide-40-layer-7 {
    z-index: 5;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 100px;
    line-height: 100px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-40-layer-9 {
    z-index: 6;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-40-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

#slide-41-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 80px;
    line-height: 100px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -3px;
}

#slide-41-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 5px;
}

#slide-41-layer-3 {
    z-index: 7;
    white-space: nowrap;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_16_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_16_1 {
    display: none;
}

#slide-42-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 150px;
    line-height: 130px;
    font-weight: 900;
    color: #ffffff;
    letter-spacing: 0;
    font-family: "Playfair Display", serif;
}

#slide-42-layer-3 {
    z-index: 6;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 6px;
}

#slide-42-layer-5 {
    z-index: 7;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 1.8px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

#slide-43-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 90px;
    line-height: 90px;
    font-weight: 900;
    color: #f16521;
    letter-spacing: 0;
    font-family: "Playfair Display", serif;
}

#slide-43-layer-5 {
    color: #FFFFFF;
    z-index: 6;
    white-space: nowrap;
    letter-spacing: 1.8px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
    -webkit-transition: 0.4;
    -o-transition: 0.4;
    transition: 0.4;
}

#slide-44-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 100px;
    line-height: 100px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-44-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 1px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_17_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_17_1 {
    display: none;
}

#slide-45-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 140px;
    line-height: 185px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-45-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 90px;
    line-height: 90px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-46-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 140px;
    line-height: 185px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-46-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 90px;
    line-height: 90px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-47-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 120px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: -1px;
}

#slide-47-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 22px;
    line-height: 22px;
    font-weight: 900;
    color: #f16521;
    letter-spacing: 10px;
    font-family: "Playfair Display", serif;
}

#slide-48-layer-1>span {
    color: #f16521;
}

#slide-50-layer-1>span {
    color: #f16521;
}

#rev_slider_18_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_18_1 {
    display: none;
}

#slide-48-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 70px;
    line-height: 70px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: 0;
}

#slide-48-layer-2 {
    z-index: 6;
    min-width: 550px;
    max-width: 550px;
    white-space: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #5f362e;
    letter-spacing: 0;
}

#slide-48-layer-4 {
    z-index: 7;
}

#slide-48-layer-3 {
    z-index: 8;
}

#slide-48-layer-5 {
    z-index: 9;
    white-space: nowrap;
    letter-spacing: 1px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-49-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 90px;
    line-height: 100px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-49-layer-2 {
    z-index: 6;
    min-width: 550px;
    max-width: 550px;
    white-space: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-49-layer-5 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 1px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-50-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 90px;
    line-height: 100px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-50-layer-2 {
    z-index: 6;
    min-width: 550px;
    max-width: 550px;
    white-space: normal;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-50-layer-5 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 1px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    cursor: pointer;
}

#rev_slider_19_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_19_1 {
    display: none;
}

#slide-51-layer-7 {
    z-index: 5;
    min-width: 1022px;
    max-width: 161px;
    white-space: normal;
    font-size: 60px;
    line-height: 80px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -2.25px;
}

#slide-51-layer-9 {
    z-index: 6;
    min-width: 358px;
    max-width: 358px;
    white-space: normal;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 6px;
}

#slide-51-layer-11 {
    z-index: 7;
    min-width: 168px;
    max-width: 168px;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: white;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

#rev_slider_20_1_wrapper {
    background: transparent;
    padding: 0;
}

#rev_slider_20_1 {
    display: none;
}

#slide-52-layer-9 {
    z-index: 5;
}

#slide-52-layer-1 {
    z-index: 6;
    white-space: nowrap;
    font-size: 150px;
    line-height: 130px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -2px;
}

#slide-52-layer-3 {
    z-index: 7;
    min-width: 184px;
    max-width: 57px;
    white-space: nowrap;
    font-size: 13px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-52-layer-7 {
    z-index: 8;
    min-width: 501px;
    max-width: 501px;
    white-space: normal;
    font-size: 44px;
    line-height: 44px;
    font-weight: 400;
    color: #f16521;
    letter-spacing: 0;
}

#slide-53-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 150px;
    line-height: 130px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -2px;
}

#slide-53-layer-3 {
    z-index: 6;
    min-width: 184px;
    max-width: 57px;
    white-space: nowrap;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    border-color: white;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_20_1 {
    background: -webkit-gradient(linear, left top, right top, from(#030570), to(#500096));
    background: -webkit-linear-gradient(left, #030570 0%, #500096 100%);
    background: -o-linear-gradient(left, #030570 0%, #500096 100%);
    background: linear-gradient(90deg, #030570 0%, #500096 100%);
}

#rev_slider_20_1 .uranus.tparrows {
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0);
}

#rev_slider_20_1 .uranus.tparrows:before {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 40px;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;
}

#rev_slider_20_1 .uranus.tparrows:hover:before {
    opacity: 0.75;
}

.ares.tp-bullets:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    padding: 10px;
    margin-left: -10px;
    margin-top: -10px;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.ares .tp-bullet {
    width: 13px;
    height: 13px;
    position: absolute;
    background: #e5e5e5;
    border-radius: 50%;
    cursor: pointer;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
}

.ares .tp-bullet:hover,
.ares .tp-bullet.selected {
    background: white;
}

.ares .tp-bullet-title {
    position: absolute;
    color: #888888;
    font-size: 12px;
    padding: 0 10px;
    font-weight: 600;
    right: 27px;
    top: -4px;
    background: rgba(255, 255, 255, 0.75);
    visibility: hidden;
    -ms-transform: translatex(-20px);
    transform: translatex(-20px);
    -webkit-transform: translatex(-20px);
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
    -webkit-transition: transform 0.3s;
    line-height: 20px;
    white-space: nowrap;
}

.ares .tp-bullet-title:after {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
    content: " ";
    position: absolute;
    right: -10px;
    top: 0;
}

.ares .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
    -ms-transform: translatex(0px);
    transform: translatex(0px);
    -webkit-transform: translatex(0px);
}

.ares .tp-bullet.selected:hover .tp-bullet-title {
    background: white;
}

.ares .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent transparent transparent white;
}

.ares.tp-bullets:hover .tp-bullet-title {
    visibility: hidden;
}

.ares.tp-bullets:hover .tp-bullet:hover .tp-bullet-title {
    visibility: visible;
    -ms-transform: translateX(0px) translatey(0px);
    transform: translateX(0px) translatey(0px);
    -webkit-transform: translateX(0px) translatey(0px);
}

.ares.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
    right: auto;
    left: 27px;
    -ms-transform: translatex(20px);
    transform: translatex(20px);
    -webkit-transform: translatex(20px);
}

.ares.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title:after {
    border-width: 10px 10px 10px 0 !important;
    border-color: transparent rgba(255, 255, 255, 0.75) transparent transparent;
    right: auto !important;
    left: -10px !important;
}

.ares.nav-dir-vertical.nav-pos-hor-left .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent white transparent transparent !important;
}

.ares.nav-dir-horizontal.nav-pos-ver-center .tp-bullet-title,
.ares.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet-title {
    top: -35px;
    left: 50%;
    right: auto;
    -ms-transform: translateX(-50%) translateY(-10px);
    transform: translateX(-50%) translateY(-10px);
    -webkit-transform: translateX(-50%) translateY(-10px);
}

.ares.nav-dir-horizontal.nav-pos-ver-center .tp-bullet-title:after,
.ares.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet-title:after {
    border-width: 10px 10px 0 10px;
    border-color: rgba(255, 255, 255, 0.75) transparent transparent transparent;
    right: auto;
    left: 50%;
    margin-left: -10px;
    top: auto;
    bottom: -10px;
}

.ares.nav-dir-horizontal.nav-pos-ver-center .tp-bullet.selected:hover .tp-bullet-title:after,
.ares.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: white transparent transparent transparent;
}

.ares.nav-dir-horizontal.nav-pos-ver-center .tp-bullet:hover .tp-bullet-title,
.ares.nav-dir-horizontal.nav-pos-ver-bottom .tp-bullet:hover .tp-bullet-title {
    -ms-transform: translateX(-50%) translatey(0px);
    transform: translateX(-50%) translatey(0px);
    -webkit-transform: translateX(-50%) translatey(0px);
}

.ares.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
    top: 25px;
    left: 50%;
    right: auto;
    -ms-transform: translateX(-50%) translateY(10px);
    transform: translateX(-50%) translateY(10px);
    -webkit-transform: translateX(-50%) translateY(10px);
}

.ares.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title:after {
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent rgba(255, 255, 255, 0.75) transparent;
    right: auto;
    left: 50%;
    margin-left: -10px;
    bottom: auto;
    top: -10px;
}

.ares.nav-dir-horizontal.nav-pos-ver-top .tp-bullet.selected:hover .tp-bullet-title:after {
    border-color: transparent transparent white transparent;
}

.ares.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-title {
    -ms-transform: translateX(-50%) translatey(0px);
    transform: translateX(-50%) translatey(0px);
    -webkit-transform: translateX(-50%) translatey(0px);
}

.tp-caption.Button-Outline-Secondary,
.Button-Outline-Secondary {
    color: white;
    font-size: 14px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

.tp-caption.Button-Outline-Secondary:hover,
.Button-Outline-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

.tp-caption.Button-Outline-White,
.Button-Outline-White {
    color: white;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #ffffff;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

.tp-caption.Button-Outline-White:hover,
.Button-Outline-White:hover {
    color: #5f362e;
    text-decoration: none;
    background-color: #ffffff;
    border-color: #ffffff;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_21_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_21_1 {
    display: none;
}

#slide-55-layer-1 {
    z-index: 5;
    min-width: 616px;
    max-width: 142px;
    white-space: normal;
    font-size: 60px;
    line-height: 70px;
    font-weight: 700;
    color: #5f362e;
    letter-spacing: -0.86px;
}

#slide-55-layer-5 {
    z-index: 6;
    white-space: nowrap;
    letter-spacing: 1.8px;
    border-color: #5f362e;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    cursor: pointer;
}

#slide-55-layer-7 {
    z-index: 7;
    white-space: nowrap;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    color: #5f362e;
    letter-spacing: -0.86px;
    font-family: "Playfair Display", serif;
    font-style: italic;
}

#slide-55-layer-8 {
    z-index: 8;
}

#slide-56-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 100px;
    line-height: 140px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: -3px;
}

#slide-56-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 100px;
    line-height: 140px;
    font-weight: 400;
    color: #f16521;
    letter-spacing: -3px;
}

#slide-56-layer-3 {
    z-index: 7;
    white-space: nowrap;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 15px;
}

#rev_slider_21_1_wrapper .tp-caption.Button-Outline-Primary,
#rev_slider_21_1_wrapper .Button-Outline-Primary {
    color: #5f362e;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #5f362e;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_21_1_wrapper .tp-caption.Button-Outline-Primary:hover,
#rev_slider_21_1_wrapper .Button-Outline-Primary:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #5f362e;
    border-color: #5f362e;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_22_1_wrapper {
    background: transparent;
    padding: 0;
}

#rev_slider_22_1 {
    display: none;
}

#slide-57-layer-7 {
    z-index: 5;
    min-width: 752px;
    max-width: 752px;
    white-space: normal;
    font-size: 140px;
    line-height: 125px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -1px;
}

#slide-57-layer-9 {
    z-index: 6;
    min-width: 358px;
    max-width: 358px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-57-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

#slide-58-layer-7 {
    z-index: 5;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-58-layer-9 {
    z-index: 6;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 10px;
}

#slide-58-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-59-layer-7 {
    z-index: 5;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 120px;
    line-height: 110px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-59-layer-9 {
    z-index: 6;
    min-width: 780px;
    max-width: 780px;
    white-space: normal;
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-59-layer-11 {
    z-index: 7;
    white-space: nowrap;
    font-size: 14px;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_22_1_wrapper .tp-caption.Button-Outline-Secondary,
#rev_slider_22_1_wrapper .Button-Outline-Secondary {
    color: white;
    font-size: 14px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_22_1_wrapper .tp-caption.Button-Outline-Secondary:hover,
#rev_slider_22_1_wrapper .Button-Outline-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_22_1_wrapper .tp-caption.Button-Solid-Secondary,
#rev_slider_22_1_wrapper .Button-Solid-Secondary {
    color: white;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_22_1_wrapper .tp-caption.Button-Solid-Secondary:hover,
#rev_slider_22_1_wrapper .Button-Solid-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #5f362e;
    border-color: #5f362e;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_24_1 .zeus .tp-bullet {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    width: 13px;
    height: 13px;
    border: 2px solid white;
}

#rev_slider_24_1 .zeus .tp-bullet:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    border-radius: 50%;
    background-color: white;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -o-transition: transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

#rev_slider_24_1 .zeus .tp-bullet:hover:after,
#rev_slider_24_1 .zeus .tp-bullet.selected:after {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

#rev_slider_24_1 .zeus .tp-bullet-image,
#rev_slider_24_1 .zeus .tp-bullet-imageoverlay {
    width: 135px;
    height: 60px;
    position: absolute;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    bottom: 13px;
    margin-bottom: 10px;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-size: cover;
    background-position: center center;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    border-radius: 4px;
}

#rev_slider_24_1 .zeus .tp-bullet-title,
#rev_slider_24_1 .zeus .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}

#rev_slider_24_1 .zeus .tp-bullet-title {
    color: white;
    text-align: center;
    line-height: 15px;
    font-size: 13px;
    font-weight: 600;
    z-index: 3;
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    position: absolute;
    bottom: 45px;
    width: 135px;
    vertical-align: middle;
}

#rev_slider_24_1 .zeus .tp-bullet:hover .tp-bullet-title,
#rev_slider_24_1 .zeus .tp-bullet:hover .tp-bullet-image,
#rev_slider_24_1 .zeus .tp-bullet:hover .tp-bullet-imageoverlay {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px) translateX(-50%);
    -ms-transform: translateY(0px) translateX(-50%);
    transform: translateY(0px) translateX(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet-image,
#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
    bottom: auto;
    margin-right: 10px;
    margin-bottom: 0;
    right: 13px;
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image {
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet-title,
#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet-title {
    bottom: auto;
    right: 100%;
    margin-right: 10px;
}

#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-title,
#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-image,
#rev_slider_24_1 .zeus.nav-dir-vertical .tp-bullet:hover .tp-bullet-imageoverlay {
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-image,
#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
    bottom: auto;
    margin-left: 10px;
    margin-bottom: 0;
    left: 13px;
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet:hover .tp-bullet-image {
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title,
#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -ms-transform: translateX(0px) translateY(-50%);
    transform: translateX(0px) translateY(-50%);
    -webkit-transform: translateX(0px) translateY(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-vertical.nav-pos-hor-left .tp-bullet-title {
    bottom: auto;
    left: 100%;
    margin-left: 10px;
}

#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-image,
#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
    bottom: auto;
    top: 13px;
    margin-top: 10px;
    margin-bottom: 0;
    left: 0;
    -ms-transform: translateY(0px) translateX(-50%);
    transform: translateY(0px) translateX(-50%);
    -webkit-transform: translateX(0px) translateX(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet:hover .tp-bullet-image {
    -ms-transform: scale(1) translateY(0px) translateX(-50%);
    transform: scale(1) translateY(0px) translateX(-50%);
    -webkit-transform: scale(1) translateY(0px) translateX(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title,
#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-imageoverlay {
    z-index: 2;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -ms-transform: translateY(0px) translateX(-50%);
    transform: translateY(0px) translateX(-50%);
    -webkit-transform: translateY(0px) translateX(-50%);
}

#rev_slider_24_1 .zeus.nav-dir-horizontal.nav-pos-ver-top .tp-bullet-title {
    bottom: auto;
    top: 13px;
    margin-top: 20px;
}

#rev_slider_24_1 .tp-caption.Button-Solid-Secondary,
#rev_slider_24_1 .Button-Solid-Secondary {
    color: white;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_24_1 .tp-caption.Button-Solid-Secondary:hover,
#rev_slider_24_1 .Button-Solid-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #5f362e;
    border-color: #5f362e;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#slide-63-layer-1,
#slide-64-layer-1,
#slide-65-layer-1 {
    color: #5f362e;
}

#rev_slider_25_1 .tp-caption.Button-Outline-Secondary,
#rev_slider_25_1 .Button-Outline-Secondary {
    color: white;
    font-size: 14px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_25_1 .tp-caption.Button-Outline-Secondary:hover,
#rev_slider_25_1 .Button-Outline-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_25_1 .tp-caption.Button-Solid-Secondary,
#rev_slider_25_1 .Button-Solid-Secondary {
    color: white;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_25_1 .tp-caption.Button-Solid-Secondary:hover,
#rev_slider_25_1 .Button-Solid-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #5f362e;
    border-color: #5f362e;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_26_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_26_1 {
    display: none;
}

#slide-69-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 150px;
    line-height: 150px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-69-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 56px;
    line-height: 75px;
    font-weight: 400;
    color: #f16521;
    letter-spacing: 0;
    font-family: "Playfair Display", serif;
    font-style: italic;
}

#slide-69-layer-3 {
    z-index: 7;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 1px 0;
    text-decoration: none;
}

#slide-69-layer-6 {
    z-index: 8;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
    border-color: rgba(255, 204, 0, 0);
    border-style: solid;
    border-width: 0 0 1px 0;
    text-decoration: none;
}

#slide-69-layer-7 {
    z-index: 9;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 0 0 1px 0;
    text-decoration: none;
}

#slide-69-layer-8 {
    z-index: 10;
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 3px;
    border-color: transparent;
    border-width: 0 0 1px 0;
    text-decoration: none;
}

#rev_slider_27_1_wrapper {
    height: 100vh !important;
}

#rev_slider_28_1_wrapper {
    background: transparent;
    padding: 0;
}

#rev_slider_28_1 {
    display: none;
}

#slide-75-layer-7 {
    z-index: 5;
}

#slide-75-layer-1 {
    z-index: 6;
    white-space: nowrap;
    font-size: 70px;
    line-height: 82px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-75-layer-3 {
    z-index: 7;
    max-width: 57px;
    white-space: nowrap;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-75-layer-5 {
    z-index: 8;
    min-width: 117px;
    max-width: 117px;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#rev_slider_28_1_wrapper .tp-caption.Button-Solid-Secondary,
#rev_slider_28_1_wrapper .Button-Solid-Secondary {
    color: white;
    font-size: 13px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_28_1_wrapper .tp-caption.Button-Solid-Secondary:hover,
#rev_slider_28_1_wrapper .Button-Solid-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_29_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_29_1 {
    display: none;
}

#slide-76-layer-1 {
    z-index: 5;
    white-space: nowrap;
    font-size: 120px;
    line-height: 96px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.86px;
    font-family: "Playfair Display", serif;
}

#slide-76-layer-2 {
    z-index: 6;
    white-space: nowrap;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 2px;
}

#slide-76-layer-6 {
    z-index: 7;
    white-space: nowrap;
    font-size: 60px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
    text-decoration: none;
}

.hesperiden.tparrows {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.5);
    width: 40px;
    height: 40px;
    position: absolute;
    display: block;
    z-index: 100;
    border-radius: 50%;
}

.hesperiden.tparrows:hover {
    background: black;
}

.hesperiden.tparrows:before {
    font-family: "revicons";
    font-size: 20px;
    color: white;
    display: block;
    line-height: 40px;
    text-align: center;
}

.hesperiden.tparrows.tp-leftarrow:before {
    content: "\e82c";
    margin-left: -3px;
}

.hesperiden.tparrows.tp-rightarrow:before {
    content: "\e82d";
    margin-right: -3px;
}

#rev_slider_2_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_2_1 {
    display: none;
}

#slide-2-layer-1 {
    z-index: 5;
}

#slide-2-layer-2 {
    z-index: 6;
}

#slide-2-layer-3 {
    z-index: 7;
    white-space: nowrap;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 6px;
}

#slide-2-layer-4 {
    z-index: 8;
    white-space: nowrap;
    font-size: 110px;
    line-height: 125px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: -0.79px;
}

#slide-2-layer-5 {
    z-index: 9;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;
}

#rev_slider_2_1 .tp-caption.Button-Outline-Secondary,
#rev_slider_2_1 .Button-Outline-Secondary {
    color: white;
    font-size: 14px;
    line-height: 51px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    background-color: transparent;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
}

#rev_slider_2_1 .tp-caption.Button-Outline-Secondary:hover,
#rev_slider_2_1 .Button-Outline-Secondary:hover {
    color: white;
    text-decoration: none;
    background-color: #f16521;
    border-color: #f16521;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-radius: 30px 30px 30px 30px;
    cursor: pointer;
}

#rev_slider_3_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_3_1 {
    display: none;
}

#slide-3-layer-5 {
    z-index: 5;
    min-width: 117px;
    max-width: 117px;
    white-space: nowrap;
    font-size: 20px;
    line-height: 22px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-3-layer-7 {
    z-index: 6;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 90px;
    line-height: 98px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-4-layer-7 {
    z-index: 5;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 120px;
    line-height: 110px;
    font-weight: 700;
    color: #f16521;
    letter-spacing: 0;
}

#slide-4-layer-9 {
    z-index: 6;
    min-width: 600px;
    max-width: 600px;
    white-space: normal;
    font-size: 22px;
    line-height: 36px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-4-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#slide-5-layer-7 {
    z-index: 5;
    min-width: 1251px;
    max-width: 1251px;
    white-space: normal;
    font-size: 120px;
    line-height: 110px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-5-layer-11 {
    z-index: 7;
    white-space: nowrap;
    letter-spacing: 2px;
    border-color: #f16521;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
}

#rev_slider_4_1_wrapper {
    margin: 0 auto;
    background: transparent;
    padding: 0;
}

#rev_slider_4_1 {
    display: none;
}

#slide-6-layer-5 {
    z-index: 5;
    min-width: 709px;
    max-width: 709px;
    white-space: normal;
    font-size: 56px;
    line-height: 70px;
    font-weight: 700;
    color: #ffffff;
    letter-spacing: 0;
}

#slide-6-layer-6 {
    z-index: 6;
    min-width: 580px;
    max-width: 580px;
    white-space: normal;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #ffffff;
    letter-spacing: 0;
}

@media only screen and (max-width: 767.98px) {
    #rev_slider_25_1_wrapper .rev-btn {
        padding: 10px 20px;
    }
}

#rev_slider_christmas {
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    #rev_slider_christmas .tparrows {
        display: none;
    }
}

#rev_slider_christmas #slide-36-layer-17,
#rev_slider_christmas #slide-38-layer-17 {
    color: #ff0000;
}

#rev_slider_christmas #slide-38-layer-19 {
    background-color: #ff0000;
    border-color: #ff0000;
    color: #FFFFFF;
}

#rev_slider_christmas .tp-bullet {
    -webkit-box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
    box-shadow: 0 0 0 2px rgba(255, 0, 0, 0);
}

#rev_slider_christmas .tp-bullet-inner {
    background-color: #fefefe;
}

#rev_slider_christmas .tp-bullet.selected,
#rev_slider_christmas .tp-bullet:hover {
    -webkit-box-shadow: 0 0 0 2px red;
    box-shadow: 0 0 0 2px red;
}

#rev_slider_christmas .tp-bullet.selected .tp-bullet-inner,
#rev_slider_christmas .tp-bullet:hover .tp-bullet-inner {
    background-color: red;
}

/*=====================
07. About Style CSS
======================*/
.about-content h3 {
    font-size: 2.25rem;
    line-height: 1.5;
}

@media (max-width: 1200px) {
    .about-content h3 {
        font-size: calc(1.35rem + 1.2vw);
    }
}

.about-content>.btn {
    margin-top: 10px;
}

.about-content-icon {
    background: url("../img/icons/ruller.png") no-repeat top right;
    padding: 50px 0;
    margin-left: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-content-icon {
        margin-left: 0;
        padding: 0;
    }
}

@media only screen and (min-width: 1200px) {
    .about-content-icon .about-txt {
        max-width: 400px;
    }
}

.about-content .about-txt span {
    color: #f16521;
}

.about-content .ht-list li:before {
    content: '\e006';
    font-family: "linea-arrows-10";
}

.about-content .section-title .about-txt .btn-bordered {
    margin-top: 50px;
}

@media only screen and (max-width: 767.98px) {
    .about-content .section-title .about-txt .btn-bordered {
        margin-top: 30px;
    }
}

.about-content.onepage {
    padding: 50px 0  200px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-content.onepage {
        padding: 30px 0 0;
    }
}

.about-content.onepage h4 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .about-content.onepage h4 {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.about-content.construction h4 {
    font-size: 2.75rem;
    font-weight: 700;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .about-content.construction h4 {
        font-size: calc(1.4rem + 1.8vw);
    }
}

.about-thumb {
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-thumb {
        margin-bottom: 40px;
    }
}

.about-thumb img {
    width: 100%;
}

.about-thumb-ml {
    margin-left: -100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-thumb-ml {
        margin-left: 0;
    }
}

.about-thumb-left-align {
    margin-left: -550px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-thumb-left-align {
        margin-left: auto;
    }
}

.about-thumb-right-align {
    margin-right: -550px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-thumb-right-align {
        margin-right: auto;
    }
}

.about-creative {
    background-color: #5f362e;
    color: #FFFFFF;
    padding: 160px 0 135px;
    position: relative;
    margin-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-creative {
        padding: 80px 0 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-creative {
        padding: 60px 0 40px;
    }
}

.about-creative:before {
    background: url("../img/icons/letter_a.png") no-repeat center center;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.02;
    height: 100%;
    width: 100%;
}

.about-modern .about-content {
    z-index: 2;
    position: relative;
}

.about-modern .about-txt {
    max-width: 370px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-modern .about-txt {
        max-width: 100%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .about-business-bottom .about-content {
        margin-top: 60px;
    }
}

.about-business-item {
    height: 650px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-business-item {
        height: 500px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-business-item {
        height: 400px;
    }
}

.about-business-item .about-content {
    max-width: 500px;
    margin: auto;
    padding: 0 15px;
}

.about-business-item.bg-img .section-title h2 {
    color: #f16521;
}

.about-business-item.bg-brand .section-title {
    color: #5f362e;
}

.about-business-item.bg-brand .section-title h5 {
    color: #5f362e;
}

.about-personal-item {
    margin-bottom: 40px;
}

@media only screen and (max-width: 767.98px) {
    .about-personal-item {
        margin-bottom: 25px;
    }
}

.about-personal-item:last-child {
    margin-bottom: 0;
}

.about-personal-item h5 {
    font-size: 1rem;
    font-weight: 700;
    color: #f16521;
    margin-bottom: 10px;
}

.about-graphic .about-content {
    font-size: 1.5rem;
}

@media (max-width: 1200px) {
    .about-graphic .about-content {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.about-graphic .about-content h2 {
    margin-bottom: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-graphic .about-content h2 {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-graphic .about-content h2 {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .about-graphic .about-thumb-right-align {
        margin-right: -400px;
    }
}

.about-content-minimal {
    font-size: 1.5rem;
}

@media (max-width: 1200px) {
    .about-content-minimal {
        font-size: calc(1.275rem + 0.3vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .about-content-minimal {
        font-size: 1.125rem;
    }
}

.about-content-minimal h6 {
    color: #f16521;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 1.8px;
    text-transform: uppercase;
}

.about-content-minimal h2 {
    margin-bottom: 20px;
}

@media only screen and (min-width: 1200px) {
    .about-content-minimal h2 {
        max-width: 800px;
    }
}

.about-content-minimal h2 #typed {
    color: #f16521;
    font-style: italic;
    font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 575.98px) {
    .about-onepage .counter-item .counter-number {
        font-size: 1.5rem;
    }
}

@media only screen and (max-width: 575.98px) and (max-width: 1200px) {
    .about-onepage .counter-item .counter-number {
        font-size: calc(1.275rem + 0.3vw);
    }
}

@media only screen and (max-width: 575.98px) {
    .about-onepage .counter-item .counter-txt {
        font-size: 0.875rem;
    }
}

.img-h-w-wrap {
    position: relative;
}

.img-h-w-wrap .img-half-width {
    position: absolute;
    height: 100%;
    top: 0;
    width: 50%;
}

.img-h-w-wrap .img-half-width.right {
    right: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .img-h-w-wrap .img-half-width {
        position: relative;
        width: 100%;
        height: 300px;
        margin: auto;
    }
}

@media only screen and (max-width: 767.98px) {
    .fresh-ideas-area {
        padding-bottom: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .fresh-ideas-area {
        padding-bottom: 90px;
    }
}

@media only screen and (min-width: 1200px) {
    .startup-mission-area {
        padding: 80px 0;
    }
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .startup-mission-area .service-area-gd {
        background-image: none !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .startup-mission-area .service-area-gd {
        background-size: 25%;
    }
}

/*========================
08. Portfolio Style CSS
=========================*/
.portfolio-wrapper .container-fluid {
    padding: 0 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-wrapper .container-fluid {
        padding: 0 15px;
    }
}

.portfolio-carousel-wrap .lSPager.lSpg li a {
    background-color: transparent;
    border: 3px solid #e6e8ee;
    height: 15px;
    width: 15px;
}

.portfolio-carousel-wrap .lSPager.lSpg li.active a,
.portfolio-carousel-wrap .lSPager.lSpg li:hover a {
    background-color: transparent;
    border-color: #f16521;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-home-wrapper .portfolio-home-title {
        margin-bottom: 30px;
    }
}

.portfolio-home-wrapper .portfolio-home-title h1 {
    font-family: "Playfair Display", serif;
    font-size: 5.625rem;
    line-height: 1;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .portfolio-home-wrapper .portfolio-home-title h1 {
        font-size: calc(1.6875rem + 5.25vw);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .portfolio-home-wrapper .portfolio-home-title h1 {
        font-size: 3.75rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) and (max-width: 1200px) {
    .portfolio-home-wrapper .portfolio-home-title h1 {
        font-size: calc(1.5rem + 3vw);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .portfolio-home-wrapper .portfolio-home-title h1 {
        font-size: 55px;
    }
}

.filter-menu li {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #5f362e;
    cursor: pointer;
    margin-left: 40px;
    position: relative;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (max-width: 767.98px) {
    .filter-menu li {
        margin-left: 20px;
    }
}

.filter-menu li:first-child {
    margin-left: 0;
}

.filter-menu li:before {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 1px;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.filter-menu li:hover:before,
.filter-menu li.active:before {
    opacity: 1;
    visibility: visible;
    bottom: 0;
}

.portfolio-item {
    position: relative;
    margin-top: 30px;
}

.portfolio-item:before {
    background-color: rgba(251, 239, 225, 0.5);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.portfolio-item__info {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.portfolio-item__info .title {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 5px;
    text-transform: uppercase;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
}

.portfolio-item__info .title a {
    color: #f16521;
}

.portfolio-item__info .category {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 1;
    text-transform: capitalize;
    display: inline-block;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
}

.portfolio-item:hover:before {
    opacity: 1;
    visibility: visible;
}

.portfolio-item:hover .portfolio-item__info {
    opacity: 1;
    visibility: visible;
}

.portfolio-item:hover .portfolio-item__info .title,
.portfolio-item:hover .portfolio-item__info .category {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.portfolio-item--2:before {
    display: none;
}

.portfolio-item--2 .portfolio-item__info {
    position: static;
    opacity: 1;
    visibility: visible;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-top: 25px;
}

.portfolio-item--2 .portfolio-item__info * {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.portfolio-item--2 .portfolio-item__info .title {
    margin-top: 0;
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 400;
}

@media (max-width: 1200px) {
    .portfolio-item--2 .portfolio-item__info .title {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.portfolio-item--2 .portfolio-item__info .title a {
    color: #5f362e;
}

.portfolio-item--2 .portfolio-item__info .title a:hover {
    color: #f16521;
}

.portfolio-item--2 .portfolio-item__info .category {
    color: #878c9b;
}

.no-gutters .portfolio-item,
.portfolio-carousel-content .portfolio-item {
    margin-top: 0;
}

@media only screen and (min-width: 1200px) {
    .columns-5 .col {
        -webkit-flex-basis: 20%;
        -ms-flex-preferred-size: 20%;
        flex-basis: 20%;
        max-width: 20%;
    }
}

/*======================
09. Skills Style CSS
========================*/
.ht-pie-chart {
    text-align: center;
    position: relative;
}

.ht-pie-chart .skill-percent {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.skill-item {
    margin-top: 30px;
}

.skill-item__txt {
    margin-top: 32px;
}

@media only screen and (max-width: 767.98px) {
    .skill-item__txt {
        margin-top: 25px;
    }
}

/*======================
10. Service Style CSS
========================*/
.service-business-classic {
    margin-top: -80px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .service-business-classic {
        margin-top: -30px;
    }
}

@media only screen and (min-width: 1200px),
only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .service-business-classic .icon-box-item {
        margin-top: 80px;
    }
}

.service-area-gd {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 7% 50%;
}

.service-content-gd .list-box__info h6 {
    font-size: 1rem;
    font-weight: 700;
}

.service-item__txt {
    margin-top: 35px;
}

.service-item__txt h2 {
    margin-top: 0;
}

.service-2-content-wrap {
    margin-top: -75px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .service-2-content-wrap {
        margin-top: -55px;
    }
}

@media only screen and (max-width: 767.98px) {
    .service-2-content-wrap {
        margin-top: -35px;
    }
}

.service-2-content-wrap .icon-box-item {
    margin-top: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .service-2-content-wrap .icon-box-item {
        margin-top: 55px;
    }
}

@media only screen and (max-width: 767.98px) {
    .service-2-content-wrap .icon-box-item {
        margin-top: 35px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .architecture-service-wrap .list-box-item {
        margin-top: 45px;
    }
}

/*================================
Start Baber Service Area Wrapper
==================================*/
.baber-service-title-area {
    padding: 120px 0;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .baber-service-title-area {
        padding: 80px 0 175px;
    }
}

@media only screen and (max-width: 767.98px) {
    .baber-service-title-area {
        padding: 60px 0 166px;
    }
}

.baber-service-title-area:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.baber-service-content-inner {
    background-color: #FFFFFF;
    border: 15px solid #f16521;
    padding: 56px 70px 85px;
    margin-top: -120px;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .baber-service-content-inner {
        padding: 31px 30px 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .baber-service-content-inner {
        padding: 16px 15px 40px;
        border-width: 5px;
    }
}

.service-list-wrap {
    text-align: center;
}

.service-list-wrap .btn {
    padding: 15px 30px;
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .service-list-wrap .btn {
        margin-top: 30px;
    }
}

.service-list-wrap .btn-bordered:hover {
    background-color: #f16521;
    color: #222222;
}

.service-list {
    overflow: hidden;
    margin: 0 -15px;
    text-align: left;
}

.service-list-item {
    border-bottom: 1px solid #f16521;
    float: left;
    width: calc(50% - 30px);
    padding: 25px 0;
    margin: 0 15px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 767.98px) {
    .service-list-item {
        width: calc(100% - 30px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .service-list-item {
        font-size: 14px;
    }
}

.service-list-item-title {
    color: #222222;
}

.service-list-item-price {
    color: #f16521;
    margin-left: 10px;
}

/*===========================
11. Testimonial Style CSS
============================*/
.testimonial-item {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 50px 30px;
    text-align: center;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    margin-top: 30px;
}

.testimonial-item .client-title {
    margin-bottom: 0;
}

.testimonial-item .client-designation {
    font-size: 1rem;
}

.testimonial-item .client-thumb {
    margin: 15px 0 25px;
}

.testimonial-item .client-thumb img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .testimonial-item:hover {
        -webkit-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

.testimonial-item--2 {
    border: none;
    margin-top: 0;
    padding: 0;
}

.testimonial-item--2:hover {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.testimonial-item--2 .client-thumb {
    margin: 0 0 40px;
}

@media only screen and (max-width: 767.98px) {
    .testimonial-item--2 .client-thumb {
        margin: 0 0 20px;
    }
}

.testimonial-item--2 .client-quote {
    color: #5f362e;
    font-size: 2rem;
    line-height: 1.2;
}

@media (max-width: 1200px) {
    .testimonial-item--2 .client-quote {
        font-size: calc(1.325rem + 0.9vw);
    }
}

.testimonial-item--2 .client-info {
    margin-top: 40px;
}

@media only screen and (max-width: 767.98px) {
    .testimonial-item--2 .client-info {
        margin-top: 30px;
    }
}

.testimonial-item--2 .client-info .client-designation {
    color: #878c9b;
}

.testimonial-header-area {
    padding: 120px 0 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .testimonial-header-area {
        padding: 90px 0 200px;
    }
}

@media only screen and (max-width: 767.98px) {
    .testimonial-header-area {
        padding: 60px 0 200px;
    }
}

.testimonial-content-area {
    margin-top: -230px;
}

.business-testimonial {
    position: relative;
}

.business-testimonial:before {
    background-color: rgba(24, 33, 65, 0.5);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.bg-softWhite .testimonial-item--2 {
    background-color: transparent;
}

/*===========================
12. Brand Logo Style CSS
=============================*/
.brand-logo-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100px;
}

.brand-logo-area {
    padding: 80px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .brand-logo-area {
        padding: 60px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .brand-logo-area {
        padding: 50px 0;
    }
}

/*===============================
13. Call to Action Style CSS
=================================*/
.call-to-action-wrapper {
    position: relative;
}

.call-to-action-wrapper:before {
    background-color: rgba(24, 33, 65, 0.8);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.call-to-action-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 550px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .call-to-action-content {
        height: 400px;
    }
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content {
        height: 300px;
    }
}

.call-to-action-content h2 {
    color: #FFFFFF;
    font-size: 4.6875rem;
    margin-bottom: 40px;
}

@media (max-width: 1200px) {
    .call-to-action-content h2 {
        font-size: calc(1.59375rem + 4.125vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content h2 {
        margin-bottom: 20px;
    }
}

.call-to-action-content .btn-bordered {
    color: #FFFFFF;
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content--modern {
        height: 400px;
    }
}

.call-to-action-content--modern .contact-content-wrap h2 {
    color: #5f362e;
}

.call-to-action-content--modern .contact-content-wrap .call-to-contact {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .call-to-action-content--modern .contact-content-wrap .call-to-contact {
        margin-top: 45px;
    }
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content--modern .contact-content-wrap .call-to-contact {
        margin-top: 30px;
    }
}

.call-to-action-content--business {
    height: 200px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-content--business {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.call-to-action-content--business h4 {
    /* color: #FFFFFF; */
    margin-bottom: 0;
}

.call-to-action-content--business .btn-bordered {
    border-color: #FFFFFF;
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-content--business .btn-bordered {
        margin-top: 25px;
    }
}

.call-to-action-content--business .btn-bordered:hover {
    background-color: #FFFFFF;
    color: #f16521;
}

.call-to-action-content.business-modern {
    height: 580px;
}

.call-to-action-content.business-modern h4 {
    color: #FFFFFF;
    margin-bottom: 34px;
}

.call-to-action-content--personal {
    height: 350px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-top: 60px;
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content--personal {
        height: 220px;
        padding-top: 35px;
    }
}

.call-to-action-content--personal h5 {
    color: #f16521;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 1.5px;
}

.call-to-action-content--personal h2 {
    color: #5f362e;
    margin-bottom: 10px;
    font-size: 3.5rem;
}

@media (max-width: 1200px) {
    .call-to-action-content--personal h2 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-content--personal h2 {
        font-size: 26px;
    }
}

.call-to-action-content--design {
    height: 500px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .call-to-action-content--design {
        height: 300px;
    }
}

.call-to-action-content--design h2 {
    font-weight: 700;
    font-size: 5rem;
}

@media (max-width: 1200px) {
    .call-to-action-content--design h2 {
        font-size: calc(1.625rem + 4.5vw);
    }
}

.call-to-action-content--design-light {
    height: 200px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-content--design-light {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.call-to-action-content--design-light h4 {
    margin-bottom: 0;
}

.call-to-action-content--design-light .btn-bordered {
    color: #5f362e;
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-content--design-light .btn-bordered {
        margin-top: 25px;
    }
}

.call-to-action-content--design-light .btn-bordered:hover {
    background-color: #f16521;
    color: #FFFFFF;
}

.call-to-action-content--minimal {
    height: 450px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .call-to-action-content--minimal {
        height: 300px;
    }
}

.call-to-action-content--minimal h3 {
    font-size: 3.5rem;
}

@media (max-width: 1200px) {
    .call-to-action-content--minimal h3 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

.call-to-action-content--minimal .btn-bordered {
    color: #5f362e;
    margin-top: 40px;
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-content--minimal .btn-bordered {
        margin-top: 20px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .call-to-action-content--startup {
        height: 400px;
    }
}

.call-to-action-content--startup h2 {
    color: #f16521;
    font-size: 3.5rem;
}

@media (max-width: 1200px) {
    .call-to-action-content--startup h2 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

.call-to-action-content--startup .btn-bordered:not(:hover) {
    color: #f16521;
}

.call-to-action-architecture {
    position: relative;
}

.call-to-action-architecture:before {
    background-color: rgba(24, 33, 65, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.call-to-action-restaurant {
    position: relative;
    padding: 150px 0;
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-restaurant {
        padding: 80px 0;
    }
}

.call-to-action-restaurant:before {
    background-color: rgba(0, 0, 0, 0.7);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.call-to-action-restaurant .restaurant-contact-content h2 {
    color: #FFFFFF;
    font-family: "Playfair Display", serif;
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-restaurant .restaurant-hours {
        margin-top: 35px;
    }
}

.call-to-action-restaurant .restaurant-hours__item {
    margin-bottom: 40px;
}

.call-to-action-restaurant .restaurant-hours__item:last-child {
    margin-bottom: 0;
}

.call-to-action-restaurant .restaurant-hours__item h6 {
    color: #f16521;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
}

.call-to-action-restaurant .restaurant-hours .btn-bordered {
    color: #FFFFFF;
}

.call-to-action-classic-shop {
    border-bottom: 1px solid #eee;
    padding: 60px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .call-to-action-classic-shop .icon-box-item {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .call-to-action-classic-shop .icon-box-item .icon-box__icon {
        margin-bottom: 13px;
    }
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-events .call-to-action-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }

    .call-to-action-events .call-to-action-content .btn {
        margin-top: 25px;
    }
}

.call-to-action-finance .finance-call-to-content {
    background-color: #5f362e;
    border-radius: 6px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: -60px;
    padding: 30px 70px;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .call-to-action-finance .finance-call-to-content {
        padding: 30px;
    }
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-finance .finance-call-to-content {
        padding: 40px 30px;
    }
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-finance .finance-call-to-content {
        display: block;
        text-align: center;
    }
}

.call-to-action-finance .finance-call-to-content h2 {
    color: #FFFFFF;
    font-size: 1.5rem;
    margin-bottom: 0;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .call-to-action-finance .finance-call-to-content h2 {
        font-size: calc(1.275rem + 0.3vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .call-to-action-finance .finance-call-to-content h2 {
        font-size: 1.25rem;
    }
}

.call-to-action-finance .finance-call-to-content .btn-bordered {
    color: #FFFFFF;
}

@media only screen and (max-width: 575.98px) {
    .call-to-action-finance .finance-call-to-content .btn-bordered {
        margin-top: 20px;
    }
}

.video-popup-thumb {
    position: relative;
}

.video-popup-thumb:before {
    background-color: rgba(24, 33, 65, 0.4);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.video-popup-txt {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.video-popup-txt .btn-video-popup {
    color: #FFFFFF;
    font-size: 5rem;
    line-height: 1;
}

@media (max-width: 1200px) {
    .video-popup-txt .btn-video-popup {
        font-size: calc(1.625rem + 4.5vw);
    }
}

.video-popup-txt .btn-video-popup:hover {
    color: #f16521;
}

/*==========================
14. Blog Post Style CSS
===========================*/
.post-item {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 53px 40px 60px;
    margin-top: 30px;
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .post-item {
        padding: 35px 30px 40px;
    }
}

.post-item iframe {
    border: none;
    width: 100%;
}

.post-item:hover .post-thumb {
    background-position: center center;
    opacity: 1;
    visibility: visible;
}

.post-item:hover .post-info__title a,
.post-item:hover .post-info__action a {
    color: #FFFFFF;
}

.post-item:hover .post-info__title a:hover,
.post-item:hover .post-info__action a:hover {
    color: #f16521;
}

.post-item--2 {
    padding: 0;
    border: none;
}

.post-item--2 .post-thumb {
    background-size: cover;
    background-position: center center;
    position: static;
    height: 250px;
    opacity: 1;
    visibility: visible;
    display: block;
}

.post-item--2 .post-thumb:before {
    display: none;
}

.post-item--2 .post-info {
    margin-top: 20px;
}

.post-item--2 .post-info__meta {
    margin-bottom: 0;
}

.post-item--2:hover .post-info__title a {
    color: inherit;
}

.post-item--2 .blockquote {
    font-size: 1.875rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .post-item--2 .blockquote {
        font-size: calc(1.3125rem + 0.75vw);
    }
}

.post-item--3 {
    border: none;
    padding: 0;
}

.post-item--3 .post-thumb {
    background-size: cover;
    background-position: center center;
    position: static;
    opacity: 1;
    visibility: visible;
    display: block;
}

.post-item--3 .post-thumb:before {
    display: none;
}

.post-item--3 .post-info {
    border: 1px solid #eee;
    padding: 41px 40px 50px;
}

@media only screen and (max-width: 767.98px) {
    .post-item--3 .post-info {
        padding: 30px 20px 40px;
    }
}

@media only screen and (max-width: 767.98px) {
    .post-item--3 .post-info__meta {
        margin-bottom: 15px;
    }
}

.post-item--3:hover .post-info__title a,
.post-item--3:hover .post-info__action a {
    color: #5f362e;
}

.post-item--3 .blockquote {
    background-color: #5f362e;
    font-size: 1.5rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .post-item--3 .blockquote {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.post-item--4 {
    padding: 0;
    border: none;
}

.post-item--4 .post-thumb {
    background-size: cover;
    background-position: center center;
    position: static;
    opacity: 1;
    visibility: visible;
    display: block;
    height: auto;
}

.post-item--4 .post-thumb:before {
    display: none;
}

.post-item--4 .post-info {
    padding: 40px 30px 48px;
}

.post-item--4 .post-info__meta {
    margin-bottom: 0;
}

.post-item--4:hover .post-info__title a {
    color: inherit;
}

.post-item--4 .blockquote {
    background-color: #5f362e;
    font-size: 1.375rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .post-item--4 .blockquote {
        font-size: calc(1.2625rem + 0.15vw);
    }
}

.post-day {
    position: absolute;
    top: 0;
    right: 24px;
    z-index: 0;
    font-size: 9.375rem;
    line-height: 1;
    opacity: .07;
}

@media (max-width: 1200px) {
    .post-day {
        font-size: calc(2.0625rem + 9.75vw);
    }
}

.post-thumb {
    background-repeat: no-repeat;
    background-position: 10px center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.post-thumb:before {
    background-color: rgba(24, 33, 65, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.post-info {
    position: relative;
    z-index: 1;
}

.post-info__cate {
    color: #f16521;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-bottom: 17px;
}

.post-info__cate:hover {
    color: #f16521;
}

.post-info__title {
    font-size: 1.875rem;
}

@media (max-width: 1200px) {
    .post-info__title {
        font-size: calc(1.3125rem + 0.75vw);
    }
}

.post-info__title a {
    color: #5f362e;
}

.post-info__title a:hover {
    color: #f16521;
}

.post-info__meta {
    margin-bottom: 25px;
}

.post-info__meta a {
    color: #878c9b;
    line-height: 1;
}

.post-info__action {
    margin-top: 25px;
}

.post-link {
    background-color: #f16521;
    color: #FFFFFF;
    padding: 40px 35px;
    font-size: 1.5rem;
    display: block;
    word-break: break-word;
}

@media (max-width: 1200px) {
    .post-link {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.post-link a {
    color: #FFFFFF;
}

/*========================
15. List Box Style CSS
=========================*/
.list-box-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-top: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .list-box-item {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .list-box-item {
        margin-top: 35px;
    }
}

.list-box-item .list-box__serial {
    margin-right: 20px;
}

.list-box-item .list-box__serial .serial-no {
    color: #f16521;
    margin-bottom: 0;
}

.list-box-item .list-box__info {
    margin-top: -5px;
    font-size: 1rem;
}

.list-box-item--white .list-box__info h6 {
    color: #FFFFFF;
}

/*===========================
16. Icon Box Style CSS
============================*/
.icon-box-item {
    margin-top: 30px;
}

.icon-box-item .icon-box__icon {
    color: #f16521;
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: 27px;
    text-transform: uppercase;
}

@media (max-width: 1200px) {
    .icon-box-item .icon-box__icon {
        font-size: calc(1.5rem + 3vw);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .icon-box-item .icon-box__icon {
        margin-bottom: 13px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .icon-box-item .icon-box__icon img {
        max-width: 50%;
        margin: auto;
    }
}

.icon-box-item .icon-box__icon-dark {
    color: #878c9b;
}

.icon-box-item .icon-box__txt {
    font-size: 1rem;
}

.icon-box-item .icon-box__txt .btn {
    margin-top: 10px;
}

.icon-box-item .icon-box__txt--light h6 {
    color: #5f362e;
}

.icon-box-item--dark {
    color: #5f362e;
}

.icon-box-item--dark .icon-box__icon {
    color: #5f362e;
}

.icon-box-item--left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.icon-box-item--left .icon-box__icon {
    margin-bottom: 0;
    margin-right: 20px;
}

/*========================
19. Counter Style CSS
==========================*/
.counter-item {
    margin-top: 40px;
}

.counter-number {
    margin-bottom: 15px;
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .counter-number {
        margin-bottom: 2px;
    }
}

.counter-number.plus:after {
    content: '+';
    position: absolute;
    padding-left: 5px;
}

.counter-number--brand {
    color: #f16521;
}

.counter-txt {
    color: #000;
    font-size: 1.125rem;
    margin-bottom: 0;
}

.counter-item--dark .counter-number {
    color: #5f362e;
}

.funfact-modern-content {
    border-radius: 6px;
    padding: 100px 0;
    margin-top: -145px;
    position: relative;
    z-index: 2;
}

@media only screen and (max-width: 767.98px) {
    .funfact-modern-content {
        padding: 60px 0;
    }
}

.funfact-modern-content.bg-secondary .counter-item .counter-number {
    color: #f16521;
}

.funfact-agency-content {
    background-color: #0146a3;
    border-radius: 6px;
}

.funfact-agency-content .counter-item .counter-txt {
    color: #FFFFFF;
}

.statistic-content h2:not(.counter-number) {
    color: #f16521;
    font-size: 3.25rem;
    font-weight: 700;
}

@media (max-width: 1200px) {
    .statistic-content h2:not(.counter-number) {
        font-size: calc(1.45rem + 2.4vw);
    }
}

/*==========================
20. Progress Bar Style CSS
============================*/
.progress-bar-item {
    margin-top: 45px;
}

.progress-bar-item .progress-info {
    font-size: 0.875rem;
    line-height: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
}

.progress-bar-item .progress-info__title {
    color: #5f362e;
    font-weight: 700;
    display: inline-block;
}

.progress-bar-item .progress-line {
    background-color: #EEEEEE;
    height: 4px;
    position: relative;
}

.progress-bar-item .progress-line:before {
    background-color: #f16521;
    border-radius: 50%;
    content: '';
    position: absolute;
    top: -4px;
    left: 0;
    height: 12px;
    width: 12px;
}

.progress-bar-item .progress-line-bar {
    background-color: #f16521;
    height: 100%;
    width: 0;
}

/*============================
21. Pricing Table Style CSS
==============================*/
.pricing-item {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    padding: 104px 40px 40px;
    position: relative;
    margin-top: 68px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .pricing-item {
        padding: 104px 30px 40px;
    }
}

@media only screen and (max-width: 767.98px) {
    .pricing-item {
        padding: 104px 20px 40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .pricing-item:hover {
        -webkit-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        transform: translateY(-15px);
        -webkit-box-shadow: 0 2px 16px rgba(0, 0, 0, 0.07);
        box-shadow: 0 2px 16px rgba(0, 0, 0, 0.07);
    }
}

.pricing-item.featured {
    -webkit-box-shadow: 0 2px 16px rgba(0, 0, 0, 0.07);
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.07);
}

.pricing-item.featured:after {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-left: 0 solid transparent;
    border-bottom: 48px solid transparent;
    border-right: 48px solid #f16521;
}

.pricing-item.featured:before {
    content: '\f005';
    color: #FFFFFF;
    font-family: "FontAwesome";
    font-size: 0.8125rem;
    position: absolute;
    right: 17px;
    top: 18px;
    z-index: 1;
}

.pricing-item-header {
    border-bottom: 2px solid #eee;
    margin-bottom: 26px;
}

.pricing-item-header .price-wrap {
    background-color: #f16521;
    border-radius: 50%;
    color: #FFFFFF;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: -38px;
    left: 35px;
    width: 110px;
    height: 110px;
    text-align: center;
}

.pricing-item-header .price-wrap .price {
    color: #FFFFFF;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 5px;
}

.pricing-item-header .price-wrap .period {
    font-size: 0.8125rem;
    line-height: 1;
    margin-top: 5px;
}

.pricing-item-header h2 {
    margin-bottom: 22px;
}

@media only screen and (max-width: 767.98px) {
    .pricing-item-content .ht-list li {
        font-size: 1rem;
    }
}

.pricing-item-footer {
    margin-top: 33px;
}

@media only screen and (max-width: 767.98px) {
    .pricing-item-footer {
        margin-top: 25px;
    }
}

.pricing-item .package-feature li:before {
    color: #f16521;
}

.pricing-item--2 {
    padding-top: 40px;
    margin-top: 30px;
}

.pricing-item--2 .pricing-item-header h2 {
    margin-bottom: 10px;
}

.pricing-item--2 .pricing-item-header .price-wrap {
    background-color: transparent;
    border-radius: 0;
    color: #f16521;
    position: static;
    text-align: left;
    margin-bottom: 30px;
    height: auto;
    width: auto;
}

.pricing-item--2 .pricing-item-header .price-wrap .price {
    color: #f16521;
    font-size: 24px;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 0;
}

.pricing-package-landing {
    position: relative;
}

.pricing-package-landing:before {
    background-color: rgba(24, 33, 65, 0.8);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

/*===========================
22. Newsletter Style CSS
=============================*/
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .newsletter-content {
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 767.98px) {
    .newsletter-content {
        margin-bottom: 35px;
    }
}

.newsletter-content h4, .newsletter-content p {
    color: #5f362e;
}

.newsletter-content--light h4 {
    color: #5f362e;
}

.newsletter-form-content .form-input-item {
    margin: 0;
}

.newsletter-form-content .form-input-item input {
    color: #5f362e;
    width: 100%;
}

.newsletter-form-content .form-input-item input:focus {
    border-bottom-color: #5f362e;
}

.newsletter-form-content .form-input-item input::-webkit-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content .form-input-item input::-moz-placeholder {
    color: #5f362e;
}

.newsletter-form-content .form-input-item input:-ms-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content .form-input-item input::-ms-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content .form-input-item input::placeholder {
    color: #5f362e;
}

.newsletter-form-content .form-input-item .btn-bordered {
    color: #FFFFFF;
    min-width: 170px;
    width: 100%;
}

@media only screen and (max-width: 575.98px) {
    .newsletter-form-content .form-input-item .btn-bordered {
        margin-top: 30px;
    }
}

.newsletter-form-content--light .form-input-item input {
    color: #5f362e;
    border-bottom-color: #5f362e;
}

.newsletter-form-content--light .form-input-item input:focus,
.newsletter-form-content--light .form-input-item input:active {
    border-bottom-color: #5f362e;
}

.newsletter-form-content--light .form-input-item input::-webkit-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content--light .form-input-item input::-moz-placeholder {
    color: #5f362e;
}

.newsletter-form-content--light .form-input-item input:-ms-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content--light .form-input-item input::-ms-input-placeholder {
    color: #5f362e;
}

.newsletter-form-content--light .form-input-item input::placeholder {
    color: #5f362e;
}

.newsletter-form-content--light .form-input-item .btn {
    color: #5f362e;
}

/*=========================
23. Flip Box Style CSS
===========================*/
.flip-box {
    background-color: transparent;
    width: 100%;
    height: 500px;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .flip-box {
        height: 400px;
    }
}

@media only screen and (max-width: 767.98px) {
    .flip-box {
        height: 350px;
    }
}

.flip-box-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-transition: -webkit-transform 0.8s;
    transition: -webkit-transform 0.8s;
    -o-transition: transform 0.8s;
    transition: transform 0.8s;
    transition: transform 0.8s, -webkit-transform 0.8s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.flip-box-front,
.flip-box-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 1;
}

.flip-box-front .flip-thumb {
    height: 100%;
    width: 100%;
    margin: 0;
}

.flip-box-back {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.flip-box:hover .flip-box-inner {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
}

.flip-box--y .flip-box-back {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.flip-box--y:hover .flip-box-inner {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
}

.flip-content {
    color: #FFFFFF;
    padding: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .flip-content {
        padding: 30px;
    }
}

.flip-content h4,
.flip-content .btn-bordered {
    color: #f16521;
}

.flip-content .btn-bordered {
    margin-top: 20px;
}

@media only screen and (max-width: 575.98px) {
    .flip-content .btn-bordered {
        margin-top: 15px;
    }
}

.flip-content .btn-bordered:hover {
    color: #FFFFFF;
}

.finance-flip-box .flip-content {
    padding: 30px;
}

/*============================
24. Social Icons Style CSS
==============================*/
.social-icons-wrapper {
    padding: 180px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .social-icons-wrapper {
        padding: 90px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .social-icons-wrapper {
        padding: 60px 0;
    }
}

.social-icons a {
    color: #5f362e;
    font-size: 2.25rem;
    line-height: 1;
    margin-right: 50px;
}

@media (max-width: 1200px) {
    .social-icons a {
        font-size: calc(1.35rem + 1.2vw);
    }
}

.social-icons a:last-child {
    margin-right: 0;
}

@media only screen and (max-width: 767.98px) {
    .social-icons a {
        margin-right: 20px;
    }
}

.social-icons a:hover {
    color: #f16521;
}

.social-icons--2 {
    padding: 85px 0;
}

@media only screen and (max-width: 767.98px) {
    .social-icons--2 {
        padding: 30px 0;
    }
}

.social-icons--2 a {
    color: #dddddd;
    font-size: 1.5rem;
    margin-right: 25px;
}

@media (max-width: 1200px) {
    .social-icons--2 a {
        font-size: calc(1.275rem + 0.3vw);
    }
}

.social-icons--3 a {
    font-size: 1.125rem;
    margin-right: 15px;
}

.social-icons--4 a {
    font-size: 1.25rem;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .social-icons--4 a {
        margin-right: 30px;
    }
}

.social-icons--5 a {
    color: #FFFFFF;
    margin-right: 20px;
    font-size: 1.125rem;
}

.social-button .btn-bottom {
    margin-right: 20px;
}

.social-button .btn-bottom:before {
    bottom: -5px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.social-button .btn-bottom:last-child {
    margin-right: 0;
}

.social-button .btn-bottom:hover:before {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}

/*========================
27. Banner Style CSS
=========================*/
.banner-content h2 {
    margin-bottom: 20px;
}

@media only screen and (max-width: 767.98px) {
    .banner-content h2 {
        margin-top: 10px;
    }
}

.banner-content .btn {
    margin-top: 30px;
}

@media only screen and (max-width: 767.98px) {
    .banner-content .btn {
        margin-top: 20px;
    }
}

.banner-content--business-service {
    padding: 180px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .banner-content--business-service {
        padding: 90px 0;
    }
}

.banner-content--restaurant h2 {
    color: #FFFFFF;
    margin-bottom: 0;
    font-size: 3.75rem;
    font-family: "Playfair Display", serif;
}

@media (max-width: 1200px) {
    .banner-content--restaurant h2 {
        font-size: calc(1.5rem + 3vw);
    }
}

.banner-item-content h6 {
    color: #f16521;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    margin-bottom: 30px;
}

.banner-item-content h4 {
    color: #FFFFFF;
    margin-bottom: 24px;
}

.banner-item-content .btn-bottom {
    color: #FFFFFF;
    margin-top: 15px;
}

@media only screen and (max-width: 767.98px) {
    .banner-item-content .btn-bottom {
        margin-top: 10px;
    }
}

/*====================================
Home Marketing Agency Banner Gallery
=====================================*/
.gallery-banner-item {
    height: 475px;
    width: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .gallery-banner-item {
        height: 400px;
    }
}

@media only screen and (max-width: 767.98px) {
    .gallery-banner-item {
        height: 300px;
    }
}

.gallery-banner-item-txt {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 70px;
}

@media only screen and (max-width: 575.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .gallery-banner-item-txt {
        padding: 0 30px;
    }
}

.gallery-banner-item-txt.bg-brand {
    color: #5f362e;
}

.gallery-banner-item-txt.bg-brand h4 {
    color: #5f362e;
}

.gallery-banner-item-txt h4 {
    color: #f16521;
    margin-top: 0;
}

/*====================================
Home Interior Banner Area
=====================================*/
.interior-banner-area {
    position: relative;
}

.interior-banner-area:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.interior-banner-content {
    padding: 80px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .interior-banner-content {
        padding: 30px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .interior-banner-content {
        padding: 40px 0;
    }
}

.interior-banner-content h2 {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 3.125rem;
}

@media (max-width: 1200px) {
    .interior-banner-content h2 {
        font-size: calc(1.4375rem + 2.25vw);
    }
}

/*====================================
Home Restaurant Banner Area
=====================================*/
.banner-restaurant-area {
    position: relative;
    padding: 200px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .banner-restaurant-area {
        padding: 120px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .banner-restaurant-area {
        padding: 100px 0;
    }
}

.banner-restaurant-area:before {
    background-color: rgba(0, 0, 0, 0.5);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

/*=========================
30. Product Style CSS
===========================*/
.product-item:hover .product-thumb img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
}

.product-item:hover .product-action button,
.product-item:hover .product-action a {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 1;
    visibility: visible;
}

.product-item:hover .product-action button:nth-child(2),
.product-item:hover .product-action a:nth-child(2) {
    -webkit-transition-property: opacify, visibility, -webkit-transform;
    transition-property: opacify, visibility, -webkit-transform;
    -o-transition-property: transform, opacify, visibility;
    transition-property: transform, opacify, visibility;
    transition-property: transform, opacify, visibility, -webkit-transform;
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
}

.product-item:hover .product-action button:nth-child(3),
.product-item:hover .product-action a:nth-child(3) {
    -webkit-transition-property: opacify, visibility, -webkit-transform;
    transition-property: opacify, visibility, -webkit-transform;
    -o-transition-property: transform, opacify, visibility;
    transition-property: transform, opacify, visibility;
    transition-property: transform, opacify, visibility, -webkit-transform;
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
}

.product-thumb {
    overflow: hidden;
    position: relative;
}

.product-thumb img {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.product-info {
    text-align: center;
    margin-top: 30px;
    position: relative;
}

.product-info .title {
    font-size: 1.375rem;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    .product-info .title {
        font-size: calc(1.2625rem + 0.15vw);
    }
}

.product-info .title a {
    color: #5f362e;
}

.product-info .title a:hover {
    color: #f16521;
}

.product-info .price {
    color: #f16521;
    font-size: 1.25rem;
    line-height: 1;
}

.product-info .price-old {
    color: #bbbbbb;
    margin-right: 5px;
}

.product-info .price-sale {
    color: #ff0000;
}

.product-info .badge {
    position: absolute;
    top: -50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: block;
    margin: 0 2px;
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    line-height: 50px;
}

.product-info .badge-sale {
    background-color: #ff0000;
}

.product-action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    top: 25px;
    right: 25px;
}

.product-action button,
.product-action a {
    border: 1px solid #5f362e;
    border-radius: 50%;
    font-size: 11px;
    text-align: center;
    line-height: 30px;
    height: 30px;
    width: 30px;
    margin-bottom: 15px;
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.product-action button:last-child,
.product-action a:last-child {
    margin-bottom: 0;
}

.product-action button:hover,
.product-action a:hover {
    background-color: #5f362e;
    color: #FFFFFF;
}

.products-wrapper--grid {
    margin-top: -30px;
}

.products-wrapper--grid .product-item {
    margin-top: 30px;
}

/*==========================
34. Accordion Style Css
============================*/
.accordion .card {
    border-radius: 0;
    border: none;
    margin-bottom: 30px;
}

.accordion .card:last-child {
    margin-bottom: 0;
}

.accordion .card-header {
    background-color: transparent;
    border: none;
    border-radius: 0;
    cursor: pointer;
    margin-bottom: 0 !important;
    padding: 0;
}

.accordion .card-header h4 {
    border-bottom: 1px solid #eee;
    font-size: 1.25rem;
    margin: 0;
    padding-bottom: 15px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.accordion .card-header h4:hover,
.accordion .card-header h4[aria-expanded="true"] {
    color: #f16521;
    border-bottom-color: #f16521;
}

.accordion .card-body {
    padding: 25px 0 0;
}

/*==========================
47. F.A.Q Page Style Css
============================*/
.faq-page-content-wrap .single-faq-topic {
    margin-bottom: 80px;
}

@media only screen and (max-width: 767.98px) {
    .faq-page-content-wrap .single-faq-topic {
        margin-bottom: 50px;
    }
}

.faq-page-content-wrap .single-faq-topic:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 767.98px) {
    .faq-page-content-wrap .single-faq-topic .faq-topic-title {
        margin-bottom: 30px;
    }
}

.faq-page-content-wrap .single-faq-topic .faq-topic-title h4 {
    margin-top: -4px;
    position: relative;
    padding-bottom: 10px;
}

.faq-page-content-wrap .single-faq-topic .faq-topic-title h4:before {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
    display: none;
}

@media only screen and (max-width: 767.98px) {
    .faq-page-content-wrap .single-faq-topic .faq-topic-title h4:before {
        display: block;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .faq-page-content-wrap .single-faq-topic .faq-topic-title h4 {
        font-size: 26px;
    }
}

/*=====================
Tabs Style
==================*/
.tab-nav--2 li a {
    background-color: #EEEEEE;
    color: #5f362e;
    display: inline-block;
    font-size: 20px;
    padding: 10px 20px;
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .tab-nav--2 li a {
        padding: 6px 15px;
    }
}

.tab-nav--2 li a:before {
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -10px;
    top: 100%;
    width: 0;
    height: 0;
    border: 9px solid transparent;
    border-top-color: #5f362e;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.tab-nav--2 li a.active {
    background-color: #5f362e;
    color: #FFFFFF;
}

.tab-nav--2 li a.active:before {
    opacity: 1;
    visibility: visible;
}

.tab-nav--2-pill {
    display: block;
}

.tab-nav--2-pill li a {
    display: block;
}

.tab-nav--2-pill li a:before {
    left: 100%;
    margin-left: 0;
    top: 50%;
    margin-top: -10px;
    border-left-color: #5f362e;
    border-top-color: transparent;
}

.tab-content--2 {
    border: 1px solid #eee;
    padding: 40px 30px;
}

/*=================================
35. Restaurant Menu Style Css
===================================*/
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .restaurant-menu-area {
        padding: 90px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .restaurant-menu-area {
        padding: 60px 0;
    }
}

.restaurant-menu-area .restaurant-menu-inner {
    margin-top: -150px;
    padding: 100px;
    position: relative;
    z-index: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .restaurant-menu-area .restaurant-menu-inner {
        margin-top: 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .restaurant-menu-area .restaurant-menu-inner {
        padding: 50px 15px;
        margin-top: 0;
    }
}

.menu-item {
    margin-top: 50px;
}

.menu-item__heading {
    margin-bottom: 45px;
}

@media only screen and (max-width: 767.98px) {
    .menu-item__heading {
        margin-bottom: 30px;
    }
}

.menu-item__heading h3 {
    font-size: 2.5rem;
    line-height: 1;
    margin-bottom: 0;
    font-family: "Playfair Display", serif;
}

@media (max-width: 1200px) {
    .menu-item__heading h3 {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.menu-item .menu-list__item {
    margin-bottom: 40px;
}

@media only screen and (max-width: 767.98px) {
    .menu-item .menu-list__item {
        margin-bottom: 20px;
    }
}

.menu-item .menu-list__item:last-child {
    margin-bottom: 0;
}

.menu-item .menu-list__item .menu-title {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.menu-item .menu-list__item .menu-text {
    font-size: 0.9375rem;
    font-family: "Playfair Display", serif;
    font-style: italic;
}

.menu-item .menu-list__item .menu-price {
    font-size: 1.25rem;
    color: #f16521;
    line-height: 1;
}

/*============================
36. Team Member Style Css
==============================*/
.team-member {
    position: relative;
    margin-top: 30px;
}

.team-member__content {
    background-color: rgba(24, 33, 65, 0.9);
    text-align: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.team-member__content .member-info,
.team-member__content .member-icons {
    position: absolute;
    left: 50%;
    width: 100%;
}

.team-member__content .member-info {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.team-member__content .member-info h3 {
    color: #FFFFFF;
    font-size: 1.25rem;
    line-height: 1;
    margin-top: 0;
}

.team-member__content .member-info .designation {
    color: #c1c5d5;
    font-size: 1rem;
    line-height: 1;
}

.team-member__content .member-icons {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    bottom: 40px;
}

.team-member__content .member-icons a {
    color: #FFFFFF;
    font-size: 1.375rem;
    line-height: 1;
    margin-right: 15px;
}

@media (max-width: 1200px) {
    .team-member__content .member-icons a {
        font-size: calc(1.2625rem + 0.15vw);
    }
}

.team-member__content .member-icons a:last-child {
    margin-right: 0;
}

.team-member:hover .team-member__content {
    opacity: 1;
    visibility: visible;
}

/*==========================
37. Schedules Style Css
===========================*/
.schedules-nav {
    border-bottom: 2px solid #eee;
    margin-bottom: 40px;
    padding-bottom: 5px;
}

.schedules-nav li {
    margin-right: 40px;
}

.schedules-nav li:last-child {
    margin-right: 0;
}

.schedules-nav li a {
    color: #878c9b;
    font-size: 1.25rem;
    line-height: 1;
    position: relative;
}

.schedules-nav li a.active:before {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    height: 2px;
    width: 100%;
}

.schedules-content {
    overflow: hidden;
}

.schedules-content .tab-pane.show h4,
.schedules-content .tab-pane.show h6 {
    -webkit-animation: slideInLeft 0.4s forwards;
    animation: slideInLeft 0.4s forwards;
}

.schedules-content-item h4 {
    color: #f16521;
    margin-bottom: 33px;
}

.schedules-timetable-item {
    margin-bottom: 30px;
}

@media only screen and (max-width: 767.98px) {
    .schedules-timetable-item {
        margin-bottom: 25px;
    }
}

.schedules-timetable-item:last-child {
    margin-bottom: 0;
}

.pd-text span {
    font-family: "Playfair Display", serif;
    font-style: italic;
}

.achieve-list-area .list-box-item {
    margin-top: 60px;
}

@media only screen and (max-width: 767.98px) {
    .achieve-list-area .list-box-item {
        margin-top: 30px;
    }
}

/*================================
40. Event Home Hero Style Css
==================================*/
.event-hero-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100vh;
    margin-top: 30px;
}

.event-hero-content h4 {
    color: #f16521;
    margin-bottom: 25px;
}

.event-hero-content h2 {
    color: #FFFFFF;
    font-size: 7.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .event-hero-content h2 {
        font-size: calc(1.875rem + 7.5vw);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .event-hero-content h2 {
        font-size: 80px;
    }
}

@media only screen and (max-width: 575.98px) {
    .event-hero-content h2 {
        font-size: 40px;
    }
}

.event-countdown-wrap {
    position: relative;
    padding-top: 60px;
    margin-top: 50px;
}

@media only screen and (max-width: 767.98px) {
    .event-countdown-wrap {
        padding-top: 40px;
        margin-top: 30px;
    }
}

.event-countdown-wrap:before {
    background-color: #FFFFFF;
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    height: 2px;
    width: 350px;
}

@media only screen and (max-width: 575.98px) {
    .event-countdown-wrap:before {
        width: 250px;
    }
}

/*=================================
56. Freelancer Hero Style Css
================================*/
.freelancer-hero-wrapper {
    height: 100vh;
    width: 100%;
    position: relative;
    z-index: 1;
}

.freelancer-hero-wrapper:before {
    background-color: rgba(24, 33, 65, 0.8);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.freelancer-hero-wrapper .hero-animate-btn {
    color: #FFFFFF;
    position: absolute;
    bottom: 70px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
}

@media only screen and (max-width: 767.98px) {
    .freelancer-hero-wrapper .hero-animate-btn {
        bottom: 30px;
    }
}

.freelancer-hero-wrapper .hero-animate-btn i {
    display: block;
    text-align: center;
}

.freelancer-hero-wrapper .hero-animate-btn i:after {
    background-color: #FFFFFF;
    content: '';
    position: absolute;
    bottom: calc(100% - 22px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    height: 70px;
    width: 1px;
    -webkit-animation: animate-line 1.3s cubic-bezier(1, 0, 0, 1) infinite;
    animation: animate-line 1.3s cubic-bezier(1, 0, 0, 1) infinite;
}

.freelancer-hero-wrapper .hero-animate-btn i:before {
    font-size: 25px;
}

.freelancer-hero-content h2 {
    color: #FFFFFF;
    font-size: 120px;
    font-family: "Playfair Display", serif;
    letter-spacing: 30px;
    font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .freelancer-hero-content h2 {
        font-size: 80px;
        letter-spacing: 20px;
    }
}

@media only screen and (max-width: 767.98px) {
    .freelancer-hero-content h2 {
        font-size: 40px;
        letter-spacing: 10px;
    }
}

.freelancer-hero-content h2 span {
    display: inline-block;
}

.freelancer-hero-content h4 {
    color: #EEEEEE;
    margin-bottom: 0;
    margin-top: 20px;
    text-transform: uppercase;
}

.freelancer-hero-content h4 span {
    color: #f16521;
}

@-webkit-keyframes animate-line {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

@keyframes animate-line {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
    }

    50% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    50.1% {
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
    }

    100% {
        -webkit-transform-origin: 0 100%;
        transform-origin: 0 100%;
        -webkit-transform: scale(1, 0);
        transform: scale(1, 0);
    }
}

/*========================
42. Timeline Style Css
===========================*/
.cd-timeline-wrap {
    margin: 0;
    position: relative;
}

.cd-timeline-wrap .cd-timeline {
    margin: 0;
    padding: 0;
}

.cd-timeline-wrap .cd-timeline:before {
    display: none;
}

.cd-timeline-wrap .cd-timeline-block {
    margin: 0;
    padding-bottom: 40px;
}

.cd-timeline-wrap .cd-timeline-block:last-child {
    padding-bottom: 0;
}

@media only screen and (min-width: 1200px) {
    .cd-timeline-wrap .cd-timeline-block:last-child:before {
        display: none;
    }
}

.cd-timeline-wrap .cd-timeline-block:nth-child(2n) .cd-timeline-content {
    text-align: right;
    float: left;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-timeline-block:nth-child(2n) .cd-timeline-content {
        text-align: left;
    }
}

.cd-timeline-wrap .cd-timeline-block:before {
    background-color: #EEEEEE;
    content: '';
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    height: 100%;
    width: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-timeline-block:before {
        left: 4px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}

.cd-timeline-wrap .cd-timeline-block .cd-timeline-dot {
    background-color: #f16521;
    border-radius: 50%;
    display: block;
    height: 12px;
    left: 50%;
    position: absolute;
    top: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 12px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-timeline-block .cd-timeline-dot {
        left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }
}

.cd-timeline-wrap .cd-timeline-content {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    text-align: left;
    font-size: 18px;
    color: #878c9b;
    float: right;
}

@media only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-timeline-content {
        margin-left: 30px;
    }
}

.cd-timeline-wrap .cd-timeline-content:before {
    display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .cd-timeline-wrap .cd-timeline-content {
        text-align: left;
    }
}

.cd-timeline-wrap .cd-timeline-content h5 {
    font-weight: 700;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 1.75px;
    margin-bottom: 10px;
}

.cd-timeline-wrap .cd-timeline-content p {
    font-size: 18px;
    margin: 0;
}

/*============================
43. Page Header Style Css
==============================*/
.page-header-wrapper {
    position: relative;
}

.page-header-wrapper:before {
    background-color: rgba(24, 33, 65, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.page-header-content {
    height: 600px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 75px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .page-header-content {
        height: 450px;
    }
}

@media only screen and (max-width: 767.98px) {
    .page-header-content {
        height: 350px;
    }
}

.page-header-content .section-title h2 {
    color: #FFFFFF;
}

.page-header-content .breadcrumb-wrap,
.page-header-content .section-title {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
    margin: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.breadcrumb li {
    margin-right: 18px;
    position: relative;
}

.breadcrumb li:after {
    content: '>';
    color: #FFFFFF;
    font-weight: 500;
    padding-left: 20px;
    vertical-align: middle;
}

.breadcrumb li:last-child {
    margin-right: 0;
}

.breadcrumb li:last-child:after {
    display: none;
}

.breadcrumb li a {
    color: #FFFFFF;
    font-size: 16px;
}

.breadcrumb li a:hover {
    color: #f16521;
}

.breadcrumb li a.active {
    opacity: 0.8;
    pointer-events: none;
}

/*=========================
49. Sidebar Style Css
===========================*/
.sidebar-wrapper {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 30px 30px 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sidebar-wrapper {
        margin-top: 90px;
    }
}

@media only screen and (max-width: 767.98px) {
    .sidebar-wrapper {
        margin-top: 60px;
    }
}

.sidebar-wrapper.shop-page {
    border: 0;
    padding: 0;
}

.sidebar-wrapper.shop-page .sidebar-search-box {
    margin-top: -10px;
}

.sidebar-item {
    margin-bottom: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .sidebar-item {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .sidebar-item {
        margin-bottom: 40px;
    }
}

.sidebar-item:last-child {
    margin-bottom: 0;
}

.sidebar-title {
    color: #f16521;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 25px;
    padding-bottom: 10px;
    position: relative;
}

.sidebar-title:before {
    background: #ddd;
    bottom: 0;
    content: "";
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
}

.sidebar-list li {
    margin-bottom: 20px;
}

.sidebar-list li:last-child {
    margin-bottom: 0;
}

.sidebar-list li a {
    color: #5f362e;
    font-size: 18px;
}

.sidebar-list li a:hover {
    color: #f16521;
}

.sidebar-list li .post-date {
    font-size: 16px;
    display: block;
    line-height: 1;
    margin-top: 5px;
}

.sidebar-search-box .form-input-item {
    position: relative;
    margin: 0;
}

.sidebar-search-box .form-input-item input {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    padding-right: 30px;
}

.sidebar-search-box .form-input-item input:focus {
    border-bottom-color: #5f362e;
}

.sidebar-search-box .form-input-item input::-webkit-input-placeholder {
    color: #878c9b;
}

.sidebar-search-box .form-input-item input::-moz-placeholder {
    color: #878c9b;
}

.sidebar-search-box .form-input-item input:-ms-input-placeholder {
    color: #878c9b;
}

.sidebar-search-box .form-input-item input::-ms-input-placeholder {
    color: #878c9b;
}

.sidebar-search-box .form-input-item input::placeholder {
    color: #878c9b;
}

.sidebar-search-box .form-input-item .btn-src {
    font-size: 21px;
    line-height: 1;
    color: #f16521;
    position: absolute;
    right: 0;
    top: 12px;
}

@media only screen and (max-width: 767.98px) {
    .sidebar-search-box .form-input-item .btn-src {
        top: 6px;
    }
}

.tags {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tags li {
    margin-right: 5px;
}

.tags li:after {
    content: ',';
}

.tags li:last-child {
    margin-right: 0;
}

.tags li:last-child:after {
    display: none;
}

.tags li a {
    color: #878c9b;
}

.tags li a:hover {
    color: #f16521;
}

.top-rated-prod-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.top-rated-prod-item:nth-child(1n + 2) {
    margin-top: 20px;
}

.top-rated-prod-item h3 {
    font-size: 20px;
    margin-bottom: 9px;
    margin-top: -3px;
}

.top-rated-prod-item h3 a {
    color: #5f362e;
}

.top-rated-prod-item h3 a:hover {
    color: #5f362e;
}

.top-rated-prod-item .rating {
    color: #f16521;
    line-height: 1;
    font-size: 14px;
    margin-bottom: 5px;
}

.top-rated-prod-item .price-group {
    font-weight: 700;
}

.top-rated-prod-item .price-group .price-old {
    opacity: 0.8;
    margin-right: 5px;
}

.top-rated-prod-item .price-group .price {
    color: #f16521;
}

.top-rated-prod-item .prod-thumb {
    max-width: 80px;
    -webkit-flex-basis: 100px;
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
    margin-right: 20px;
}

/*==========================================
Preloader Style Css
===========================================*/
.preloader-active {
    overflow: hidden;
}

.preloader-active .preloader-wrap {
    background-color: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99999;
}

.preloader-active .preloader-wrap .preloader {
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    -webkit-filter: contrast(20);
    filter: contrast(20);
}

.preloader-active .preloader-wrap .preloader .dot {
    background-color: #000000;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 12px;
    left: 15px;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    border-radius: 50%;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation: dot 2.8s infinite;
    animation: dot 2.8s infinite;
}

.preloader-active .preloader-wrap .preloader .dots {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    margin-top: 12px;
    margin-left: 31px;
    -webkit-animation: dots 2.8s infinite;
    animation: dots 2.8s infinite;
}

.preloader-active .preloader-wrap .preloader .dots span {
    background-color: #000000;
    display: block;
    float: left;
    width: 16px;
    height: 16px;
    margin-left: 16px;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    border-radius: 50%;
}

@-webkit-keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@keyframes dot {
    50% {
        -webkit-transform: translateX(96px);
        transform: translateX(96px);
    }
}

@-webkit-keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}

@keyframes dots {
    50% {
        -webkit-transform: translateX(-31px);
        transform: translateX(-31px);
    }
}

/*=======================
Quick View Style Here
=========================*/
/*#quick-view {
      .product-thumb-area {
    max-height: 450px;
    overflow: hidden;

    @media only screen and (max-width: 767.98px) {
      max-height: 250px;
    }
  }
}*/

#quick-view.modal {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-animation: fadeOut 0.3s forwards;
    animation: fadeOut 0.3s forwards;
}

#quick-view.modal.show {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    z-index: 9999;
    -webkit-animation: fadeIn 0.3s forwards;
    animation: fadeIn 0.3s forwards;
}

#quick-view.modal .modal-content {
    border: none;
    border-radius: 0;
}

@media only screen and (max-width: 767.98px) {
    #quick-view.modal .modal-content {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

#quick-view.modal .modal-dialog {
    max-width: 1110px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    #quick-view.modal .modal-dialog {
        max-width: 720px;
    }
}

@media only screen and (max-width: 767.98px) {
    #quick-view.modal .modal-dialog {
        max-width: 540px;
    }
}

#quick-view.modal .modal-dialog .modal-body {
    padding: 0;
}

#quick-view.modal .modal-dialog .close {
    background-color: #222222;
    font-weight: 400;
    color: #FFFFFF;
    opacity: 1;
    height: 60px;
    width: 60px;
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 99;
}

#quick-view.modal .modal-dialog .close:hover {
    background-color: #f16521;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    #quick-view.modal .modal-dialog .close {
        left: auto;
        right: 0;
        top: 0;
        height: 40px;
        width: 40px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    #quick-view.modal .modal-dialog .close {
        top: auto;
        bottom: 100%;
    }
}

#quick-view .product-details-info-content-wrap {
    padding: 0 30px 0 0;
    position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    #quick-view .product-details-info-content-wrap {
        max-height: 420px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 767.98px) {
    #quick-view .product-details-info-content-wrap {
        padding: 0 30px;
    }
}

@media only screen and (max-width: 575.98px) {
    #quick-view .product-details-info-content-wrap {
        padding: 0 15px;
    }
}

#quick-view .product-details-info-content-wrap .prod-details-info-content {
    padding: 50px 0;
}

#quick-view .product-details-info-content-wrap .prod-details-info-content h2 {
    font-weight: 500;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    #quick-view .product-details-info-content-wrap .prod-details-info-content h2 {
        font-size: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    #quick-view .product-details-info-content-wrap .prod-details-info-content .btn-bordered {
        padding: 15px;
    }
}

@media only screen and (max-width: 479.98px) {
    #quick-view .product-details-info-content-wrap .prod-details-info-content .btn-bordered {
        margin-top: 30px;
    }
}

/*=================================
17. Full Page Home Page Style
==================================*/
@media only screen and (max-width: 767.98px) {
    #fullpage .section {
        padding: 30px 0;
    }

    #fullpage .section:first-child {
        padding-top: 0;
    }

    #fullpage .section:last-child {
        padding: 0;
    }

    #fullpage .section:nth-last-child(2) {
        padding-bottom: 0;
    }
}

#fullpage .section>.fp-tableCell>div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
}

#fp-nav ul li,
#multiscroll-nav ul li {
    margin: 0 0 12px;
    height: auto;
    width: auto;
}

#fp-nav ul li:last-child,
#multiscroll-nav ul li:last-child {
    display: none;
    margin-bottom: 0;
}

#fp-nav ul li a,
#multiscroll-nav ul li a {
    background-color: transparent;
    border: 3px solid #FFFFFF;
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

#fp-nav ul li a.active,
#multiscroll-nav ul li a.active {
    border-color: #f16521;
}

#fp-nav ul li a span,
#multiscroll-nav ul li a span {
    display: none;
}

#fp-nav.dark ul li a,
#multiscroll-nav.dark ul li a {
    border-color: #878c9b;
}

#fp-nav.dark ul li a.active,
#multiscroll-nav.dark ul li a.active {
    border-color: #f16521;
}

#fp-nav.dark ul li .fp-tooltip,
#multiscroll-nav.dark ul li .fp-tooltip {
    color: #878c9b;
}

@media only screen and (max-width: 767.98px) {
    .presentation-achievement-area {
        padding: 60px 0;
    }
}

.presentation-service-area {
    background: url("../img/icons/letter_a-2.png") no-repeat 95% 80px;
}

.presentation-service-area .presentation-service-bottom {
    border-top: 1px solid #eee;
    padding-top: 80px;
    margin-top: 80px;
}

@media only screen and (max-width: 767.98px) {
    .presentation-service-area .presentation-service-bottom {
        padding-top: 50px;
        margin-top: 50px;
    }
}

.presentation-contact-area {
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .presentation-contact-area {
        padding: 60px 0;
    }
}

.presentation-contact-area:before {
    background-color: rgba(24, 33, 65, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

/*==========================================
25. Home FullScreen Carousel Style CSS
===========================================*/
.port-full-carousel-content {
    width: 100%;
}

.port-full-carousel-content .port-full-item {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    float: left;
    position: relative;
}

.port-full-carousel-content .port-full-item__info {
    background: -webkit-gradient(linear, left bottom, left top, from(black), to(rgba(0, 0, 0, 0)));
    background: -webkit-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 100%);
    background: -o-linear-gradient(bottom, black 0%, rgba(0, 0, 0, 0) 100%);
    background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .port-full-carousel-content .port-full-item__info {
        padding: 50px 30px;
    }
}

.port-full-carousel-content .port-full-item__info h4 {
    margin-bottom: 14px;
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: -0.21px;
}

@media (max-width: 1200px) {
    .port-full-carousel-content .port-full-item__info h4 {
        font-size: calc(1.3125rem + 0.75vw);
    }
}

.port-full-carousel-content .port-full-item__info h4 a {
    color: #FFFFFF;
}

.port-full-carousel-content .port-full-item__info h4 a:hover {
    color: #f16521;
}

.port-full-carousel-content .port-full-item__info .cate a {
    color: #f16521;
}

.port-full-carousel-content .port-full-item__info .cate a:after {
    content: ',';
}

.port-full-carousel-content .port-full-item__info .cate a:last-child:after {
    display: none;
}

.mCS-arden.mCustomScrollBox {
    position: relative;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal {
    height: 10px;
}

@media only screen and (max-width: 767.98px) {
    .mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal {
        height: 4px;
    }
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: #f16521;
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.2);
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    position: fixed;
    top: 50%;
    width: 50px;
    height: 50px;
    background: none;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 1;
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft:before,
.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight:before {
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: "linea-arrows-10";
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #FFFFFF;
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
    left: 10px;
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft:before {
    content: '\e03f';
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
    right: 10px;
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight:before {
    content: '\e04b';
}

.mCS-arden .mCSB_scrollTools.mCSB_scrollTools_horizontal a+.mCSB_draggerContainer {
    margin: 0;
}

.mCS-arden .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded+.mCSB_draggerRail,
.mCS-arden .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-arden .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-arden .mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
    margin: 0 auto;
    width: 100%;
    height: 100%;
}

.mCS-arden .mCSB_scrollTools .mCSB_buttonLeft {
    background-position: -100px -40px;
}

.mCS-arden .mCSB_scrollTools .mCSB_buttonRight {
    background-position: -100px -56px;
}

/*==========================================
28. Home Portfolio FullScreen Slider CSS
===========================================*/
.portfolio-fullscreen-slide {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100vh;
    width: 100vw;
    padding: 0 130px 130px 0;
    text-align: right;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-fullscreen-slide {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        text-align: center;
    }
}

.portfolio-fullscreen-slide:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt h2 {
    font-size: 3.75rem;
}

@media (max-width: 1200px) {
    .portfolio-fullscreen-slide .portfolio-slider-slide-txt h2 {
        font-size: calc(1.5rem + 3vw);
    }
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt h2 a {
    color: #FFFFFF;
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt h2 a:hover {
    color: #f16521;
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt .categories a {
    color: #f16521;
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt .categories a:after {
    content: ',';
}

.portfolio-fullscreen-slide .portfolio-slider-slide-txt .categories a:last-child:after {
    display: none;
}

.portfolio-fullscreen-slider-area .lightArrows-2 .lSAction a {
    left: 50px;
    color: #FFFFFF;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-fullscreen-slider-area .lightArrows-2 .lSAction a {
        left: 20px;
    }
}

.portfolio-fullscreen-slider-area .lightArrows-2 .lSAction a.lSNext {
    left: auto;
    right: 50px;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-fullscreen-slider-area .lightArrows-2 .lSAction a.lSNext {
        right: 20px;
    }
}

.portfolio-fullscreen-slider-area .lSPager {
    position: absolute;
    width: 100%;
    bottom: 40px;
}

/*=======================================
32. Home Portfolio Split Style CSS
========================================*/
.home-portfolio-spilt-area {
    height: 100vh;
}

.home-portfolio-spilt-area .home-portfolio-split-img {
    height: 100%;
}

.home-portfolio-spilt-area .home-portfolio-split-content {
    background-color: black;
    color: #FFFFFF;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.portfolio-split-info {
    padding: 0 120px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .portfolio-split-info {
        padding: 0 90px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-split-info {
        padding: 0 50px 0 30px;
    }
}

.portfolio-split-info .cate {
    color: #f16521;
}

.portfolio-split-info h2 {
    font-size: 3.75rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 30px;
}

@media (max-width: 1200px) {
    .portfolio-split-info h2 {
        font-size: calc(1.5rem + 3vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .portfolio-split-info h2 {
        font-size: 2.1875rem;
        margin-top: 15px;
    }
}

@media only screen and (max-width: 767.98px) and (max-width: 1200px) {
    .portfolio-split-info h2 {
        font-size: calc(1.34375rem + 1.125vw);
    }
}

.portfolio-split-info h2 a {
    color: #FFFFFF;
}

.portfolio-split-info .btn-bordered {
    color: #f16521;
    margin-top: 45px;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-split-info .btn-bordered {
        margin-top: 30px;
    }
}

.portfolio-split-info .btn-bordered:hover {
    color: #FFFFFF;
}

@media only screen and (max-width: 575.98px) {

    #multiscroll .ms-left,
    #multiscroll .ms-right {
        width: 100% !important;
    }
}

@media only screen and (max-width: 575.98px) {
    #multiscroll .home-portfolio-split-content {
        background-color: rgba(0, 0, 0, 0.5);
    }
}

#multiscroll-nav ul li:last-child {
    display: block;
}

.footer-area-port-split {
    max-width: 50%;
    padding: 0 120px;
    margin-left: auto;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .footer-area-port-split {
        padding: 0 90px;
    }
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .footer-area-port-split {
        max-width: 100%;
        padding: 0 50px;
    }
}

@media only screen and (max-width: 575.98px) {
    .footer-area-port-split {
        padding: 0 30px;
    }
}

.footer-area-port-split .footer-port-split-content {
    font-size: 0.9375rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 60px;
}

@media only screen and (max-width: 767.98px) {
    .footer-area-port-split .footer-port-split-content {
        padding-bottom: 25px;
    }
}

/*=======================================
Home Portfolio Metro Style CSS
========================================*/
.portfolio-metro-top {
    padding: 70px 0 95px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .portfolio-metro-top {
        padding: 50px 0 70px;
    }
}

@media only screen and (max-width: 767.98px) {
    .portfolio-metro-top {
        padding: 50px 0;
    }
}

.portfolio-metro-top .portfolio-metro-txt h2 {
    color: #f16521;
    font-weight: 700;
    font-size: 4.375rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .portfolio-metro-top .portfolio-metro-txt h2 {
        font-size: calc(1.5625rem + 3.75vw);
    }
}

.portfolio-content-wrap.image-gallery-justified .portfolio-item img {
    width: 100%;
}

/*====================================
26. Home Blog Slider Style CSS
=====================================*/
.blog-slider-slide {
    background-color: #aaaaaa;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    position: relative;
    height: 100vh;
    width: 100%;
    z-index: 1;
}

.blog-slider-slide:before {
    background-color: rgba(0, 0, 0, 0.3);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.blog-slider-slide-txt {
    color: #FFFFFF;
}

@media only screen and (min-width: 1200px) {
    .blog-slider-slide-txt {
        max-width: 35%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .blog-slider-slide-txt {
        max-width: 70%;
    }
}

@media only screen and (max-width: 767.98px) {
    .blog-slider-slide-txt {
        max-width: 90%;
    }
}

.blog-slider-slide-txt h6 {
    color: #f16521;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 50px;
}

@media only screen and (max-width: 767.98px) {
    .blog-slider-slide-txt h6 {
        margin-bottom: 30px;
    }
}

.blog-slider-slide-txt h2 {
    font-size: 3.75rem;
    color: #FFFFFF;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .blog-slider-slide-txt h2 {
        font-size: calc(1.5rem + 3vw);
    }
}

.blog-slider-slide-txt .btn-bordered {
    border-color: #FFFFFF;
    color: #FFFFFF;
    margin-top: 50px;
}

@media only screen and (max-width: 767.98px) {
    .blog-slider-slide-txt .btn-bordered {
        margin-top: 30px;
    }
}

.blog-slider-slide-txt .btn-bordered:hover {
    border-color: #f16521;
}

.blog-full-slider-area .lSSlideOuter {
    position: relative;
}

.blog-full-slider-area .lSSlideOuter .lSAction a {
    left: 60px;
    color: #FFFFFF;
}

.blog-full-slider-area .lSSlideOuter .lSAction a.lSNext {
    right: 60px;
    left: auto;
}

.blog-full-slider-area .lSSlideOuter .lSPager {
    margin: 0;
    position: absolute;
    width: 100%;
    bottom: 80px;
}

@media only screen and (max-width: 767.98px) {
    .blog-full-slider-area .lSSlideOuter .lSPager {
        bottom: 30px;
    }
}

.blog-full-slider-area .lSSlideOuter .lSPager li {
    margin-right: 0;
}

/*====================================
29. Home Modern Shop Style CSS
=====================================*/
.category-item {
    margin-top: 30px;
    overflow: hidden;
    position: relative;
}

.category-item img {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    width: 100%;
}

.category-item:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.category-item__text {
    font-size: 2.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #FFFFFF;
    position: absolute;
    left: 50px;
    bottom: 50px;
    margin-bottom: 0;
    width: 100%;
}

@media (max-width: 1200px) {
    .category-item__text {
        font-size: calc(1.375rem + 1.5vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .category-item__text {
        left: 20px;
    }
}

.feature-product-area .lSSlideOuter .lSPager li {
    padding: 0;
}

/*==========================================
31. Home Justified Gallery Style CSS
===========================================*/
.gallery-item {
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.gallery-item:before {
    background-color: rgba(24, 33, 65, 0.8);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    pointer-events: none;
    z-index: 2;
}

.gallery-item:after {
    content: '+';
    color: #FFFFFF;
    font-size: 2.1875rem;
    line-height: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    transition: 0.4s;
    z-index: 3;
}

@media (max-width: 1200px) {
    .gallery-item:after {
        font-size: calc(1.34375rem + 1.125vw);
    }
}

.gallery-item:hover:before {
    opacity: 1;
    visibility: visible;
}

.gallery-item:hover:after {
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
}

.gallery-item img {
    width: 100%;
}

/*==========================
Home vCard Style CSS
============================*/
.vCard-content {
    height: 100vh;
    width: 100vw;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .vCard-content {
        height: auto;
    }
}

.vCard-content__left,
.vCard-content__right {
    max-width: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    height: 100%;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {

    .vCard-content__left,
    .vCard-content__right {
        max-width: 100%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .vCard-content__left {
        height: 50vh;
    }
}

@media only screen and (max-width: 767.98px) {
    .vCard-content__left {
        height: 300px;
    }
}

.vCard-content__right {
    background-color: #070c1f;
    overflow-y: auto;
    padding: 100px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .vCard-content__right {
        padding: 100px 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .vCard-content__right {
        padding: 60px 15px;
    }
}

.vCard-content__right .vCard-content-author {
    margin-top: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .vCard-content__right .vCard-content-author {
        margin-top: 0;
    }
}

.vCard-content__right .vCard-content-author h2 {
    color: #f16521;
    font-family: "Playfair Display", serif;
    font-size: 6.875rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .vCard-content__right .vCard-content-author h2 {
        font-size: calc(1.8125rem + 6.75vw);
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .vCard-content__right .vCard-content-author h2 {
        font-size: 5rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) and (max-width: 1200px),
only screen and (min-width: 1200px) and (max-width: 1599.98px) and (max-width: 1200px) {
    .vCard-content__right .vCard-content-author h2 {
        font-size: calc(1.625rem + 4.5vw);
    }
}

.vCard-content__right .vCard-content-author .author-content-item {
    margin-top: 55px;
}

.vCard-content__right .vCard-content-author .author-content-item h5 {
    color: #f16521;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
}

.vCard-content__right .vCard-content-author .author-content-item .btn-bordered:not(:hover) {
    color: #f16521;
}

.vCard-content__right .vCard-content-author .author-content-item .btn-bordered i {
    padding-right: 5px;
}

.vCard-content__right .vCard-content-author .author-content-item .btn-bottom {
    color: #FFFFFF;
}

.vCard-content__right .vCard-content-author .author-content-item .contact-info {
    word-break: break-all;
}

.vCard-content__right .vCard-content-author .author-content-item .contact-info h3 {
    font-size: 3.5rem;
    color: #FFFFFF;
}

@media (max-width: 1200px) {
    .vCard-content__right .vCard-content-author .author-content-item .contact-info h3 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .vCard-content__right .vCard-content-author .author-content-item .contact-info h3 {
        font-size: 2.5rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) and (max-width: 1200px) {
    .vCard-content__right .vCard-content-author .author-content-item .contact-info h3 {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.recent-works-list li a {
    font-size: 2rem;
    line-height: 1.2;
    color: #FFFFFF;
}

@media (max-width: 1200px) {
    .recent-works-list li a {
        font-size: calc(1.325rem + 0.9vw);
    }
}

.recent-works-list li a:hover {
    color: #f16521;
}

/*==============================
38. Home Wedding Style Css
================================*/
.hero-area-wrapper {
    height: 100vh;
}

.hero-area-wrapper .wedding-hero-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #FFFFFF;
    margin-top: 50px;
}

.hero-area-wrapper .wedding-hero-content-wrap h5 {
    color: #FFFFFF;
    font-size: 1.75rem;
    margin-bottom: 45px;
}

@media (max-width: 1200px) {
    .hero-area-wrapper .wedding-hero-content-wrap h5 {
        font-size: calc(1.3rem + 0.6vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .hero-area-wrapper .wedding-hero-content-wrap h5 {
        margin-bottom: 20px;
    }
}

.hero-area-wrapper .wedding-hero-content-wrap h2 {
    color: #FFFFFF;
    font-size: 6.25rem;
    font-family: "Playfair Display", serif;
    margin-bottom: 46px;
}

@media (max-width: 1200px) {
    .hero-area-wrapper .wedding-hero-content-wrap h2 {
        font-size: calc(1.75rem + 6vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .hero-area-wrapper .wedding-hero-content-wrap h2 {
        margin-bottom: 20px;
    }
}

.hero-area-wrapper .wedding-hero-content-wrap .heart-icon {
    font-size: 4.375rem;
    line-height: 1;
    color: #FFFFFF;
}

@media (max-width: 1200px) {
    .hero-area-wrapper .wedding-hero-content-wrap .heart-icon {
        font-size: calc(1.5625rem + 3.75vw);
    }
}

.wedding-about-icon {
    margin-top: -160px;
    margin-bottom: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599.98px) {
    .wedding-about-icon {
        margin-top: -70px;
        margin-bottom: 60px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .wedding-about-icon {
        margin-top: 60px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .wedding-about-icon {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767.98px) {
    .wedding-about-icon {
        margin-top: 5px;
        margin-bottom: 0;
    }
}

.wedding-about-content h2 {
    color: #f16521;
    font-size: 3.125rem;
    font-family: "Playfair Display", serif;
    margin-bottom: 32px;
}

@media (max-width: 1200px) {
    .wedding-about-content h2 {
        font-size: calc(1.4375rem + 2.25vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .wedding-about-content h2 {
        margin-bottom: 25px;
    }
}

.wedding-about-content .heart-icon {
    font-size: 2.5rem;
    line-height: 1;
    opacity: 0.3;
}

@media (max-width: 1200px) {
    .wedding-about-content .heart-icon {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.banner-quote-area {
    height: 500px;
    color: #FFFFFF;
    font-size: 2rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .banner-quote-area {
        font-size: calc(1.325rem + 0.9vw);
    }
}

@media only screen and (max-width: 767.98px) {
    .banner-quote-area {
        height: 350px;
    }
}

.banner-quote-area .quote-title {
    font-size: 1rem;
    color: #f16521;
    font-weight: 700;
    letter-spacing: 2px;
}

.ceremony-date-content {
    background-image: url("../img/wedding/round-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 600px;
    height: 600px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: auto auto 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .ceremony-date-content {
        width: 450px;
        height: 450px;
        margin: auto auto 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .ceremony-date-content {
        width: 300px;
        height: 300px;
        margin: auto auto 30px;
    }
}

.ceremony-date-content h2 {
    font-size: 3.125rem;
    font-family: "Playfair Display", serif;
    color: #f16521;
    margin-bottom: 10px;
}

@media (max-width: 1200px) {
    .ceremony-date-content h2 {
        font-size: calc(1.4375rem + 2.25vw);
    }
}

.ceremony-date-content h5 {
    color: #878c9b;
    margin: 20px 0 0;
}

/*=======================
Countdown Clock Style
=========================*/
.ht-countdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.ht-countdown .countdown-item {
    text-align: center;
    position: relative;
    margin-right: 48px;
    padding-right: 50px;
}

@media only screen and (max-width: 575.98px) {
    .ht-countdown .countdown-item {
        padding-right: 15px;
        margin-right: 10px;
    }
}

.ht-countdown .countdown-item:after {
    color: #5f362e;
    font-size: 1.875rem;
    content: ':';
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media (max-width: 1200px) {
    .ht-countdown .countdown-item:after {
        font-size: calc(1.3125rem + 0.75vw);
    }
}

.ht-countdown .countdown-item:last-child {
    padding-right: 0;
    margin-right: 0;
}

.ht-countdown .countdown-item:last-child:after {
    display: none;
}

.ht-countdown .countdown-item__time {
    color: #5f362e;
    font-size: 3.5rem;
    line-height: 1;
}

@media (max-width: 1200px) {
    .ht-countdown .countdown-item__time {
        font-size: calc(1.475rem + 2.7vw);
    }
}

.ht-countdown .countdown-item__label {
    display: block;
}

.ht-countdown--light .countdown-item:after {
    color: #FFFFFF;
}

.ht-countdown--light .countdown-item__time {
    color: #f16521;
}

.ht-countdown--light .countdown-item__label {
    color: rgba(255, 255, 255, 0.6);
}

.ceremony-events-wrap {
    margin-top: 75px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .ceremony-events-wrap {
        margin-top: 55px;
    }
}

@media only screen and (max-width: 767.98px) {
    .ceremony-events-wrap {
        margin-top: 35px;
    }
}

.ceremony-events-wrap .event-heart-icon {
    color: #f16521;
    font-size: 2.5rem;
    line-height: 1;
}

@media (max-width: 1200px) {
    .ceremony-events-wrap .event-heart-icon {
        font-size: calc(1.375rem + 1.5vw);
    }
}

.ceremony-event-item h4 {
    color: #f16521;
}

.ceremony-event-item .event-time {
    font-size: 1rem;
    opacity: 0.5;
    margin-bottom: 20px;
}

.ceremony-rsvp-area h2 {
    color: #FFFFFF;
    font-family: "Playfair Display", serif;
    font-size: 3.5625rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .ceremony-rsvp-area h2 {
        font-size: calc(1.48125rem + 2.775vw);
    }
}

.rsvp-form-wrap {
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 90px 275px 100px 70px;
    position: relative;
    margin-right: 100px;
    z-index: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .rsvp-form-wrap {
        padding: 80px 225px 90px 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .rsvp-form-wrap {
        padding: 50px;
        margin-right: 0;
    }
}

.rsvp-form-wrap:before {
    background-image: url("../img/wedding/rsvp-frame.png");
    background-repeat: no-repeat;
    background-position: right center;
    content: '';
    position: absolute;
    top: 50%;
    right: -100px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    height: 100%;
    width: 100%;
    z-index: -1;
}

.wedding-gallery-content {
    margin-top: -30px;
}

.wedding-gallery-content .gallery-item {
    margin-top: 30px;
}

.section-title.wedding h2 {
    color: #f16521;
    font-family: "Playfair Display", serif;
    font-size: 3.125rem;
    margin-top: -10px;
}

@media (max-width: 1200px) {
    .section-title.wedding h2 {
        font-size: calc(1.4375rem + 2.25vw);
    }
}

/*===============================
57. Home Freelancer Style Css
================================*/
@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .resume-content-wrap .tab-nav--2 li a {
        padding-right: 40px;
        padding-left: 40px;
    }
}

.resume-content-wrap .tab-nav--2 li a.active {
    background-color: #f16521;
}

.resume-content-wrap .tab-nav--2 li a:before {
    display: none;
}

.resume-content-wrap .tab-content {
    margin-top: 60px;
}

/*==============================
18. Contact Content Style
==============================*/
.contact-content-wrap h2 {
    font-size: 3.5rem;
    margin-bottom: 0;
}

@media (max-width: 1200px) {
    .contact-content-wrap h2 {
        font-size: calc(1.475rem + 2.7vw);
    }
}

.call-to-contact {
    margin-top: 35px;
}

@media only screen and (max-width: 767.98px) {
    .call-to-contact {
        margin-top: 30px;
    }
}

.call-to-contact h5 {
    color: #f16521;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1;
    letter-spacing: 1.5px;
    margin-bottom: 27px;
}

@media only screen and (max-width: 767.98px) {
    .call-to-contact h5 {
        margin-bottom: 15px;
    }
}

.call-to-contact--light h4 {
    color: #FFFFFF;
    line-height: 1.6;
}

.form-input-item {
    margin-top: 70px;
}

@media only screen and (max-width: 767.98px) {
    .form-input-item {
        margin-top: 30px;
    }
}

.form-input-item input,
.form-input-item textarea {
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #5f362e;
    color: #5f362e;
    display: block;
    font-size: 1.5rem;
    width: 100%;
}

@media (max-width: 1200px) {

    .form-input-item input,
    .form-input-item textarea {
        font-size: calc(1.275rem + 0.3vw);
    }
}

@media only screen and (max-width: 767.98px) {

    .form-input-item input,
    .form-input-item textarea {
        font-size: 1.125rem;
    }
}

.form-input-item input::-webkit-input-placeholder,
.form-input-item textarea::-webkit-input-placeholder {
    color: #5f362e;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.form-input-item input::-moz-placeholder,
.form-input-item textarea::-moz-placeholder {
    color: #5f362e;
    -moz-transition: 0.4s;
    transition: 0.4s;
}

.form-input-item input:-ms-input-placeholder,
.form-input-item textarea:-ms-input-placeholder {
    color: #5f362e;
    -ms-transition: 0.4s;
    transition: 0.4s;
}

.form-input-item input::-ms-input-placeholder,
.form-input-item textarea::-ms-input-placeholder {
    color: #5f362e;
    -ms-transition: 0.4s;
    transition: 0.4s;
}

.form-input-item input::placeholder,
.form-input-item textarea::placeholder {
    color: #5f362e;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.form-input-item input:focus,
.form-input-item textarea:focus {
    border-bottom-color: #f16521;
}

.form-input-item input:focus::-webkit-input-placeholder,
.form-input-item textarea:focus::-webkit-input-placeholder {
    opacity: 0;
}

.form-input-item input:focus::-moz-placeholder,
.form-input-item textarea:focus::-moz-placeholder {
    opacity: 0;
}

.form-input-item input:focus:-ms-input-placeholder,
.form-input-item textarea:focus:-ms-input-placeholder {
    opacity: 0;
}

.form-input-item input:focus::-ms-input-placeholder,
.form-input-item textarea:focus::-ms-input-placeholder {
    opacity: 0;
}

.form-input-item input:focus::placeholder,
.form-input-item textarea:focus::placeholder {
    opacity: 0;
}

.form-input-item textarea {
    padding: 0;
    resize: none;
}

.form-input-item .btn-bordered {
    padding: 18px 10px;
    width: 100%;
}

.form-input-item.light input,
.form-input-item.light textarea {
    color: #f16521;
}

.form-input-item.light input::-webkit-input-placeholder,
.form-input-item.light textarea::-webkit-input-placeholder {
    color: #f16521;
}

.form-input-item.light input::-moz-placeholder,
.form-input-item.light textarea::-moz-placeholder {
    color: #f16521;
}

.form-input-item.light input:-ms-input-placeholder,
.form-input-item.light textarea:-ms-input-placeholder {
    color: #f16521;
}

.form-input-item.light input::-ms-input-placeholder,
.form-input-item.light textarea::-ms-input-placeholder {
    color: #f16521;
}

.form-input-item.light input::placeholder,
.form-input-item.light textarea::placeholder {
    color: #f16521;
}

.form-input-item.light input:focus,
.form-input-item.light textarea:focus {
    border-bottom-color: #FFFFFF;
}

.form-input-item.light .btn-bordered {
    color: #f16521;
}

.form-input-item.light .btn-bordered:hover {
    color: #FFFFFF;
}

.form-input-item.classic {
    margin-top: 35px;
}

.form-input-item.classic input,
.form-input-item.classic textarea {
    background-color: #EEEEEE;
    border-bottom: 0;
    padding: 10px;
}

.form-input-item.classic .btn-bordered {
    padding: 20px 40px;
    width: auto;
}

.requested-thumb {
    height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .requested-thumb {
        height: 400px;
    }
}

.requested-content-inner {
    max-width: 650px;
    margin: auto;
}

@media only screen and (max-width: 767.98px) {
    .requested-content-inner {
        padding: 0 30px;
    }
}

.requested-content-header h4 {
    font-weight: 700;
    color: #f16521;
}

.requested-form .form-input-item input {
    color: #FFFFFF;
    font-size: 1.125rem;
    padding-bottom: 10px;
}

.requested-form .form-input-item input::-webkit-input-placeholder {
    color: #FFFFFF;
}

.requested-form .form-input-item input::-moz-placeholder {
    color: #FFFFFF;
}

.requested-form .form-input-item input:-ms-input-placeholder {
    color: #FFFFFF;
}

.requested-form .form-input-item input::-ms-input-placeholder {
    color: #FFFFFF;
}

.requested-form .form-input-item input::placeholder {
    color: #FFFFFF;
}

.requested-form .btn-bordered {
    color: #FFFFFF;
    margin-top: 40px;
}

.requested-form .nice-select {
    color: #FFFFFF;
    font-size: 1.125rem;
}

.requested-form .nice-select:after {
    border-right-color: #FFFFFF;
    border-bottom-color: #FFFFFF;
}

.finance-map-area {
    height: 450px;
    width: 100%;
}

.finance-map-area .leaflet-control-zoom {
    display: none;
}

.contact-page-map {
    height: 500px;
}

@media only screen and (max-width: 767.98px) {
    .contact-page-map {
        height: 300px;
    }
}

.contact-page-2-content-area {
    position: relative;
    padding: 165px 0 120px;
}

@media only screen and (max-width: 767.98px) {
    .contact-page-2-content-area {
        padding: 100px 0 90px;
    }
}

@media screen and (min-height: 750px) {
    .contact-page-2-content-area {
        height: 100vh;
        padding: 50px 0 0;
    }
}

.contact-page-2-content-area:before {
    background-color: rgba(24, 33, 65, 0.9);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

/*============================
41. About Page Style Css
==============================*/
.about-page-feature-content {
    background-repeat: no-repeat;
    background-position: 10% center;
}

@media screen and (min-width: 1601px) {
    .about-page-content {
        padding-right: 350px;
    }
}

.about-page-content h3 {
    font-size: 3rem;
    margin-bottom: 20px;
}

@media (max-width: 1200px) {
    .about-page-content h3 {
        font-size: calc(1.425rem + 2.1vw);
    }
}

.about-page-2-feature-area {
    position: relative;
}

.about-page-2-feature-area:before {
    background-color: rgba(24, 33, 65, 0.95);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.about-me-hero-content {
    height: 720px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #FFFFFF;
}

@media only screen and (max-width: 767.98px) {
    .about-me-hero-content {
        height: 500px;
    }
}

.about-me-hero-content h5 {
    color: #f16521;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 1.5px;
}

.about-me-hero-content h2 {
    color: #FFFFFF;
    font-size: 90px;
    font-weight: 700;
    line-height: 1;
    margin: 15px 0 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-me-hero-content h2 {
        font-size: 80px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-me-hero-content h2 {
        font-size: 45px;
    }
}

.about-me-service .icon-box-item .icon-box__txt h6 {
    margin-bottom: 0;
}

@media only screen and (max-width: 479.98px) {
    .about-me-service .icon-box-item .icon-box__txt h6 {
        font-size: 16px;
    }
}

.about-personal-skill-area .personal-skill-left {
    padding: 150px 50px;
    height: 100%;
}

@media screen and (min-width: 1500px) {
    .about-personal-skill-area .personal-skill-left {
        padding: 170px 120px 170px 365px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-personal-skill-area .personal-skill-left {
        padding: 90px 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-personal-skill-area .personal-skill-left {
        padding: 60px 30px;
    }
}

.about-personal-skill-area .personal-skill-right {
    padding: 150px 50px;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media screen and (min-width: 1500px) {
    .about-personal-skill-area .personal-skill-right {
        padding: 170px 365px 170px 120px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .about-personal-skill-area .personal-skill-right {
        padding: 55px 50px 95px;
    }
}

@media only screen and (max-width: 767.98px) {
    .about-personal-skill-area .personal-skill-right {
        padding: 35px 30px 65px;
    }
}

/*===================================
44. 404 Page Not Found Page Style Css
====================================*/
.page-not-found-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 120px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .page-not-found-content {
        padding: 90px 0;
    }
}

@media screen and (min-height: 750px) {
    .page-not-found-content {
        height: 100vh;
        padding: 0;
    }
}

.page-not-found-content .logo {
    max-width: 120px;
    display: inline-block;
    margin-bottom: 90px;
}

@media only screen and (max-width: 767.98px) {
    .page-not-found-content .logo {
        margin-bottom: 50px;
    }
}

.page-not-found-content .page-not-found-txt {
    margin-top: 40px;
}

.page-not-found-content .page-not-found-txt h4 {
    font-size: 2.25rem;
}

@media (max-width: 1200px) {
    .page-not-found-content .page-not-found-txt h4 {
        font-size: calc(1.35rem + 1.2vw);
    }
}

.page-not-found-content .page-not-found-txt .page-not-found-buttons {
    margin-top: 40px;
}

.page-not-found-content .page-not-found-txt .page-not-found-buttons a {
    display: inline-block;
    padding: 18px 40px;
    margin-right: 20px;
}

@media only screen and (max-width: 575.98px) {
    .page-not-found-content .page-not-found-txt .page-not-found-buttons a {
        padding: 15px 25px;
        margin-right: 10px;
    }
}

.page-not-found-content .page-not-found-txt .page-not-found-buttons a:last-child {
    margin-right: 0;
}

/*============================
45. Maintenance Page Style Css
==============================*/
.maintenance-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 60px 0;
}

@media screen and (min-height: 500px) {
    .maintenance-content-wrap {
        height: 100vh;
        padding: 0;
    }
}

.maintenance-header .logo {
    max-width: 120px;
    display: inline-block;
}

.maintenance-body .maintenance-txt {
    margin-top: 90px;
    color: #FFFFFF;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .maintenance-body .maintenance-txt {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .maintenance-body .maintenance-txt {
        margin-top: 40px;
    }
}

.maintenance-body .maintenance-txt h3 {
    color: #f16521;
    margin-bottom: 21px;
}

.maintenance-footer .social-icons a {
    color: #FFFFFF;
}

.maintenance-footer .social-icons a:hover {
    color: #f16521;
}

.maintenance-progress .progress-info {
    color: #FFFFFF;
    font-size: 20px;
    line-height: 1;
}

/*====================================
46. Coming Soon Page Style Css
======================================*/
.coming-soon-content-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 185px 0 100px;
}

@media only screen and (max-width: 767.98px) {
    .coming-soon-content-wrap {
        padding: 135px 0 60px;
    }
}

@media screen and (min-height: 850px) {
    .coming-soon-content-wrap {
        height: 100vh;
        padding: 0;
    }
}

.coming-soon-header {
    position: absolute;
    top: 80px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

@media only screen and (max-width: 767.98px) {
    .coming-soon-header {
        top: 60px;
    }
}

.coming-soon-header .logo {
    max-width: 120px;
    display: inline-block;
}

.coming-soon-body .event-countdown-wrap {
    padding-top: 0;
    margin-top: 0;
    margin-bottom: 70px;
}

@media only screen and (max-width: 767.98px) {
    .coming-soon-body .event-countdown-wrap {
        margin-bottom: 50px;
    }
}

.coming-soon-body .event-countdown-wrap:before {
    display: none;
}

.coming-soon-body .event-countdown-wrap .countdown-item:after {
    color: #FFFFFF;
}

.coming-soon-body .coming-soon-txt {
    color: #FFFFFF;
}

.coming-soon-body .coming-soon-txt h3 {
    color: #FFFFFF;
    margin-bottom: 22px;
}

.coming-soon-body .newsletter-form-wrap {
    margin-top: -15px !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 1200px) {
    .coming-soon-body .newsletter-form-wrap {
        max-width: 520px;
        margin: auto;
    }
}

.coming-soon-body .newsletter-form-wrap .form-input-item input {
    text-align: center;
    color: #FFFFFF;
}

.coming-soon-body .newsletter-form-wrap .form-input-item input::-webkit-input-placeholder {
    color: #f16521;
}

.coming-soon-body .newsletter-form-wrap .form-input-item input::-moz-placeholder {
    color: #f16521;
}

.coming-soon-body .newsletter-form-wrap .form-input-item input:-ms-input-placeholder {
    color: #f16521;
}

.coming-soon-body .newsletter-form-wrap .form-input-item input::-ms-input-placeholder {
    color: #f16521;
}

.coming-soon-body .newsletter-form-wrap .form-input-item input::placeholder {
    color: #f16521;
}

.coming-soon-body .newsletter-form-wrap .form-input-item .btn-bordered {
    color: #FFFFFF;
    width: auto;
    padding-left: 40px;
    padding-right: 40px;
}

.coming-soon-body .newsletter-form-wrap .form-input-item:last-child {
    margin-top: 30px;
}

/*============================
48. Blog Pages Style Css
=============================*/
.pagination-wrap {
    margin-top: 60px;
}

@media only screen and (max-width: 767.98px) {
    .pagination-wrap {
        margin-top: 40px;
    }
}

.pagination li {
    margin-right: 10px;
}

.pagination li:last-child {
    margin-right: 0;
}

.pagination li a {
    background-color: #FFFFFF;
    border: 1px solid #eee;
    color: #5f362e;
    display: block;
    text-align: center;
    line-height: 45px;
    height: 45px;
    width: 45px;
}

.pagination li a.active,
.pagination li a:hover {
    border-color: #f16521;
}

/*====================================
50. Blog Details Page Style Css
=====================================*/
.blog-post-details-content {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 50px 30px 52px;
}

.blog-post-details-header {
    margin-bottom: 32px;
}

.blog-post-details-header .post-info__meta {
    margin-bottom: 0;
}

.blog-post-details-body .post-thumbnail {
    margin-bottom: 32px;
}

.blog-post-details-footer {
    border-top: 1px solid #eee;
    padding-top: 25px;
    margin-top: 52px;
}

.blog-post-details-footer .post-tags span {
    color: #f16521;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    margin-right: 15px;
}

.blog-post-details-footer .post-tags a {
    line-height: 1;
    color: #878c9b;
    margin-right: 10px;
}

.blog-post-details-footer .post-tags a:last-child {
    margin-right: 0;
}

.blog-post-details-footer .post-tags a:last-child:after {
    display: none;
}

.blog-post-details-footer .post-tags a:after {
    content: ',';
}

.blog-post-details-footer .post-tags a:hover {
    color: #f16521;
}

.share-btn a {
    font-size: 15px;
    line-height: 1;
    color: #878c9b;
    margin-right: 15px;
}

.share-btn a:last-child {
    margin-right: 0;
}

.share-btn a:hover {
    color: #f16521;
}

.author-wrap {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 45px 30px 43px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 479.98px) {
    .author-wrap {
        display: block;
    }
}

.author-wrap .author-pic {
    border-radius: 50%;
    -webkit-flex-basis: 110px;
    -ms-flex-preferred-size: 110px;
    flex-basis: 110px;
    max-width: 110px;
    height: 110px;
    margin-right: 30px;
    overflow: hidden;
}

@media only screen and (max-width: 479.98px) {
    .author-wrap .author-pic {
        display: block;
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.author-wrap .author-info {
    -webkit-flex-basis: calc(100% - 140px);
    -ms-flex-preferred-size: calc(100% - 140px);
    flex-basis: calc(100% - 140px);
    font-size: 16px;
}

.author-wrap .author-info h6 {
    margin-bottom: 10px;
}

.related-post-area {
    margin-top: 60px;
}

.related-post-area>h4 {
    text-align: center;
    margin-bottom: 32px;
}

.related-post-area .post-item {
    margin: 0;
    padding: 40px 30px;
}

.related-post-area .post-item:hover .post-info__title a {
    color: inherit;
}

.comment-view-area {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 50px 30px 52px;
}

.comment-view-area .single-comment {
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 50px;
    padding-bottom: 50px;
}

.comment-view-area .single-comment:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.comment-view-area .single-comment.reply-comment {
    padding-left: 100px;
}

@media only screen and (max-width: 767.98px) {
    .comment-view-area .single-comment.reply-comment {
        padding-left: 30px;
    }
}

.comment-view-area .single-comment .author-wrap {
    padding: 0;
    border: none;
}

.comment-view-area .single-comment .author-wrap .comment-mata .btn-reply {
    color: #878c9b;
    font-weight: 600;
}

.comment-view-area .single-comment .author-wrap .comment-mata .btn-reply:hover {
    color: #f16521;
}

.write-comment-area {
    background-color: #FFFFFF;
    border: 1px solid #EEEEEE;
    padding: 50px 30px 52px;
}

.write-comment-area .form-input-item input,
.write-comment-area .form-input-item textarea {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    font-size: 16px;
    padding-bottom: 10px;
}

.write-comment-area .form-input-item input:focus,
.write-comment-area .form-input-item textarea:focus {
    border-bottom-color: #5f362e;
}

.write-comment-area .form-input-item input::-webkit-input-placeholder,
.write-comment-area .form-input-item textarea::-webkit-input-placeholder {
    color: #878c9b;
}

.write-comment-area .form-input-item input::-moz-placeholder,
.write-comment-area .form-input-item textarea::-moz-placeholder {
    color: #878c9b;
}

.write-comment-area .form-input-item input:-ms-input-placeholder,
.write-comment-area .form-input-item textarea:-ms-input-placeholder {
    color: #878c9b;
}

.write-comment-area .form-input-item input::-ms-input-placeholder,
.write-comment-area .form-input-item textarea::-ms-input-placeholder {
    color: #878c9b;
}

.write-comment-area .form-input-item input::placeholder,
.write-comment-area .form-input-item textarea::placeholder {
    color: #878c9b;
}

/*======================================
51. Portfolio Details Page Style Css
=======================================*/
.portfolio-details-info h4 {
    margin-bottom: 24px;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-info h4 {
        margin-bottom: 14px;
    }
}

.portfolio-details-info .btn {
    margin-top: 10px;
}

.portfolio-details-meta {
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 50px 0;
    margin-top: 60px;
}

.portfolio-details-meta .portfolio-meta-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: -30px;
}

.portfolio-details-meta .portfolio-meta-list li {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
    margin-top: 30px;
}

.portfolio-details-meta .portfolio-meta-list li label {
    color: #5f362e;
    display: block;
    font-size: 20px;
    line-height: 1;
}

.portfolio-details-meta .portfolio-meta-list li a {
    color: #878c9b;
}

.portfolio-details-meta .portfolio-meta-list li a:hover {
    color: #f16521;
}

.portfolio-details-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 25px;
}

.portfolio-details-footer .social-icons a {
    color: #878c9b;
    font-size: 16px;
    line-height: 1;
}

.portfolio-details-footer .social-icons a:hover {
    color: #f16521;
}

.portfolio-details-footer .portfolio-like-view span {
    margin-right: 10px;
    display: inline-block;
}

.portfolio-details-footer .portfolio-like-view span:last-child {
    margin-right: 0;
}

.portfolio-details-footer .portfolio-like-view i {
    margin-right: 5px;
    color: #5f362e;
}

.portfolio-details-footer .portfolio-like-view i.fa-heart-o {
    font-size: 16px;
    color: #ff0000;
}

@media only screen and (min-width: 1200px) {
    .portfolio-details-thumb {
        padding-left: 30px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-details-thumb {
        margin-bottom: 40px;
    }
}

@media only screen and (min-width: 1200px) {
    .portfolio-details-thumb--left {
        padding-left: 0;
        padding-right: 30px;
    }
}

.portfolio-details-thumb-item {
    margin-bottom: 30px;
}

.portfolio-details-thumb-item:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 575.98px) {
    .portfolio-details-thumb .gallery-item {
        margin-bottom: 30px;
    }
}

.portfolio-details-img-slider .portfolio-details-thumb {
    margin-bottom: 60px;
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-img-slider .portfolio-details-thumb {
        margin-bottom: 35px;
    }
}

.portfolio-details-img-slider .portfolio-details-thumb .lSSlideOuter .lSAction a {
    left: 50px;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-img-slider .portfolio-details-thumb .lSSlideOuter .lSAction a {
        line-height: 50px;
        height: 50px;
        width: 50px;
        left: 20px;
    }
}

.portfolio-details-img-slider .portfolio-details-thumb .lSSlideOuter .lSAction a.lSNext {
    left: auto;
    right: 50px;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-img-slider .portfolio-details-thumb .lSSlideOuter .lSAction a.lSNext {
        right: 20px;
    }
}

@media only screen and (min-width: 1200px) {
    .portfolio-details-img-slider .portfolio-details-info {
        padding-right: 30px;
    }
}

.portfolio-details-img-slider .portfolio-details-meta {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-img-slider .portfolio-details-meta {
        margin-top: 50px;
    }
}

.portfolio-details-creative-wrap .port-details-creative-item {
    height: 100vh;
}

.portfolio-details-creative-wrap .lSSlideOuter .lSAction {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
}

.portfolio-details-creative-wrap .lSSlideOuter .lSAction a {
    border: none;
    border-radius: 0;
    border-right: 1px solid #eee;
    display: block;
    font-size: 30px;
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.portfolio-details-creative-wrap .lSSlideOuter .lSAction a:last-child {
    border-right: 0;
}

.portfolio-details-creative-wrap .portfolio-details-creative-content {
    background-color: #FFFFFF;
    position: fixed;
    left: -50%;
    top: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    height: 100%;
    width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content {
        width: 100%;
        left: -100%;
    }
}

.portfolio-details-creative-wrap .portfolio-details-creative-content.show {
    left: 0;
    overflow-y: auto;
}

.portfolio-details-creative-wrap .portfolio-details-creative-content.show .portfolio-details-creative-action {
    right: 0;
    left: calc(50% - 90px);
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content.show .portfolio-details-creative-action {
        left: calc(50% - 70px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content.show .portfolio-details-creative-action {
        left: calc(100% - 70px);
    }
}

.portfolio-details-creative-wrap .portfolio-details-creative-content.show .portfolio-details-creative-action .btn-content i.close-icon {
    display: inline-block;
}

.portfolio-details-creative-wrap .portfolio-details-creative-content.show .portfolio-details-creative-action .btn-content i.open-icon {
    display: none;
}

.portfolio-details-creative-wrap .portfolio-details-creative-content-inner {
    padding: 120px 110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content-inner {
        padding: 90px 50px;
    }
}

@media only screen and (max-width: 767.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content-inner {
        padding: 50px 15px;
    }
}

.portfolio-details-creative-wrap .portfolio-details-creative-content .portfolio-details-creative-action {
    background-color: #FFFFFF;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: fixed;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    left: 0;
    height: 270px;
    width: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .portfolio-details-creative-wrap .portfolio-details-creative-content .portfolio-details-creative-action {
        width: 70px;
        height: 200px;
    }
}

.portfolio-details-creative-wrap .portfolio-details-creative-content .portfolio-details-creative-action .btn-content {
    color: #5f362e;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    white-space: nowrap;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
    transform: translateX(-50%) translateY(-50%) rotate(90deg);
}

.portfolio-details-creative-wrap .portfolio-details-creative-content .portfolio-details-creative-action .btn-content i {
    margin-right: 5px;
}

.portfolio-details-creative-wrap .portfolio-details-creative-content .portfolio-details-creative-action .btn-content i.close-icon {
    display: none;
}

/*=============================
52. Shop Pages Style Css
==============================*/
.category-single-item {
    position: relative;
    margin-top: 20px;
}

.category-single-item .category-info {
    background-color: rgba(255, 255, 255, 0.65);
    position: absolute;
    left: 0;
    bottom: 30px;
    padding: 15px;
    text-transform: uppercase;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    width: 100%;
}

.category-single-item .category-info h6 {
    margin-bottom: 5px;
}

.category-single-item .category-info .prod-count {
    color: #5f362e;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 0;
}

.category-single-item:hover .category-info {
    background-color: #5f362e;
}

.category-single-item:hover .category-info * {
    color: #FFFFFF;
}

.shop-content-shorting {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 50px 0 45px;
}

@media only screen and (max-width: 575.98px) {
    .shop-content-shorting {
        display: block;
    }
}

@media only screen and (max-width: 575.98px) {
    .shop-content-shorting .product-sorting {
        margin-top: 15px;
    }
}

.shop-content-shorting .product-sorting .nice-select {
    color: #878c9b;
    border: 1px solid #eee;
    font-size: 16px;
    padding-left: 50px;
    padding-right: 60px;
    height: 50px;
    line-height: 46px;
}

.shop-content-shorting .product-sorting .nice-select:after {
    border-right-color: #878c9b;
    border-bottom-color: #878c9b;
}

.shop-content-products .product-item {
    margin-top: 30px;
}

/*==========================================
53. Single Product Pages Style Css
===========================================*/
@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-thumb-area {
        margin-bottom: 50px;
    }
}

.product-thumb-area .lSSlideOuter .lSAction a {
    left: 30px;
}

.product-thumb-area .lSSlideOuter .lSAction a.lSNext {
    left: auto;
    right: 30px;
}

.product-thumb-area .lSSlideOuter .lSPager {
    margin-top: 30px;
}

@media only screen and (min-width: 1200px) {
    .prod-details-info-content {
        padding-left: 30px;
    }
}

.prod-details-info-content .price-group {
    color: #ff0000;
    font-size: 30px;
    margin-bottom: 10px;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .prod-details-info-content .price-group {
        font-size: 20px;
    }
}

.prod-details-info-content .price-group del {
    margin-right: 10px;
    color: #878c9b;
}

.prod-details-info-content p:last-child {
    margin-bottom: 0;
}

.config-item {
    margin-bottom: 20px;
}

.config-item:last-child {
    margin-bottom: 0;
}

.config-item .config-name {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 13px;
}

.config-item .config-name b {
    text-transform: capitalize;
    margin-left: 5px;
    font-size: 16px;
    line-height: 1;
    font-weight: 400;
}

.config-item .config-list li {
    border: 1px solid #D4D4D4;
    cursor: pointer;
    color: #8d8d8d;
    height: 40px;
    padding: 3px;
    font-size: 14px;
    line-height: 1;
    display: block;
    text-align: center;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    margin-right: 5px;
    overflow: hidden;
    outline: none;
    width: 40px;
}

.config-item .config-list li.active,
.config-item .config-list li:hover {
    border-color: #f16521;
    color: #222222;
    font-weight: 600;
}

.config-item .config-list li span {
    line-height: 30px;
    display: block;
}

.prod-details-footer__item {
    border-bottom: 1px solid #f16521;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 12px 0;
}

.prod-details-footer__item:first-child {
    border-top: 1px solid #f16521;
}

.prod-details-footer__item h5 {
    font-size: 15px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

@media only screen and (max-width: 575.98px) {
    .prod-details-footer__item h5 {
        font-size: 12px;
    }
}

.prod-details-footer__item h5 i {
    color: #888888;
    margin-right: 10px;
}

@media only screen and (max-width: 575.98px) {
    .prod-details-footer__item h5 i {
        margin-right: 3px;
    }
}

.prod-details-footer__item .cate-list li {
    margin-right: 10px;
}

.prod-details-footer__item .cate-list li:last-child {
    margin-right: 0;
}

.prod-details-footer__item .cate-list li a {
    color: #878c9b;
    font-size: 15px;
    font-weight: 600;
}

.prod-details-footer__item .cate-list li a:hover {
    color: #f16521;
}

.prod-details-footer__item .share-item a {
    color: #878c9b;
    margin-right: 15px;
}

.prod-details-footer__item .share-item a:last-child {
    margin-right: 0;
}

.prod-details-footer__item .share-item a:hover {
    color: #f16521;
}

.product-description-review .desc-review-tab-menu {
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 30px;
}

.product-description-review .desc-review-tab-menu li {
    margin-right: 20px;
}

.product-description-review .desc-review-tab-menu li:last-child {
    margin-right: 0;
}

.product-description-review .desc-review-tab-menu li a {
    color: #5f362e;
    font-size: 20px;
    line-height: 1;
    text-transform: capitalize;
    position: relative;
    padding-bottom: 5px;
}

.product-description-review .desc-review-tab-menu li a.active:after {
    background-color: #f16521;
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 2px;
    width: 100%;
}

/* .product-rating-wrap { */
    /*-- rating Form Wrap --*/
    /*-- rating Form --*/
/* } */

.product-rating-wrap .pro-avg-rating {
    margin-bottom: 30px;
    float: left;
    width: 100%;
    line-height: 1;
}

.product-rating-wrap .pro-avg-rating h4 {
    font-size: 24px;
    margin: 0 0 7px;
    font-weight: 600;
}

.product-rating-wrap .pro-avg-rating h4 span {
    color: #000000;
    font-size: 12px;
}

.product-rating-wrap .pro-avg-rating>span {
    display: block;
}

.product-rating-wrap .rating-list {
    margin-bottom: 30px;
    float: left;
    width: 100%;
}

.product-rating-wrap .rating-list .sin-list {
    margin-right: 30px;
}

.product-rating-wrap .rating-list .sin-list:last-child {
    margin-right: 0;
}

.product-rating-wrap .rating-list .sin-list i {
    color: #fbbf00;
}

.product-rating-wrap .rating-list .sin-list span {
    color: #666666;
}

.product-rating-wrap .sin-ratings {
    margin-bottom: 25px;
}

.product-rating-wrap .sin-ratings:last-child {
    margin-bottom: 0;
}

.product-rating-wrap .sin-ratings .rating-author {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.product-rating-wrap .sin-ratings .rating-author h3 {
    float: left;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 18px;
    margin-right: 15px;
}

.product-rating-wrap .sin-ratings .rating-author .rating-star {
    line-height: 1;
}

.product-rating-wrap .sin-ratings .rating-author .rating-star i {
    color: #fbbf00;
    font-size: 14px;
}

.product-rating-wrap .sin-ratings .rating-author .rating-star span {
    margin-left: 5px;
    font-weight: 600;
}

.product-rating-wrap .rating-form-wrapper {
    margin-top: 30px;
}

.product-rating-wrap .rating-form-wrapper h3 {
    border-bottom: 1px solid #eee;
    font-size: 20px;
    margin: 0 0 30px;
    padding: 0 0 10px;
    text-transform: capitalize;
}

.product-rating-wrap .rating-form h5 {
    float: left;
    font-size: 16px;
    line-height: 1;
    margin-right: 10px;
    font-weight: 600;
    margin-bottom: 0;
}

.product-rating-wrap .rating-form .rating-star {
    margin-bottom: 15px;
    display: inline-block;
}

.product-rating-wrap .rating-form .rating-star i {
    color: #D4D4D4;
    float: left;
    display: block;
    margin-right: 3px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.product-rating-wrap .rating-form .rating-star i:hover~i {
    color: #D4D4D4;
}

.product-rating-wrap .rating-form .rating-star i:last-child {
    margin: 0;
}

.product-rating-wrap .rating-form .rating-star:hover i {
    color: #fbbf00;
}

.pro-qty {
    display: inline-block;
    position: relative;
    width: 140px;
}

.pro-qty input {
    border: 1px solid #f16521;
    text-align: center;
    outline: none;
    height: 55px;
    width: 100%;
}

.pro-qty a {
    color: #5f362e;
    font-size: 22px;
    text-align: center;
    position: absolute;
    top: 0;
    line-height: 55px;
    height: 100%;
    width: 30px;
}

.pro-qty a:hover {
    background-color: #f16521;
    color: #FFFFFF;
}

.pro-qty a.inc {
    right: 0;
    border-left: 1px solid #f16521;
}

.pro-qty a.dec {
    border-right: 1px solid #f16521;
    left: 0;
}

/*==========================================
54. Checkout Page Pages Style Css
===========================================*/
.checkout-page-coupon-area .card {
    border: none;
    padding: 0;
}

.checkout-page-coupon-area .card h3 {
    background-color: #5f362e;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.2;
    margin: 0;
    padding: 20px 30px;
}

@media only screen and (max-width: 575.98px) {
    .checkout-page-coupon-area .card h3 {
        font-size: 14px;
        padding: 10px 15px;
    }
}

.checkout-page-coupon-area .card h3 i {
    color: #008000;
    margin-right: 10px;
}

.checkout-page-coupon-area .card h3 span {
    cursor: pointer;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
}

.checkout-page-coupon-area .card h3 span:hover {
    color: #f16521;
}

.checkout-page-coupon-area .card .card-body {
    padding: 0;
    margin-top: 30px;
}

.checkout-page-coupon-area .card .card-body .apply-coupon-wrapper .form-input-item input {
    font-size: 16px;
    padding: 10px 0;
}

.checkout-page-coupon-area .card .card-body .apply-coupon-wrapper .btn-bordered {
    padding: 15px 20px;
}

.checkout-billing-details-wrap {
    margin-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .checkout-billing-details-wrap {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .checkout-billing-details-wrap {
        margin-top: 40px;
    }
}

.checkout-billing-details-wrap h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 46px;
}

@media only screen and (max-width: 767.98px) {
    .checkout-billing-details-wrap h2 {
        margin-bottom: 26px;
    }
}

.checkout-billing-details-wrap h2:before {
    background-color: #222222;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
}

.billing-form-wrap .form-input-item {
    margin-top: 40px;
}

.billing-form-wrap .form-input-item input,
.billing-form-wrap .form-input-item textarea {
    font-size: 16px;
    padding: 5px 0;
}

.billing-form-wrap .form-input-item input::-webkit-input-placeholder,
.billing-form-wrap .form-input-item textarea::-webkit-input-placeholder {
    color: #878c9b;
}

.billing-form-wrap .form-input-item input::-moz-placeholder,
.billing-form-wrap .form-input-item textarea::-moz-placeholder {
    color: #878c9b;
}

.billing-form-wrap .form-input-item input:-ms-input-placeholder,
.billing-form-wrap .form-input-item textarea:-ms-input-placeholder {
    color: #878c9b;
}

.billing-form-wrap .form-input-item input::-ms-input-placeholder,
.billing-form-wrap .form-input-item textarea::-ms-input-placeholder {
    color: #878c9b;
}

.billing-form-wrap .form-input-item input::placeholder,
.billing-form-wrap .form-input-item textarea::placeholder {
    color: #878c9b;
}

.billing-form-wrap .form-input-item .nice-select:after {
    border-right-color: #878c9b;
    border-bottom-color: #878c9b;
}

.billing-form-wrap .form-input-item .nice-select .current {
    font-size: 16px;
    color: #878c9b;
}

.checkout-box-wrap .account-create {
    margin-top: 25px;
    font-size: 16px;
}

.checkout-box-wrap .account-create,
.checkout-box-wrap .ship-to-different {
    display: none;
}

.order-details-area-wrap {
    border: 2px solid #eee;
    padding: 50px 30px;
    margin-top: 80px;
}

@media only screen and (max-width: 575.98px) {
    .order-details-area-wrap {
        padding: 30px 20px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .order-details-area-wrap {
        margin-top: 60px;
    }
}

@media only screen and (max-width: 767.98px) {
    .order-details-area-wrap {
        margin-top: 40px;
    }
}

.order-details-area-wrap h2 {
    font-weight: 600;
    font-size: 30px;
    line-height: 1;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 35px;
}

@media only screen and (max-width: 767.98px) {
    .order-details-area-wrap h2 {
        margin-bottom: 26px;
    }
}

.order-details-area-wrap h2:before {
    background-color: #222222;
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 50px;
}

.order-details-area-wrap .order-details-table {
    line-height: 1;
    white-space: nowrap;
}

.order-details-area-wrap .order-details-table .custom-control-label,
.order-details-area-wrap .order-details-table th {
    font-weight: 400;
}

.order-details-area-wrap .order-details-table .table thead {
    border-bottom: 1px solid #eee;
    margin-bottom: 27px;
}

.order-details-area-wrap .order-details-table .table thead th {
    margin-bottom: 27px;
}

.order-details-area-wrap .order-details-table .table tbody {
    border-bottom: 1px solid #eee;
}

.order-details-area-wrap .order-details-table .table tbody .cart-item {
    font-size: 16px;
}

.order-details-area-wrap .order-details-table .table tbody .cart-item:first-child td {
    padding-top: 30px;
}

.order-details-area-wrap .order-details-table .table tbody .cart-item:last-child td {
    padding-bottom: 30px;
}

.order-details-area-wrap .order-details-table .table tfoot .shipping .shipping-method li {
    margin-bottom: 15px;
}

.order-details-area-wrap .order-details-table .table tfoot .shipping .shipping-method li:last-child {
    margin-bottom: 0;
}

.order-details-area-wrap .order-details-table .table tfoot .total-amount {
    font-size: 20px;
    font-weight: 600;
}

.order-details-area-wrap .order-details-table .table tfoot tr {
    border-bottom: 1px solid #eee;
}

.order-details-area-wrap .order-details-table .table tr td,
.order-details-area-wrap .order-details-table .table tr th {
    padding: 15px 0;
}

.order-details-area-wrap .order-details-table .table tr td:last-child,
.order-details-area-wrap .order-details-table .table tr th:last-child {
    text-align: right;
}

.order-details-area-wrap .order-details-table .product-title .product-quantity {
    color: #8d8d8d;
    font-size: 14px;
}

.order-details-area-wrap .order-details-footer {
    font-size: 15px;
}

.custom-control:not(.custom-radio) .custom-control-label:before {
    border-radius: 0;
    top: 6px;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-control:not(.custom-radio) .custom-control-label:after {
    top: 6px;
}

.custom-control:not(.custom-radio) .custom-control-input {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
}

.custom-control:not(.custom-radio) .custom-control-input:checked~.custom-control-label:before {
    background-color: #f16521;
    border-color: #f16521;
}

.custom-control:not(.custom-radio) .custom-control-input:focus~.custom-control-label:before {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #eee;
}

.custom-radio .custom-control-label:before {
    background-color: transparent;
    top: 2px;
}

.custom-radio .custom-control-label:after {
    top: 2px;
}

.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-radio .custom-control-input:checked label:before {
    border-color: #f16521;
    background-color: #f16521;
}

.custom-radio .custom-control-input:focus~.custom-control-label:before,
.custom-radio .custom-control-input:focus label:before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

/*==================================
55. Cart Page Pages Style Css
===================================*/
.shopping-cart-table thead th {
    color: #222222;
    font-weight: 700;
    font-size: 15px;
    text-transform: uppercase;
    border-bottom: 0;
}

.shopping-cart-table tr td,
.shopping-cart-table tr th {
    vertical-align: middle;
    width: 15%;
    padding: 10px;
    border-color: #eee;
    white-space: nowrap;
}

.shopping-cart-table tr td.product-list,
.shopping-cart-table tr th.product-list {
    width: 55%;
}

.shopping-cart-table .product-list .remove-icon {
    margin-right: 15px;
}

.shopping-cart-table .product-list .remove-icon button {
    color: #878c9b;
}

.shopping-cart-table .product-list .remove-icon button:hover {
    color: #f16521;
}

.shopping-cart-table .product-list .product-thumb {
    display: block;
    width: 70px;
    margin-right: 20px;
}

.shopping-cart-table .product-list .product-name {
    color: #222222;
    line-height: 1;
}

.shopping-cart-table .product-list .product-name:hover {
    color: #f16521;
}

.shopping-cart-table .pro-qty {
    width: 120px;
    line-height: 40px;
}

.shopping-cart-table .pro-qty input {
    padding: 10px 0;
    font-weight: 400;
    height: 40px;
}

.shopping-cart-table .pro-qty a {
    width: 30px;
    line-height: 44px;
    font-weight: 400;
    font-size: 16px;
}

.cart-coupon-update-area {
    margin-top: 20px;
}

.cart-coupon-update-area button {
    font-size: 14px;
    font-weight: 700;
}

.cart-coupon-update-area button:hover {
    color: #f16521;
}

.cart-coupon-update-area .coupon-form-wrap {
    width: 55%;
}

@media only screen and (max-width: 575.98px) {
    .cart-coupon-update-area .coupon-form-wrap {
        width: 100%;
    }
}

.cart-coupon-update-area .coupon-form-wrap form {
    position: relative;
}

.cart-coupon-update-area .coupon-form-wrap form input {
    border: none;
    border-bottom: 1px solid #eee;
    display: block;
    outline: none;
    padding: 5px 100px 5px 0;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    width: 100%;
}

.cart-coupon-update-area .coupon-form-wrap form input:focus {
    border-color: #f16521;
}

.cart-coupon-update-area .coupon-form-wrap form .btn-apply {
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.cart-coupon-update-area .cart-update-buttons button:nth-child(1n + 2) {
    margin-left: 10px;
}

.cart-calculate-area {
    background-color: #EEEEEE;
    padding: 30px;
}

.cart-calculate-area h5 {
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    margin-bottom: 20px;
}

.cart-calculate-area .cart-cal-table {
    white-space: nowrap;
}

.cart-calculate-area .cart-cal-table table {
    margin-bottom: 0;
}

.cart-calculate-area .cart-cal-table td,
.cart-calculate-area .cart-cal-table th {
    font-size: 16px;
    font-weight: 400;
    padding: 5px 0 15px;
}

.cart-calculate-area .cart-cal-table .shipping-method li {
    margin-bottom: 15px;
}

.cart-calculate-area .cart-cal-table .shipping-method li:last-child {
    margin-bottom: 0;
}

.cart-calculate-area .cart-cal-table .shipping-method .custom-control-label:before {
    top: 5px;
}

.cart-calculate-area .cart-cal-table .shipping-method .custom-control-label:after {
    top: 5px;
}

.cart-calculate-area .cart-cal-table .order-total {
    border-top: 1px solid #eee;
}

.cart-calculate-area .cart-cal-table .order-total th,
.cart-calculate-area .cart-cal-table .order-total td {
    padding-top: 15px;
}

.cart-calculate-area .proceed-checkout-btn {
    margin: 15px 0 0;
}

.table-responsive::-webkit-scrollbar-thumb,
.list::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0;
    background-color: #f16521;
}

.table-responsive::-webkit-scrollbar,
.list::-webkit-scrollbar {
    background-color: rgba(242, 182, 54, 0.3);
    -webkit-appearance: none;
    width: 15px;
    height: 5px;
}